import { Geometry } from "ol/geom";

export class SearchResult {

  public id: string | undefined;
  public display: string | undefined;
  public envelope: Geometry | undefined;
  public relevancy: number | undefined;

  constructor() {

  }
}