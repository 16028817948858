import { DataFetchAPI, PostAPI } from "@opt/core";
import { create } from "zustand"
import config from "../../config";
import { DatasetUserAccess } from "../../models/adm/DatasetUserAccess";
import { SensorAlgorithmAvailability } from "../../models/adm/SensorAlgorithmAvailability";
import { TenantUserAccess } from "../../models/adm/TenantUserAccess";

const fetchDatasetUserAccess = (userID: string) => {
  const { doFetchMany } = DataFetchAPI<DatasetUserAccess>(DatasetUserAccess, `${config.apiServices.url}/adm/datasetuser?user=${userID}`);
  return doFetchMany;
}

const fetchTenantUserAccess = (userID: string) => {
  const { doFetchMany } = DataFetchAPI<TenantUserAccess>(TenantUserAccess, `${config.apiServices.url}/adm/tenantuser?user=${userID}`);
  return doFetchMany;
}

const { doPost: postAccess } = PostAPI(Object, `${config.apiServices.url}/adm/datasetuser`);

const { doPost: postTenantAccess } = PostAPI(Object, `${config.apiServices.url}/adm/tenantuser`);

const fetchSensorAlgorithmAvailability = (algoName: string) => {
  const { doFetchMany } = DataFetchAPI<SensorAlgorithmAvailability>(SensorAlgorithmAvailability, `${config.apiServices.url}/adm/sensoralgorithm?algorithm=${algoName}`);
  return doFetchMany;
}

const { doPost: postSaveAvailability } = PostAPI(Object, `${config.apiServices.url}/adm/sensoralgorithm`);

interface AdmState {
  selectedUser: Object | undefined,
  fetchDatasetUserAccess: (userID: string) => Promise<DatasetUserAccess[] | undefined>;
  fetchTenantUserAccess: (userID: string) => Promise<TenantUserAccess[] | undefined>;
  saveAccess: (userID: string, datasets: string[]) => Promise<any>;
  saveTenantAccess: (userID: string, tenants: string[]) => Promise<any>;
  fetchSensorAlgorithmAvailability: (algoName: string) => Promise<SensorAlgorithmAvailability[] | undefined>;
  saveAvailability: (algoName: string, sensors: string[]) => Promise<any>;
}

export const useAdmStore = create<AdmState>((set, get) => ({
  selectedUser: undefined,

  fetchDatasetUserAccess: async (userID: string) => {
    const query = await fetchDatasetUserAccess(userID)();

    return query;
  },

  fetchTenantUserAccess: async (userID: string) => {
    const query = await fetchTenantUserAccess(userID)();

    return query;
  },

  saveAccess: async (userID: string, datasets: string[]) => {
    const result = postAccess({ userID: userID, datasetAccess: datasets });

    return result;
  },

  saveTenantAccess: async (userID: string, tenants: string[]) => {
    const result = postTenantAccess({ userID: userID, tenantAccess: tenants });

    return result;
  },

  fetchSensorAlgorithmAvailability: async (algoName: string) => {
    const query = await fetchSensorAlgorithmAvailability(algoName)();

    return query;
  },

  saveAvailability: async (algoName: string, sensors: string[]) => {
    const result = postSaveAvailability({ algorithmName: algoName, sensors: sensors });

    return result;
  },
}))