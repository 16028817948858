import { Draw, Modify, Snap } from "ol/interaction";
import React, { useEffect } from "react";
import OlMap from 'ol/Map';
import { DrawEvent } from "ol/interaction/Draw";
import { ModifyEvent } from "ol/interaction/Modify";
import { Feature } from "ol";
import { useStack } from "@opt/core";

const DrawGeometryEditor = (
  addMode: boolean,
  editMode: boolean,
  map: OlMap | undefined,
  drawer: Draw | undefined,
  modify: Modify | undefined,
  snap: Snap | undefined,
  currentEditing: Feature | undefined,
  setCurrentEditing: React.Dispatch<React.SetStateAction<Feature | undefined>>,
  pushUndo: (item: Feature) => void,
  clearUndoRedo: () => void
): {
  enableDraw: () => void,
  disableDraw: () => void
} => {

  const { push, pop, clear } = useStack<Feature>([]);

  useEffect(() => {
    if (!currentEditing) return;
    push(currentEditing);
  }, [currentEditing])

  useEffect(() => {
    if (addMode) {
      map?.addInteraction(modify as Modify);
      map?.addInteraction(drawer as Draw);
      map?.addInteraction(snap as Snap);

      enableDraw();

      return () => {
        map?.removeInteraction(modify as Modify);
        map?.removeInteraction(drawer as Draw);
        map?.removeInteraction(snap as Snap);
      }
    }
    else {
      disableDraw();
      setCurrentEditing(undefined);
      clearUndoRedo();

      if (drawer) map?.removeInteraction(drawer);
      if (snap) map?.removeInteraction(snap);
      if (modify) map?.removeInteraction(modify);
    }

  }, [addMode]);

  useEffect(() => {
    if (editMode) {
      map?.addInteraction(modify as Modify);
      map?.addInteraction(snap as Snap);

      enableDraw();
      clear();
      return () => {
        map?.removeInteraction(modify as Modify);
        map?.removeInteraction(snap as Snap);
      }
    }
    else {
      disableDraw();
      setCurrentEditing(undefined);
      clearUndoRedo();

      if (snap) map?.removeInteraction(snap);
      if (modify) map?.removeInteraction(modify);
    }

  }, [editMode]);

  const disableDraw = () => {
    clearUndoRedo();
    setCurrentEditing(undefined);

    drawer?.un('drawstart', handleDrawStart);
    drawer?.un('drawend', handleDrawEnd);
    modify?.un("modifyend", handleModifyEnd);
    modify?.un("modifystart", handleModifyStart)

    clear();
  }

  const enableDraw = () => {
    drawer?.on('drawstart', handleDrawStart);
    drawer?.on('drawend', handleDrawEnd);
    modify?.on("modifyend", handleModifyEnd);
    modify?.on("modifystart", handleModifyStart)

    push(currentEditing as Feature);
  }

  const handleDrawStart = (event: DrawEvent) => {
    clearUndoRedo();
    setCurrentEditing(undefined);
  }

  const handleDrawEnd = (event: DrawEvent) => {
    const feature = event.feature.clone();
    const previous = pop();

    if (feature) {
      if (previous) pushUndo(previous.clone());
      setCurrentEditing(feature);
    }
  }

  const handleModifyStart = (event: ModifyEvent) => {
    const feature = (event.features.item(0) as Feature)?.clone();
    pushUndo(feature);
  }

  const handleModifyEnd = (event: ModifyEvent) => {
    const feature = (event.features.item(0) as Feature)?.clone();
    const previous = pop();

    if (feature) {
      if (previous) pushUndo(previous.clone());
      setCurrentEditing(feature);
    }
  }

  return { enableDraw, disableDraw }
}

export default DrawGeometryEditor;