import { Exclude, Type } from "class-transformer";
import { QueryFilter } from "./QueryFilter";

export class PagedResult<T> {

  @Exclude()
  private type: Function;

  @Type(() => QueryFilter)
  public queryFilter: QueryFilter;

  @Type(options => (options?.newObject as PagedResult<T>).type)
  public items: Array<T>;

  constructor(type: Function) {
    this.type = type;
    this.items = [];
    this.queryFilter = new QueryFilter();
  }
}
