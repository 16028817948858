import { Box } from "@mui/material";
import { useWindowDimensions } from "@opt/core";
import { TableMetadataSearchView } from "@opt/ui-core";
import React from "react";
import { useParams } from "react-router-dom";

const MetadataSearchPage: React.FC = () => {

  const { modelID } = useParams<string>();
  const { height } = useWindowDimensions();

  return (
    <>
      <Box component="div" sx={{ overflow: 'auto', maxHeight: height - 110 }}>
        <TableMetadataSearchView modelID={modelID}></TableMetadataSearchView>
      </Box>
    </>
  );
}

export default MetadataSearchPage;