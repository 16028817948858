import { Stack, IconButton, Tooltip, styled, Box, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@opt/ui-core";
import { QueryFilter } from "@opt/core";
import { useExplorerStore } from "../components/explorer/ExplorerStore";

const ExplorerListPage: React.FC = () => {

  const Root = styled('div')({
    display: "flex",
    flex: "1",
    flexDirection: "column",
    height: "100%"
  });

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { datasets, getDatasets } = useExplorerStore();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: `${t('pages.explorer.gridCols.name')}`,
      width: 500,
      editable: false,
    },
    {
      field: 'description',
      headerName: `${t('pages.explorer.gridCols.description')}`,
      width: 200,
      editable: false,
      align: "center"
    },
    {
      field: "action",
      headerName: "",
      width: 60,
      sortable: false,
      editable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return <Stack direction="row" spacing={2}>
          <Tooltip title={`${t('pages.explorer.gotoDetails')}`}>
            <IconButton size="medium" onClick={() => navigate(`/explorer/${params.row?.id}`)}>
              <SlideshowIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    }
  ];

  useEffect(() => {
    getDatasets();
  }, []);

  return (
    <>
      <Root>
        <Box sx={{ marginTop: "10px" }}>
          <Typography gutterBottom variant="h5" color="text.secondary">{t('pages.explorer.title')}</Typography>
          <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
            <DataGrid
              height={"calc(100vh - 200px)"}
              dataRows={datasets}
              dataColumns={columns}
              queryFilter={new QueryFilter(100)}
            />
          </Box>
        </Box>
      </Root>
    </>
  )
}

export default ExplorerListPage;