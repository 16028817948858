import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { CustomLabel, COLORS } from "../Helpers";
import { useCockpitStore } from "../CockpitStore";
import { FormatNumber } from "@opt/core";
import { useTranslation } from "react-i18next";

const InspectionsPerStatus: React.FC = () => {

  const { t } = useTranslation();
  const { inspectionsPerStatus } = useCockpitStore();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card>
          <CardContent>
            <span>{FormatNumber(payload[0].value)}</span>
          </CardContent>
        </Card>
      );
    }

    return null;
  }

  return (
    <>
      <Typography gutterBottom variant="h6" color="primary">
        {t('components.cockpit.cards.inspectionsPerStatus.title')}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" maxHeight={300} minHeight={300}>
        <PieChart width={400} height={400}>
          <Pie
            data={inspectionsPerStatus}
            dataKey="count"
            fill="#8884d8"
            labelLine={false}
            label={CustomLabel}>
            {inspectionsPerStatus.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="bottom" height={36} formatter={(value, entry, index) => ((entry.payload) as any).status} />
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default InspectionsPerStatus;