import { Alert, AlertColor, Snackbar } from "@mui/material";
import React from "react";
import { create } from "zustand";

interface NofifyerState {
  msg: string,
  type: AlertColor,
  duration: number,
  show: boolean,
  addMsg: (type: AlertColor, msg: string, duration?: number) => void,
  hide: () => void
}

export const useNotifyStore = create<NofifyerState>((set, get) => ({
  msg: "",
  type: "success",
  duration: 6000,
  show: false,

  addMsg: (type: AlertColor, msg: string, duration: number = 6000) => {
    set({ msg: msg, type: type, duration: duration, show: true });
  },

  hide: () => {
    set({ show: false, msg: "" });
  }
}));

interface NotifyerProps {
  children: JSX.Element
}

const Notifyer: React.FC<NotifyerProps> = ({ children }) => {

  const { msg, type, duration, show, hide } = useNotifyStore();

  return (
    <>
      {children}
      <Snackbar
        autoHideDuration={duration}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={show}
        onClose={() => hide()}
      >
        <Alert severity={type} sx={{whiteSpace: "pre-line"}}>{msg}</Alert>
      </Snackbar>
    </>
  )
}

export default Notifyer;