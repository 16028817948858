import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import { FormatDecimalNumber } from "@opt/core";
import React from "react";
import { useEffect } from "react";
import { useAlertsStore } from "./AlertsStore";
import { useTranslation } from "react-i18next";

const AlertCardAOI: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const { currentEvent, relatedAOI, getAOI } = useAlertsStore();

  useEffect(() => {
    if (!currentEvent) return;
    getAOI(currentEvent.eventID);
  }, [currentEvent]);

  return (
    <>
      {relatedAOI &&
        <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
          <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
            <Typography gutterBottom variant="h6" component="div" color="primary">
              {t('components.alerts.aoiCard.title')}
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.aoiCard.dataset')}: {relatedAOI.datasetName}
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.aoiCard.parcelID')}: {relatedAOI.parcelID}
            </Typography>
            <Tooltip title={relatedAOI.projectID}>
              <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                {t('components.alerts.aoiCard.projectID')}: {relatedAOI.projectID}
              </Typography>
            </Tooltip >
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.aoiCard.area')}: {FormatDecimalNumber(relatedAOI.shapeArea)} ha
            </Typography>
          </CardContent>
        </Card>
      }
    </>
  )
}

export default AlertCardAOI;