import { Box } from "@mui/material";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import DataGrid from "../DataGrid";
import { useDynamicFormsStore } from "./DynamicStore";
import { FieldDataType, FieldMetadata, FormatNumber, QueryFilter, TableMetadata } from "@opt/core";
import moment from "moment";

const styles = {
  gridBox: {
    margin: "20px",
    flexGrow: 1
  }
}

interface DynamicGridProps {
  metadata: TableMetadata | undefined,
  relatedTableID?: string,
  relatedModelID?: string,
  setShowForm: (state: boolean) => void,
  setUpdateMode: (state: boolean) => void,
  setReadOnlyMode: (state: boolean) => void,
  setShowDeleteDialog: (state: boolean) => void,
  setSelectedModel: (selected: Object) => void,
  handleMoreActions?: (data: any, element: HTMLElement) => void
}

const DynamicGrid: React.FC<DynamicGridProps> = (
  {
    metadata,
    relatedTableID,
    relatedModelID,
    setShowForm,
    setUpdateMode,
    setReadOnlyMode,
    setShowDeleteDialog,
    setSelectedModel,
    handleMoreActions
  }) => {

  const { rows, queryFilter, query } = useDynamicFormsStore();
  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);

  const calculateSize = (field: FieldMetadata) => {
    if (field.dataType == FieldDataType.Boolean) {
      return 80;
    } else if (field.dataType == FieldDataType.Date) {
      return 120;
    }

    return Math.max((field.length ?? 130), 130);
  }

  useEffect(() => {
    if (!metadata) return;

    const sorted = metadata.fieldsMetadata
      .filter(x => x.visible)
      .sort((a, b) => (a.order ?? 999) - (b.order ?? 999));

    const columns = sorted
      .map((field) => {
        const column = {
          field: field.name,
          headerName: field.label,
          width: calculateSize(field),
          editable: false,
          valueFormatter: (params: GridValueFormatterParams<any>): any => params.value
        }

        if (field.dataType == FieldDataType.Date) {
          column.valueFormatter = params => moment(params?.value).format("DD/MM/YYYY")
        }
        else if (field.dataType == FieldDataType.DateTime) {
          column.valueFormatter = params => moment(params?.value).format("DD/MM/YYYY hh:mm A")
        }
        else if (field.dataType == FieldDataType.Integer) {
          column.valueFormatter = params => FormatNumber(params.value)
        }
        else if (field.dataType == FieldDataType.Float) {
          column.valueFormatter = params => params.value.toLocaleString('pt-br', { minimumFractionDigits: field.precision ?? 2, maximumFractionDigits: field.precision ?? 2 })
        }
        else if (field.dataType == FieldDataType.Boolean) {
          column.valueFormatter = params => params.value ? 'Sim' : 'Não'
        }

        return column;
      });

    setGridColumns(columns);
    query(queryFilter, relatedTableID, relatedModelID);

  }, [metadata]);

  const handleOnEdit = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(false);
    setUpdateMode(true);
    setShowForm(true);
  }

  const handleOnDelete = (data: any) => {
    setSelectedModel(data);
    setShowDeleteDialog(true);
  }

  const handleOnDetails = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(true);
    setShowForm(true);
  }

  const onChangeFilters = (filter: QueryFilter) => {
    query(filter, relatedTableID, relatedModelID);
  }

  return (
    <>
      <Box sx={styles.gridBox}>
        <DataGrid
          height="calc(100vh - 270px)"
          dataRows={rows}
          dataColumns={gridColumns}
          queryFilter={queryFilter}
          rowID={metadata?.getKeyAttribute()}
          onChangeFilters={onChangeFilters}
          onEditClicked={handleOnEdit}
          onDeleteClicked={handleOnDelete}
          onDetailsClicked={handleOnDetails}
          onMoreActionsCliked={handleMoreActions}
        />
      </Box>
    </>
  )
}

export default DynamicGrid;