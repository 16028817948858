import React from "react";
import { useParams } from "react-router-dom";
import InspectionView from "../components/inspections/InspectionsView";

const InspectionManualDetailsPage: React.FC = () => {

  const { dsid, id } = useParams<string>();

  return (
    <>
      <InspectionView datasetID={dsid} showInspectionID={id} />
    </>
  )
}

export default InspectionManualDetailsPage;