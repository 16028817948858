import { useStack } from "@opt/core";
import { Feature } from "ol";
import React, { useEffect } from "react";

const UndoRedoEditor = (
  currentEditing: Feature | undefined,
  setCurrentEditing: React.Dispatch<React.SetStateAction<Feature | undefined>>): {
    handleUndo: () => void,
    handleRedo: () => void,
    hasUndo: () => boolean,
    hasRedo: () => boolean,
    clearUndoRedo: () => void,
    pushUndo: (item: Feature) => void,
    pushRedo: (item: Feature) => void
  } => {

  const { push: pushUndo, pop: popUndo, isEmpty: isEmptyUndo, clear: clearUndo } = useStack<Feature>([]);
  const { push: pushRedo, pop: popRedo, isEmpty: isEmptyRedo, clear: clearRedo } = useStack<Feature>([]);

  const handleUndo = () => {
    const feature = popUndo();

    if (feature) {
      if (currentEditing) pushRedo(currentEditing);
      setCurrentEditing(feature);
    }
  }

  const handleRedo = () => {
    const feature = popRedo();

    if (feature) {
      if (currentEditing) pushUndo(currentEditing.clone());
      setCurrentEditing(feature);
    }
  }

  const hasUndo = (): boolean => {
    return !isEmptyUndo();
  }

  const hasRedo = (): boolean => {
    return !isEmptyRedo();
  }

  const clearUndoRedo = () => {
    clearUndo();
    clearRedo();
  }

  return { handleUndo, handleRedo, hasUndo, hasRedo, clearUndoRedo, pushUndo, pushRedo }
}

export default UndoRedoEditor;