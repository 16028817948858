import { AppMap, EditorToolbarControl, IdentifyTool, LayerDefinition, LayerType, MVTLayer } from "@opt/mapping";
import React, { useEffect, useState } from "react";
import { useDynamicFormsStore } from "./DynamicStore";
import OlMap from 'ol/Map';
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Geometry, LineString, MultiLineString, MultiPolygon, Polygon } from "ol/geom";
import { Config } from "../../config";
import { Box, Drawer, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Feature } from "ol";
import DynamicForm from "./DynamicForm";
import { GeometryType } from "@opt/core";
import WKT from "ol/format/WKT";
import { useNotifyStore } from "../Notifyer";
import DeleteDialog from "../DeleteDialog";

interface DynamicModelMapProps {
  relatedTableID?: string,
  relatedModelID?: string
}

const DynamicModelMap: React.FC<DynamicModelMapProps> = ({ relatedTableID, relatedModelID }) => {

  const { tableMetadata, deleteModel } = useDynamicFormsStore();
  const { addMsg } = useNotifyStore();
  const [map, setMap] = useState<OlMap>();
  const [mvtLayers, setMVTLayers] = useState<LayerDefinition[]>([]);
  const [currentLayers, setCurrentLayers] = useState<LayerDefinition>();
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  const [showForm, setShowForm] = useState(false);
  const [selectedModel, setSelectedModel] = useState<Object>();
  const [updateMode, setUpdateMode] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [signalDone, setSignalDone] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (!map) return;

    const fill = new Fill({
      color: 'rgba(255,231,0,0.4)'
    });

    const stroke = new Stroke({
      color: 'rgba(255,231,0,1)',
      width: 2
    });

    const selectedStyle = new Style({
      image: new Circle({
        fill: fill,
        stroke: new Stroke({
          color: 'yellow',
          width: 2
        }),
        radius: 5,
      }),
      fill: fill,
      stroke: stroke
    });

    const source = new VectorSource();
    new VectorLayer({
      source: source,
      map: map,
      style: selectedStyle
    });

    setVectorSource(source);
  }, [map]);

  useEffect(() => {
    if (!showForm) {
      vectorSource?.clear();
    }
  }, [showForm]);

  useEffect(() => {
    if (!tableMetadata) return;

    let url;
    if (relatedTableID || relatedTableID) {
      url = `${Config.Instance.apiEndpoint}/table/${tableMetadata.id}/related/${relatedTableID}/${relatedModelID}/mvt/{z}/{x}/{y}.pbf`;
    } else {
      url = `${Config.Instance.apiEndpoint}/table/${tableMetadata.id}/mvt/{z}/{x}/{y}.pbf`;
    }

    const definition = new LayerDefinition(tableMetadata.id as string, LayerType.MVT,
      url, tableMetadata.label);
    definition.geometryType = tableMetadata.geometryType as GeometryType;

    setCurrentLayers(definition);
    setMVTLayers([definition]);

  }, [tableMetadata]);

  const setIdentifyResults = (results: Feature<Geometry>[]) => {
    vectorSource?.clear();

    if (results.length) {
      const first = results[0];

      vectorSource?.addFeature(first.clone());

      setSelectedModel(first.getProperties());
      setShowForm(true);
      setReadOnlyMode(true);
    }
  }

  const handleDoneDrawing = (feature: Feature, updateMode: boolean) => {
    const newModel = {} as any;
    let drawed = feature.getGeometry() as Geometry;

    if (drawed.getType() === 'Polygon') {
      if (tableMetadata?.geometryType === GeometryType.MultiPolygon) {
        drawed = new MultiPolygon([drawed as Polygon]);
      }
    } else if (drawed.getType() === 'LineString') {
      if (tableMetadata?.geometryType === GeometryType.MultiLineString) {
        drawed = new MultiLineString([drawed as LineString]);
      }
    }

    const wkt = new WKT().writeGeometry(drawed);
    newModel["geom"] = wkt;

    const props = feature.getProperties();

    for (var k in props) {
      if (k === "geometry") continue;

      newModel[k] = props[k]
    }

    setSelectedModel(newModel);
    setShowForm(true);
    setReadOnlyMode(false);
    setUpdateMode(updateMode);
  }

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      const id = tableMetadata?.getKeyAttribute();
      deleteModel(Reflect.get(selectedModel as any, id as string), relatedTableID, relatedModelID)
        .then(() => {
          addMsg("success", "Registro excluído com sucesso.")
        })
        .catch(e => {
          if (e?.remote?.hasErrors) {
            const msg = e.remote.errors.join('\n');
            addMsg("error", `Não foi possível atualizar registro.\n${msg}`);
          }
        });

      const editedLayer = map?.getAllLayers().find(x => x.get("LAYER_DEFINITION")?.key === tableMetadata?.id);
      editedLayer?.getSource()?.refresh();
      setSignalDone((previous) => previous + 1);
    }

    setShowDeleteDialog(false);
  }

  const handleDelete = (feature: Feature) => {
    setSelectedModel(feature.getProperties());
    setShowDeleteDialog(true);
  }

  const handleOnSave = (model: object) => {
    setShowForm(false);

    const editedLayer = map?.getAllLayers().find(x => x.get("LAYER_DEFINITION")?.key === tableMetadata?.id);
    editedLayer?.getSource()?.refresh();
    setSignalDone((previous) => previous + 1);
  }

  return (
    <>
      <AppMap mapHeight="100%" onMapCreated={setMap}>
        {mvtLayers.length > 0 &&
          mvtLayers.map(x => {
            return <MVTLayer key={x.name} definition={x} />
          })
        }
      </AppMap>
      <IdentifyTool map={map} setIdentifyResults={setIdentifyResults} targetLayers={[tableMetadata?.id ?? '']} />
      <EditorToolbarControl map={map}
        layerDefinition={currentLayers}
        onDoneDrawing={handleDoneDrawing}
        onDelete={handleDelete}
        signalClear={signalDone} />
      <Drawer
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "600px", maxWidth: "600px" }}>
          <DynamicForm metadata={tableMetadata}
            forUpdate={updateMode}
            forReadOnly={readOnlyMode}
            onSave={handleOnSave}
            model={selectedModel as object}
            hideMap={true}></DynamicForm>
        </Box>
      </Drawer>
      <DeleteDialog confirmation={handleDeleteConfirmation} showDialog={showDeleteDialog} />
    </>
  )
}

export default DynamicModelMap;
