import { Feature } from "ol";
import Polygon from "ol/geom/Polygon";
import { GeoJSON } from 'ol/format';
import TurfBuffer from '@turf/buffer';
import { union as turfUnion, featureCollection, combine as turfCombine } from "@turf/turf";
import { Geometry, MultiPolygon } from "ol/geom";

export function calculateExtent(feature: Feature<Geometry>, distance: number = 200) {

  feature.setGeometry(feature.getGeometry()?.transform("EPSG:3857", "EPSG:4326") as Polygon)

  const format = new GeoJSON();
  const turfFeature = format.writeFeatureObject(feature);
  const buffered = TurfBuffer(turfFeature, distance, { units: 'meters' });
  const new_feature = format.readFeatures(buffered)[0];

  new_feature.setGeometry(new_feature.getGeometry()?.transform("EPSG:4326", "EPSG:3857") as Polygon)

  const extent = new_feature.getGeometry()?.getExtent();

  return extent;
}

export function applyBuffer(geom: Geometry, distance: number) {
  const format = new GeoJSON();
  const feature = new Feature({ geometry: geom.transform("EPSG:3857", "EPSG:4326") });
  const turfFeature = format.writeFeatureObject(feature);
  const buffered = TurfBuffer(turfFeature, distance, { units: 'meters' });
  const newFeature = format.readFeatures(buffered)[0];

  return newFeature.getGeometry()?.transform("EPSG:4326", "EPSG:3857");
}

export function unionGeometries(features: Feature<Geometry>[]) {
  if (!features.length) return undefined;

  if (features.length === 1) return features[0];

  const format = new GeoJSON();

  if (features[0].getGeometry() instanceof Polygon || features[0].getGeometry() instanceof MultiPolygon) {
    //apply union
    const turfFeatures = features.map(feature => format.writeFeatureObject(feature));

    const first = turfFeatures[0];
    let union = first;
    for (let index = 1; index < turfFeatures.length; index++) {
      const element = turfFeatures[index];
      union = turfUnion(union as any, element as any) as any;
    }

    return format.readFeature(union);
  }
  else {
    //apply combine
    const turfFeatures = features.map(feature => format.writeFeatureObject(feature));
    const collection = featureCollection(turfFeatures);
    const combination = turfCombine(collection as any);
    return format.readFeature(combination.features[0]);
  }
}