import { useUserAuthStore } from "@opt/core";

export const VectorTileLoadFunction = () => {

  const accessToken = useUserAuthStore.getState().accessToken;
  const tenantID = useUserAuthStore.getState().currentTenant.id;

  return async (tile: any, url: any) => {

    tile.setLoader(function (extent: any, resolution: any, projection: any) {
      fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Authorization': `Bearer ${accessToken}`,
          'X-Tenant': tenantID,
          'Accept-Encoding': 'gzip'
        })
      })
        .then(function (response) {
          response.arrayBuffer().then(function (data) {
            const format = tile.getFormat()
            const features = format.readFeatures(data, {
              extent: extent,
              featureProjection: projection
            });
            tile.setFeatures(features);
          });
        });
    });
  };
};