import React from "react";
import Keycloak from 'keycloak-js';
import { useUserAuthStore } from "../UserAuthStore";
import { KeycloakConfig } from "../LoginContext";

type RedirectToKeycloakProps = {
  config: KeycloakConfig,
  keycloak: Keycloak
}

const RedirectToKeycloak: React.FC<RedirectToKeycloakProps> = ({ keycloak, config }) => {

  const { authenticated, setUserInfo, updateAccessToken } = useUserAuthStore();

  if (!authenticated) {
    keycloak
      .init({ checkLoginIframe: false })
      .then((status) => {
        if (status) {

          let roles: string[] = [];
          if (keycloak?.tokenParsed?.resource_access) {
            roles = roles = keycloak?.tokenParsed?.resource_access[config.clientID]?.roles;
          }

          const info = {
            authenticated: true,
            authorized: false,
            accessToken: keycloak.token,
            username: keycloak?.idTokenParsed?.preferred_username,
            email: keycloak?.idTokenParsed?.email,
            roles: roles
          };

          setUserInfo(info);

          const expTime = Math.round((keycloak?.tokenParsed?.exp ?? 0) + (keycloak?.timeSkew ?? 0) - new Date().getTime() / 1000) * 1000;

          setInterval(() => {
            keycloak.updateToken(30).then(() => {
              updateAccessToken(keycloak.token);
            });
          }, expTime);
        }
        else {
          keycloak
            .login()
            .catch(function () {
              alert('Não foi possível conectar-se ao servidor de autenticação.');
            })
        }
      }).catch(function () {
        alert('Não foi possível conectar-se ao servidor de autenticação.');
      });
  }

  return (
    <></>
  )
}

export default RedirectToKeycloak;