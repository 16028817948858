export class ZonalIndexStats {

    public id: string;
    public min: number;
    public max: number;
    public std: number;
    public median: number;
    public mean: number;
    public count: number;
    public masked: number;
    public threshold: number;

    constructor() {
        this.id = "";
        this.min = 0;
        this.max = 0;
        this.std = 0;
        this.median = 0;
        this.mean = 0;
        this.count = 0;
        this.masked = 0;
        this.threshold = 0;
    }
}