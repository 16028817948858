export default class LayerDetail {

  public tableID: string | undefined;
  public order: number | undefined;
  public showInLegend: boolean | undefined;
  public identifiable: boolean | undefined;
  public vectorType: "MVT" | "OPT" | "LABEL" | undefined;

  constructor() {

  }
}