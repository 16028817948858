import { styled } from '@mui/material';
import {
  AppMap, FeatureLayer, LayerDefinition,
  LayerStyling,
  LayerType, MapLegend, MVTLayer
} from '@opt/mapping';
import React, { useEffect, useMemo, useState } from 'react';
import OlMap from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useInspectionsStore } from './InspectionStore';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import config from '../../config';
import { Extent } from 'ol/extent';
import { useTranslation } from 'react-i18next';
import { FeatureLike } from 'ol/Feature';
import { FormatDate } from '@opt/core';
import { useExplorerStore } from '../explorer/ExplorerStore';
import GeoJSON from "ol/format/GeoJSON";

const Root = styled('div')({
  display: "flex",
  height: "100%",
  width: "100%"
});

const MapArea = styled('div')({
  flex: "5 1 0"
});

const MapDiv = styled('div')({
  flex: "1",
  width: "100%",
  height: "100%"
});

const DetailsArea = styled('div')({
  flex: "2 1 0",
  padding: "5px",
  maxWidth: "460px",
  minWidth: "420px",
  overflowY: "scroll",
  height: "calc(100% - 5px)!important",
  maxHeight: "calc(100% - 5px)!important"
});

interface BaseInspectionLayoutProps {
  details: JSX.Element,
  showInspectionID: string | undefined,
  datasetID?: string,
  onMapCreated?: (map: OlMap) => void;
  onInspectionsLayerCreated?: (layer: VectorLayer<VectorSource>) => void;
}

const BaseInspectionLayout: React.FC<BaseInspectionLayoutProps> = (
  {
    details,
    showInspectionID,
    datasetID,
    onMapCreated,
    onInspectionsLayerCreated
  }): JSX.Element => {

  const { t } = useTranslation();
  const { current, getExtension } = useInspectionsStore();
  const [map, setMap] = useState<OlMap>();
  const [layers, setLayers] = useState<LayerDefinition[]>([]);
  const [inspectionsLayer, setInspectionsLayer] = useState<VectorLayer<VectorSource> | undefined>();
  const { getDatasetExtent } = useExplorerStore();
  const { filterStatus, filterInspectionNumber, filterType, filterRangeDate } = useInspectionsStore();

  useEffect(() => {
    if (!map) return;

    if (!!datasetID) {
      getDatasetExtent(datasetID as string)
        .then(result => {
          const geom = new GeoJSON().readGeometry(result);
          CreateLayers(geom.getExtent() as Extent, true);
        });
    }
    else if (!!current) {
      getExtension(current.aoi, 1000)
        .then(bbox => {
          CreateLayers(bbox as Extent);
        });
    }

  }, [current, map, datasetID, filterStatus, filterInspectionNumber, filterType, filterRangeDate]);

  useEffect(() => {
    if (onInspectionsLayerCreated && inspectionsLayer) {
      onInspectionsLayerCreated(inspectionsLayer);
    }
  }, [inspectionsLayer])

  const CreateLayers = (viewExtent: Extent, forFullDataset: boolean = false) => {

    const aoiStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(255, 0, 255, 0)'
      }),
      stroke: new Stroke({
        color: 'rgba(204, 0, 204, 1)',
        width: 2
      })
    });

    const aoiUrl = forFullDataset ?
      `${config.gisServices.url}/mvt/datasets/${datasetID}/{z}/{x}/{y}.pbf`
      : `${config.gisServices.url}/mvt/aoi/${current?.aoi}/{x}/{y}/{z}.pbf`;

    const aoiDefinition = new LayerDefinition("aoi_layer", LayerType.MVT,
      aoiUrl,
      t('components.inspections.baseLayout.layers.aoiLayer'), 10, true, true, false, undefined, aoiStyle);

    const pendingStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(255,59,1,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(255,59,1,1)',
        width: 2
      })
    });

    const verifyStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(126,186,235,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(126,186,235,1)',
        width: 2
      })
    });

    const monitorStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(254,209,65,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(254,209,65,1)',
        width: 2
      })
    });

    const remoteStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(209,211,170,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(209,211,170,1)',
        width: 2
      })
    });

    const fieldStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(67,84,32,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(67,84,32,1)',
        width: 2
      })
    });

    const noproblemStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(27,35,41,0.5)'
      }),
      stroke: new Stroke({
        color: 'rgba(27,35,41,1)',
        width: 2
      })
    });

    const styleFunc = (feature: FeatureLike | undefined, resolution: number) => {
      const status = feature?.get("Status");

      if (status === "PENDING") {
        return pendingStyle;
      } else if (status === "VERIFY") {
        return verifyStyle;
      } else if (status === "MONITOR") {
        return monitorStyle;
      } else if (status === "CONFIRMED_REMOTE") {
        return remoteStyle;
      } else if (status === "CONFIRMED_FIELD") {
        return fieldStyle;
      } else {
        return noproblemStyle;
      }
    }

    const legendEntries = [
      {
        label: t('components.inspections.types.status.PENDING'),
        fill: "rgba(255,59,1,0.5)",
        stroke: "rgba(255,59,1,1)"
      },
      {
        label: t('components.inspections.types.status.VERIFY'),
        fill: "rgba(126,186,235,0.5)",
        stroke: "rgba(126,186,235,1)"
      },
      {
        label: t('components.inspections.types.status.MONITOR'),
        fill: "rgba(254,209,65,0.5)",
        stroke: "rgba(254,209,65,1)"
      },
      {
        label: t('components.inspections.types.status.CONFIRMED_REMOTE'),
        fill: "rgba(209,211,170,0.5)",
        stroke: "rgba(209,211,170,1)"
      },
      {
        label: t('components.inspections.types.status.CONFIRMED_FIELD'),
        fill: "rgba(67,84,32,0.5)",
        stroke: "rgba(67,84,32,1)"
      },
      {
        label: t('components.inspections.types.status.NOT_PROBLEM'),
        fill: "rgba(27,35,41,0.5)",
        stroke: "rgba(27,35,41,1)"
      }
    ]

    const styleDef = new LayerStyling();
    styleDef.styleType = "FUNCTION";
    styleDef.legendOverride = JSON.stringify(legendEntries);

    let url = '';

    if (forFullDataset) {
      url = showInspectionID !== undefined ?
      `${config.gisServices.url}/feature/dataset/${datasetID}/inspections/${showInspectionID}` :
      `${config.gisServices.url}/feature/dataset/${datasetID}/inspections?s=${filterStatus}&n=${filterInspectionNumber}&t=${filterType}&sd=${FormatDate(filterRangeDate[0])}&ed=${FormatDate(filterRangeDate[1])}`;
    }
    else {
      url = showInspectionID !== undefined ?
        `${config.gisServices.url}/feature/aoi/${current?.aoi}/inspections/${showInspectionID}` :
        `${config.gisServices.url}/feature/aoi/${current?.aoi}/inspections?s=${filterStatus}&n=${filterInspectionNumber}&t=${filterType}&sd=${FormatDate(filterRangeDate[0])}&ed=${FormatDate(filterRangeDate[1])}`;
    }

    const inspectionsDefinition = new LayerDefinition("inspections_layer", LayerType.Feature,
      url, t('components.inspections.baseLayout.layers.inspectionsLayer'), 14, true, true, false, undefined, styleFunc);
    inspectionsDefinition.styleDefinition = styleDef;

    setLayers([aoiDefinition, inspectionsDefinition]);

    map?.getView().fit(viewExtent as Extent);
  }

  const handleMapCreated = (map: OlMap) => {
    setMap(map);
    if (onMapCreated) onMapCreated(map);
  }

  const vectorLayers = useMemo(() => {
    const l = layers.map(layer => {
      if (layer.type === LayerType.MVT) {
        return <MVTLayer key={layer.key} definition={layer} />
      }
      else if (layer.type === LayerType.Feature) {
        if (layer.key === "inspections_layer") {
          return <FeatureLayer key={layer.key} definition={layer} setCreatedLayer={setInspectionsLayer} />
        } else {
          return <FeatureLayer key={layer.key} definition={layer} />
        }
      }
    });

    return l;
  }, [layers])

  return (
    <Root>
      <MapArea>
        <MapDiv>
          <AppMap mapHeight="100%" onMapCreated={(map: any) => handleMapCreated(map)}>
            {layers.length > 0 && vectorLayers}
            <MapLegend></MapLegend>
          </AppMap>
        </MapDiv>
      </MapArea>
      <DetailsArea>
        {details}
      </DetailsArea>
    </Root>
  )
}

export default BaseInspectionLayout;