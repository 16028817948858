import {
  Box, Typography, Alert, Button, Backdrop,
  CircularProgress, Stepper, Step, StepLabel
} from "@mui/material";
import React, { useEffect } from "react";

interface StepInfo {
  key: string,
  label: string
}

interface SideEditFormProps {
  title: string,
  forUpdate: boolean,
  forReadOnly: boolean,
  isLoading?: boolean,
  isError?: boolean,
  onSubmit: () => void,
  steps?: StepInfo[],
  onChangeStep?: (stepIndex: number, step: StepInfo) => void,
  children: JSX.Element
}

const SideEditForm: React.FC<SideEditFormProps> = ({
  title,
  forUpdate,
  forReadOnly,
  isLoading = false,
  isError = false,
  onSubmit,
  steps,
  onChangeStep,
  children }) => {

  const [activeStep, setActiveStep] = React.useState(0);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    if (!steps) return;

    if (onChangeStep) {
      onChangeStep(activeStep, steps[activeStep])
    }
  }, [activeStep]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          {title}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          {forUpdate ? 'Editar registro' : 'Novo registro'}
        </Typography>
      </Box>
      {isError &&
        <Alert severity="error" sx={{ width: '100%' }}>
          {forUpdate ? 'Falha ao editar registro!' : 'Falha ao criar novo registro!'}
        </Alert>
      }
      <Box sx={{ marginTop: "20px" }}>

        {steps && steps.length > 0 &&
          <>
            <Stepper activeStep={activeStep}>
              {steps.map(step => {
                return <Step key={step.key}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              })}
            </Stepper>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, paddingBottom: "10px" }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Anterior
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                disabled={activeStep === steps.length - 1}
                onClick={handleNext} sx={{ mr: 1 }}>
                Próximo
              </Button>
            </Box>
          </>
        }

        <form onSubmit={handleSubmit}>
          {children}
          {!forReadOnly &&
            <Box>
              <Button size="small" variant="contained" color="primary" type="submit" sx={{ mt: 4 }}>
                Salvar
              </Button>
            </Box>
          }
        </form>
      </Box>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default SideEditForm;