import { Type } from "class-transformer";
import { TableMetadata } from "@opt/core";
import { Geometry } from "ol/geom";
import { LayerStyling } from "@opt/mapping";
import LayerDetail from "./LayerDetail";

export class MapProject {

  public id: string | undefined;
  public name: string | undefined;
  public description: string | undefined;
  public extent: Geometry | undefined;

  @Type(() => TableMetadata)
  public mapTables: TableMetadata[];

  @Type(() => LayerStyling)
  public layersStyling: LayerStyling[];

  @Type(() => LayerDetail)
  public details: LayerDetail[];

  constructor() {
    this.mapTables = []
    this.layersStyling = [];
    this.details = [];
  }
}