import React from "react"
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { Tooltip, styled, useTheme } from "@mui/material";

interface OPTMenuItem {
  linkTo: string,
  icon: any,
  title: string,
  hidden?: boolean,
  children?: OPTMenuItem[]
}

interface SideMenuProps {
  items?: OPTMenuItem[]
}

const SideMenu: React.FC<SideMenuProps> = ({ items }) => {
  const theme = useTheme();

  const StyledMenuItem = styled(MenuItem)({
    '&': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    }
  });

  const StyledSubMenu = styled(SubMenu)({
    '&': {
      color: 'white'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '.sub-menu-content': {
      border: `${theme.palette.primary.main} solid 2px`
    }
  });

  const StyledSubMenuItem = styled(MenuItem)({
    '&': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main
    }
  });

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Sidebar defaultCollapsed
        collapsedWidth="60px"
        backgroundColor={theme.palette.primary.main}>
        <Menu>
          {items?.filter(item => !item?.hidden).map(item => {
            if (item.children) {
              return <StyledSubMenu
                key={item.linkTo}
                title={item.title}
                icon={<item.icon size={20} />}>
                {item.children.map(subItem => {
                  return <StyledSubMenuItem key={subItem.linkTo}
                    icon={<subItem.icon size={20} />}
                    component={<Link to={subItem.linkTo} />}>{subItem.title}</StyledSubMenuItem>
                })}
              </StyledSubMenu>
            } else {
              return <StyledMenuItem key={item.linkTo}
                icon={<item.icon size={20} />}
                title={item.title}
                component={<Link to={item.linkTo} />}>{item.title}</StyledMenuItem>
            }
          })}
        </Menu>
      </Sidebar>
    </div>
  )
}

export default SideMenu;