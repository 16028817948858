import { useUserAuthStore } from '@opt/core';

export const TileLoadFunction = () => {

  const accessToken = useUserAuthStore.getState().accessToken;
  const tenantID = useUserAuthStore.getState().currentTenant.id;

  return async (image: any, src: any) => {

    fetch(src, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    }).then(async response => {
      const blob = await response.blob();
      const urlData = URL.createObjectURL(blob);
      image.getImage().src = urlData;
    });
  };
};