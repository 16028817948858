export class AlgorithmFilter {
    
    public name: string;
    public description: string;
    public shortDescription: string;
    public datasetId: string;

    constructor() {
        this.name = "";
        this.description = "";
        this.shortDescription = "";
        this.datasetId = "";
    }
}