import { plainToClassFromExist } from "class-transformer";
import { useUserAuthStore } from "../auth/UserAuthStore";
import { DataException } from "./DataExecption";

export const DataAPI = <TModel>(typeFactory: new () => TModel, endpoint: string | undefined): {
  doCreate: (model: TModel) => Promise<TModel>,
  doUpdate: (model: TModel) => Promise<TModel>,
  doDelete: (modelID: string) => Promise<any>
} => {

  const doCreate = async (model: TModel): Promise<TModel> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = `${endpoint}/create`;
    const jsonModel = JSON.stringify(model);

    const result = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      }),
      body: jsonModel
    })
      .then(async (response) => {
        if (!response.ok) {
          const text = await response.text();

          if (!text) throw new DataException("Fail to save model.");

          const remote = JSON.parse(text);
          throw new DataException("Fail to save model.", remote);
        }

        return plainToClassFromExist(new typeFactory(), await response.json());
      });

    return result;
  }

  const doUpdate = async (model: TModel): Promise<TModel> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = `${endpoint}/update`;
    const jsonModel = JSON.stringify(model);

    const result = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      }),
      body: jsonModel
    })
      .then(async (response) => {
        if (!response.ok) {
          const text = await response.text();

          if (!text) throw new DataException("Fail to update model.");

          const remote = JSON.parse(text);
          throw new DataException("Fail to update model.", remote);
        }

        return plainToClassFromExist(new typeFactory(), await response.json());
      });

    return result;
  }

  const doDelete = async (modelID: string): Promise<any> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = `${endpoint}/delete/${modelID}`;

    const result = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      })
    })
      .then(async (response) => {
        if (!response.ok) {
          const text = await response.text();

          if (!text) throw new DataException("Fail to delete model.");

          const remote = JSON.parse(text);
          throw new DataException("Fail to delete model.", remote);
        }

        return true;
      });

    return result;
  }

  return { doCreate: doCreate, doUpdate: doUpdate, doDelete: doDelete };
}