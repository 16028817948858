import { create } from 'zustand';

interface UserAuthState {
  offline: boolean,
  authenticated: boolean;
  authorized: boolean;
  username: string | undefined;
  email: string | undefined;
  roles: any[];
  currentTenant: any | undefined;
  tenants: [];
  accessToken: string | undefined;
  tenantChanged: number,
  getUserInfo: (config: any) => Promise<any>;
  setUserInfo: (info: any) => void;
  updateAccessToken: (token: any) => void;
  setCurrentTenant: (tenant: any) => void;
  setOffline: (offlineMode: boolean) => void;
}

export const useUserAuthStore = create<UserAuthState>((set, get) => ({
  offline: true,
  authenticated: false,
  authorized: false,
  username: undefined,
  email: undefined,
  roles: [],
  currentTenant: undefined,
  tenants: [],
  accessToken: undefined,
  tenantChanged: 0,

  setOffline: (offlineMode: boolean) => {
    set({ offline: offlineMode });
  },

  getUserInfo: async (config: any) => {
    const url = `${config.userInfoService.url}/info`;
    const token = get().accessToken;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
        'Accept-Encoding': 'gzip'
      }),
    });

    const userInfo = await response.json();

    if (!userInfo.tenants || !userInfo.tenants.length) throw new Error("User does not have any tenant.");

    set({
      authenticated: true,
      authorized: true,
      username: userInfo.username,
      email: userInfo.email,
      currentTenant: userInfo.tenants[0],
      tenants: userInfo.tenants
    });

    return userInfo;
  },

  setUserInfo: (info: any) => {
    set({
      authenticated: info?.authenticated,
      authorized: info?.authorized,
      username: info?.username,
      email: info?.email,
      roles: info?.roles,
      currentTenant: info?.currentTenant,
      tenants: info?.tenants,
      accessToken: info?.accessToken
    });
  },

  updateAccessToken: (token: any) => {
    set({
      accessToken: token
    });
  },

  setCurrentTenant: (tenant: any) => {
    if (get().currentTenant.id !== tenant.id) {
      const version = get().tenantChanged;
      set({ currentTenant: tenant, tenantChanged: version + 1 });
    }
  }
}));