import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import MapIcon from '@mui/icons-material/Map';
import TableViewIcon from '@mui/icons-material/TableView';
import DynamicModel from "./DynamicModel";
import DynamicModelMap from "./DynamicModelMap";
import { useDynamicFormsStore } from "./DynamicStore";
import { GeometryType } from "@opt/core";

const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#697B84"
  }
});

type DynamicModelViewProps = {
  tableID: string | undefined
  relatedTableID?: string
  relatedModelID?: string
  handleMoreActions?: (data: any, element: HTMLElement) => void
}

const DynamicModelView: React.FC<DynamicModelViewProps> = (
  {
    tableID,
    relatedTableID,
    relatedModelID,
    handleMoreActions,
  }) => {

  const [viewType, setViewType] = React.useState('table');
  const [hasMap, setHasMap] = useState(false);
  const { tableMetadata, fetchTableMetadata, clear } = useDynamicFormsStore();

  useEffect(() => {
    return () => {
      clear();
      tableID = undefined;
      relatedTableID = undefined;
      relatedModelID = undefined;
    }
  }, [])

  useEffect(() => {
    if (!tableID) return;

    fetchTableMetadata(tableID);
  }, [tableID]);

  useEffect(() => {
    if (tableMetadata && tableMetadata.geometryType !== GeometryType.None) {
      setHasMap(true);
    }
  }, [tableMetadata]);

  const handleChange = (event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setViewType(value);
  };

  return (
    <>
      <Stack>
        <Box
          sx={{ height: "calc(100vh - 130px)", marginBottom: "10px" }}>
          {tableID && viewType === "table" &&
            <DynamicModel
              handleMoreActions={handleMoreActions}
              relatedTableID={relatedTableID}
              relatedModelID={relatedModelID} />
          }
          {tableID && viewType === "map" &&
            <DynamicModelMap
              relatedTableID={relatedTableID}
              relatedModelID={relatedModelID} />
          }
        </Box>
        {hasMap &&
          <Box>
            <Stack direction="row" sx={{ marginLeft: "20px" }}>
              <ToggleButtonGroup
                sx={{ height: "30px" }}
                size="small"
                color="primary"
                value={viewType}
                exclusive
                onChange={handleChange}
              >
                <StyledToggleButton value="map" color="primary" size="small"><MapIcon fontSize="small" sx={{ mr: 1 }} />Mapa</StyledToggleButton>
                <StyledToggleButton value="table" color="primary" size="small"><TableViewIcon fontSize="small" sx={{ mr: 1 }} />Tabular</StyledToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Box>
        }
      </Stack>
    </>
  )
}

export default DynamicModelView;