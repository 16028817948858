
export class InspectionPerAOI {

  public tenantID: string;
  public datasetID: string;
  public dataset: string;
  public aoi: string;
  public projectID: string;
  public parcelID: string;
  public numOfInspections: number;

  constructor() {
    this.tenantID = "";
    this.datasetID = "";
    this.dataset = "";
    this.aoi = "";
    this.projectID = "";
    this.parcelID = "";
    this.numOfInspections = 0;
  }

  get getGridID(): string {
    return `${this.datasetID} - ${this.aoi}`;
  }
}
