import React, { useEffect } from "react";
import { LayerDefinition } from "./LayerDefinition";
import { useMapContext } from "./AppMap";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";
import OlMap from 'ol/Map';
import VectorSource from "ol/source/Vector";
import { OPTFeatureLoadFunction } from "./utils/OPTFeatureLoadFunction";
import VectorLayer from "ol/layer/Vector";
import { tile } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid.js';

type OPTFeatureLayerProps = {
  definition: LayerDefinition,
  map?: OlMap,
  onLayerCreated?: (layer: VectorLayer<VectorSource>) => void
}

const defaultFill = new Fill({
  color: 'rgba(255, 0, 255, 0)'
});

const defaultStroke = new Stroke({
  color: 'rgba(204, 0, 204, 1)',
  width: 2
});

const defaultStyle: Style = new Style({
  image: new Circle({
    fill: defaultFill,
    stroke: defaultStroke,
    radius: 5,
  }),
  fill: defaultFill,
  stroke: defaultStroke,
});

const OPTFeatureLayer: React.FC<OPTFeatureLayerProps> = ({ definition, map, onLayerCreated }) => {

  const mapContext = useMapContext();

  useEffect(() => {

    const webmap = mapContext?.map ?? map;

    if (!definition || !webmap) return;

    const vectorSource = new VectorSource({
      loader: OPTFeatureLoadFunction(definition.url),
      strategy: tile(createXYZ({ tileSize: 512 }))
    });

    const layer = new VectorLayer({
      declutter: true,
      visible: definition.visible,
      extent: definition.extent,
      minZoom: definition?.styleDefinition?.minVisibleZoom,
      maxZoom: definition.styleDefinition?.maxVisibleZoom,
      source: vectorSource,
      style: definition.style ?? defaultStyle
    });

    vectorSource.on("featuresloadend", e => {
      if (e.features) {
        vectorSource.addFeatures(e.features);
      }
    })

    // if (definition.styleDefinition) {
    //   layer.setStyle(StyleBuilder.fromLayerStyling(definition.styleDefinition))
    // }

    layer.set("LAYER_DEFINITION", definition);
    webmap.addLayer(layer);
    layer.setZIndex(definition.zIndex);

    if (onLayerCreated) onLayerCreated(layer);

    return () => {
      webmap.removeLayer(layer);
    }
  }, [definition, mapContext, map]);

  return (<></>)
}

export default OPTFeatureLayer;