import { DataAPI, DataFetchAPI, DataQueryAPI, QueryFilter } from "@opt/core";
import { create } from "zustand";
import { Config } from "../../config";
import { TableMetadata } from "@opt/core";

interface DynamicFormsState {
  tableMetadata: TableMetadata | undefined,
  rows: object[],
  queryFilter: QueryFilter,
  fetchTableMetadata: (tableID: string) => Promise<TableMetadata | undefined>,
  query: (filter: QueryFilter, relatedTableID?: string, relatedModelID?: string) => void,
  createModel: (model: Object, relatedTableID?: string, relatedModelID?: string) => Promise<Object>,
  updateModel: (model: Object, relatedTableID?: string, relatedModelID?: string) => Promise<Object>,
  deleteModel: (modelID: string, relatedTableID?: string, relatedModelID?: string) => Promise<any>,
  clear: () => void;
}

export const useDynamicFormsStore = create<DynamicFormsState>((set, get) => ({
  tableMetadata: undefined,
  rows: [],
  queryFilter: new QueryFilter(),

  clear: () => {
    set({ rows: [], queryFilter: new QueryFilter(), tableMetadata: undefined });
  },

  fetchTableMetadata: async (tableID: string) => {
    const { doFetch } = DataFetchAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/table/${tableID}/definitions/metadata`);
    const result = await doFetch("");

    set({ tableMetadata: result });

    return result;
  },

  query: async (filter: QueryFilter, relatedTableID?: string, relatedModelID?: string) => {
    const tableID = get().tableMetadata?.id;

    if (tableID === undefined) return;

    let url;

    if (relatedTableID || relatedTableID) {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}/related/${relatedTableID}/${relatedModelID}`;
    } else {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}`;
    }

    const { doQuery } = DataQueryAPI<Object>(Object, url);
    const query = await doQuery(filter);

    set({ rows: query?.items, queryFilter: query?.queryFilter });
  },

  createModel: async (model: Object, relatedTableID?: string, relatedModelID?: string): Promise<Object> => {
    const tableID = get().tableMetadata?.id;

    let url;

    if (relatedTableID || relatedTableID) {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}/related/${relatedTableID}/${relatedModelID}`;
    } else {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}`;
    }

    const { doCreate } = DataAPI<Object>(Object, url);
    const result = await doCreate(model);

    return result;
  },

  updateModel: async (model: Object, relatedTableID?: string, relatedModelID?: string): Promise<Object> => {
    const tableID = get().tableMetadata?.id;

    let url;

    if (relatedTableID || relatedTableID) {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}/related/${relatedTableID}/${relatedModelID}`;
    } else {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}`;
    }

    const { doUpdate } = DataAPI<Object>(Object, url);
    const result = await doUpdate(model);

    return result;
  },

  deleteModel: async (modelID: string, relatedTableID?: string, relatedModelID?: string): Promise<any> => {
    const tableID = get().tableMetadata?.id;

    let url;

    if (relatedTableID || relatedTableID) {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}/related/${relatedTableID}/${relatedModelID}`;
    } else {
      url = `${Config.Instance.apiEndpoint}/table/${tableID}`;
    }

    const { doDelete } = DataAPI<Object>(Object, url);
    const result = await doDelete(modelID);

    return result;
  },

}));