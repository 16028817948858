import { useMetadataStore } from "@opt/ui-core";
import React, { useEffect, useState } from "react";
import OlMap from 'ol/Map';
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { IdentifyTool, LayerDefinition, LayerType, LegendBuilder, MVTLayer, StyleBuilder } from "@opt/mapping";
import { useAlertsStore } from "./AlertsStore";
import config from "../../config";
import { Drawer, Box, IconButton, Pagination, Typography, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DynamicForm } from "@opt/ui-core";
import { TableMetadata } from "@opt/core";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Circle from "ol/style/Circle";
import { useTranslation } from "react-i18next";

type TenantTablesProps = {
  map?: OlMap
}

const defaultFill = new Fill({
  color: 'rgba(200,20,20,0.4)'
});

const defaultStroke = new Stroke({
  color: 'rgba(200,20,20,0.8)',
  width: 2
});

const defaultStyle: Style = new Style({
  image: new Circle({
    fill: defaultFill,
    stroke: defaultStroke,
    radius: 5,
  }),
  fill: defaultFill,
  stroke: defaultStroke,
});

const TenantTables: React.FC<TenantTablesProps> = ({ map }) => {

  const { t } = useTranslation();
  const { tableMetadata, fetchListTableMetadata } = useMetadataStore();
  const { currentEvent, getDatasetTables } = useAlertsStore();
  const { eventExtent } = useAlertsStore();
  const [showForm, setShowForm] = useState(false);
  const [results, setResults] = useState<Feature<Geometry>[]>([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentResult, setCurrentResult] = useState<object>({});
  const [currentMetadata, setCurrentMetadata] = useState<TableMetadata>();
  const [vectorSource, setVectorSource] = useState<VectorSource>();
  const [layersDefinition, setLayersDefinition] = useState<LayerDefinition[]>([]);

  useEffect(() => {
    getDatasetTables(currentEvent?.eventID as string)
      .then(x => fetchListTableMetadata(x as string[]));
  }, [currentEvent])

  useEffect(() => {
    const definitions = tableMetadata.map(table => {
      const url = `${config.gisServices.url}/mvt/table/${table.id}/{z}/{x}/{y}.pbf`;
      const style = StyleBuilder.FromTableMetadata(table);            
      const definition = new LayerDefinition(table.id as string, LayerType.MVT, url, table.label, 4, false, true, true, eventExtent, style);
      definition.geometryType = table.geometryType;

      if (table.styleFile) {
        const legend =  LegendBuilder.fromSLD(table);
        definition.legend = legend;
      }
      return definition;
    });

    setLayersDefinition(definitions);
  }, [tableMetadata])

  useEffect(() => {
    const source = new VectorSource();
    setVectorSource(source);
    const vectorLayer = new VectorLayer({
      source: source,
      style: defaultStyle
    });

    map?.addLayer(vectorLayer);
    vectorLayer.setZIndex(4);

    return () => {
      map?.removeLayer(vectorLayer);
    }
  }, [map])

  useEffect(() => {
    if (!showForm) {
      vectorSource?.clear();
    }
  }, [showForm])

  const setIdentifyResults = (results: Feature<Geometry>[]) => {
    vectorSource?.clear();

    setResults(results);
    setShowForm(results.length > 0);
    setCurrentIndex(1);

    if (results.length) {
      const layerID = results[0].getProperties()["layer_id"];
      const metadata = tableMetadata.find(x => x.id === layerID);
      setCurrentResult(results[0].getProperties());
      setCurrentMetadata(metadata);

      const first = results[0];
      vectorSource?.addFeature(first.clone());
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentIndex(value);

    const idx = value - 1;
    const layerID = results[idx].getProperties()["layer_id"];
    const metadata = tableMetadata.find(x => x.id === layerID);

    vectorSource?.clear();
    const feature = results[idx];

    setCurrentResult(feature.getProperties());
    setCurrentMetadata(metadata);

    vectorSource?.addFeature(feature.clone());
  };

  return (
    <>
      {map &&
        <>
          <IdentifyTool map={map} setIdentifyResults={setIdentifyResults} targetLayers={tableMetadata.map(x => x.id ?? '')} />
          <Drawer
            disableEnforceFocus
            anchor="right"
            open={showForm}
          >
            <Box>
              <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ margin: "10px 20px", minWidth: "500px", maxWidth: "500px" }}>
              <Typography variant="h5" color="primary" gutterBottom component="div" textAlign="center">
                {t('components.alerts.tenantTables.identifyResults')}
              </Typography>
              {results.length > 1 &&
                <Pagination count={results.length} page={currentIndex} size="small" onChange={handleChange} />}
              <Divider sx={{ margin: "15px" }} />
              <DynamicForm
                metadata={currentMetadata}
                forReadOnly={true}
                model={currentResult}></DynamicForm>
            </Box>
          </Drawer>
        </>
      }
      {map && layersDefinition.length > 0 &&
        layersDefinition.map(definition => {
          return <MVTLayer key={definition.key} definition={definition} map={map} />
        })
      }
    </>
  )
}

export default TenantTables;