import React, { useEffect } from "react";
import { LayerDefinition } from "./LayerDefinition";
import { useMapContext } from "./AppMap";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { FeatureLoadFunction } from "./utils/FeatureLoadFunction";
import OlMap from 'ol/Map';

type FeatureLayerProps = {
  definition: LayerDefinition,
  map?: OlMap,
  setCreatedLayer?: (layer: VectorLayer<VectorSource>) => void
}

const defaultFill = new Fill({
  color: 'rgba(255, 0, 255, 0)'
});

const defaultStroke = new Stroke({
  color: 'rgba(204, 0, 204, 1)',
  width: 2
});

const defaultStyle: Style = new Style({
  image: new Circle({
    fill: defaultFill,
    stroke: defaultStroke,
    radius: 5,
  }),
  fill: defaultFill,
  stroke: defaultStroke,
});

const FeatureLayer: React.FC<FeatureLayerProps> = ({ definition, map, setCreatedLayer }) => {

  const mapContext = useMapContext();

  useEffect(() => {

    const webmap = mapContext?.map ?? map;

    if (!definition || !webmap) return;

    const vectorSource = new VectorSource({
      loader: FeatureLoadFunction(definition.url)
    });
    const layer = new VectorLayer({
      visible: definition.visible,
      extent: definition.extent,
      source: vectorSource,
      style: definition.style ?? defaultStyle
    });

    layer.set("LAYER_DEFINITION", definition);

    vectorSource.on("featuresloadend", e => {
      if (e.features) {
        vectorSource.addFeatures(e.features);
      }
    })

    webmap.addLayer(layer);

    layer.setZIndex(definition.zIndex);

    if (setCreatedLayer) setCreatedLayer(layer);

    return () => {
      webmap.removeLayer(layer);
    }

  }, [definition, mapContext, map])

  return (<></>)
}

export default FeatureLayer;