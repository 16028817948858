import { DataFetchAPI, PostAPI } from "@opt/core";
import { calculateExtent, FeatureFetchAPI } from "@opt/mapping";
import { Feature } from "ol";
import { Extent } from "ol/extent";
import { create } from "zustand";
import config from "../../config";
import { AOI } from "../../models/AOI";
import { EventDetails } from "../../models/EventDetails";
import { ExecutionEvent } from "../../models/ExecutionEvent";
import { ExecutionEventMoment } from "../../models/ExecutionEventMoment";
import { RasterView } from "../../models/RasterView";
import { ZonalIndexStats } from "../../models/ZonalIndexStats";
import { InspectionType } from "../../models/InspectionType";

const { doFetch } = DataFetchAPI<ExecutionEvent>(ExecutionEvent, `${config.apiServices.url}/event`)

const fetchMoments = (eventID: string) => {
  const { doFetchMany } = DataFetchAPI<ExecutionEventMoment>(ExecutionEventMoment, `${config.apiServices.url}/event/${eventID}/history`);
  return doFetchMany;
}

const fetchRasterViews = (eventID: string) => {
  const { doFetchMany } = DataFetchAPI<RasterView>(RasterView, `${config.apiServices.url}/event/${eventID}/rasters`);
  return doFetchMany;
}

const fetchDatasetTables = (eventID: string) => {
  const { doFetchMany } = DataFetchAPI<Object>(Object, `${config.apiServices.url}/event/${eventID}/tables`);
  return doFetchMany;
}

const fetchAOI = (eventID: string) => {
  const { doFetch } = DataFetchAPI<AOI>(AOI, `${config.apiServices.url}/event/${eventID}/aoi`);
  return doFetch;
}

const fetchEventDetails = (eventID: string) => {
  const { doFetch } = DataFetchAPI<EventDetails>(EventDetails, `${config.apiServices.url}/event/${eventID}/details`);
  return doFetch;
}

const fetchZonalStats = (eventID: string) => {
  const { doFetch } = DataFetchAPI<ZonalIndexStats>(ZonalIndexStats, `${config.apiServices.url}/event/${eventID}/zonalstats`);
  return doFetch;
}

const fetchExtension = (eventID: string) => {
  const { doFetch } = FeatureFetchAPI(`${config.gisServices.url}/feature/event/${eventID}/bbox`);
  return doFetch;
}

const postNewInspection = (eventID: string) => {
  const { doPost } = PostAPI(Object, `${config.apiServices.url}/event/${eventID}/inspection`);
  return doPost;
}

const fetchInspectionTypes = () => {
  const { doFetchMany } = DataFetchAPI<InspectionType>(InspectionType, `${config.apiServices.url}/event/inspection/types`);
  return doFetchMany;
}

interface AlertsState {
  targetEvent: ExecutionEvent | undefined;
  currentEvent: ExecutionEvent | undefined;
  relatedAOI: AOI | undefined,
  eventDetails: EventDetails | undefined,
  zonalStats: ZonalIndexStats | undefined,
  moments: ExecutionEventMoment[];
  eventExtent: Extent | undefined,
  keepExtent: boolean,
  inspectionTypes: InspectionType[],

  setTargetEvent: (targetEvent: ExecutionEvent | undefined) => void;
  getExecutionEvent: (eventID: string) => Promise<ExecutionEvent | undefined>;
  getMoments: (eventID: string) => Promise<ExecutionEventMoment[] | undefined>;
  getAOI: (eventID: string) => Promise<AOI | undefined>;
  getEventDetails: (eventID: string) => Promise<EventDetails | undefined>;
  getZonalStats: (eventID: string) => Promise<ZonalIndexStats | undefined>;
  getExtension: (eventID: string, zoneBuffer: number) => Promise<Extent | undefined>;
  getRasterViews: (eventID: string) => Promise<RasterView[] | undefined>;
  getDatasetTables: (eventID: string) => Promise<string[] | undefined>;
  createInspection: (eventID: string, inspectionType: string, observations: string | undefined, featureCollection: any) => Promise<any>;
  getInspectionTypes: () => Promise<InspectionType[] | undefined>;
}

export const useAlertsStore = create<AlertsState>((set, get) => ({
  targetEvent: undefined,
  currentEvent: undefined,
  relatedAOI: undefined,
  eventDetails: undefined,
  zonalStats: undefined,
  moments: [],
  eventExtent: undefined,
  keepExtent: false,
  inspectionTypes: [],

  setTargetEvent: (targetEvent: ExecutionEvent | undefined) => {
    set({ targetEvent: targetEvent, keepExtent: false });
  },

  getExecutionEvent: async (eventID: string) => {
    try {
      const result = await doFetch(eventID);
      const keep = get().targetEvent?.aoiid === result?.aoiid;
      set({
        currentEvent: result,
        keepExtent: keep,
        relatedAOI: undefined,
        eventDetails: undefined,
        zonalStats: undefined,
        eventExtent: undefined
      });

      return result;
    }
    catch (error) {
      set({ currentEvent: undefined });

      return undefined;
    }
  },

  getMoments: async (eventID: string) => {
    const query = await fetchMoments(eventID)();
    set({ moments: query });

    return query;
  },

  getAOI: async (eventID: string) => {
    const query = await fetchAOI(eventID)("");
    set({ relatedAOI: query });

    return query;
  },

  getEventDetails: async (eventID: string) => {
    const query = await fetchEventDetails(eventID)("");
    set({ eventDetails: query });

    return query;
  },

  getZonalStats: async (eventID: string) => {
    const query = await fetchZonalStats(eventID)("");
    set({ zonalStats: query });

    return query;
  },

  getExtension: async (eventID: string, zoneBuffer: number) => {
    const query = await fetchExtension(eventID)("");
    const feature = query?.at(0) as Feature;

    const viewExtent = calculateExtent(feature, zoneBuffer);

    set({ eventExtent: viewExtent });

    return viewExtent;
  },

  getRasterViews: async (eventID: string) => {
    const query = await fetchRasterViews(eventID)();

    return query;
  },

  getDatasetTables: async (eventID: string) => {
    const query = await fetchDatasetTables(eventID)();

    return query as string[];
  },

  createInspection: async (eventID: string, inspectionType: string, observations: string | undefined, featureCollection: any) => {
    const body = {
      inspectionType: inspectionType,
      observations: observations,
      features: featureCollection
    }
    const query = await postNewInspection(eventID)(body);

    return query;
  },

  getInspectionTypes: async () => {
    const query = await fetchInspectionTypes()();

    set({ inspectionTypes: query })

    return query;
  }
}));