import { GeometryType, ITableSLDStyle } from "@opt/core";

export interface LayerStyle {
  defaultVisible: boolean | undefined;
  label: string | undefined;
  styleFunction: string | undefined;
  fillColor: string | undefined;
  strokeColor: string | undefined;
  strokeWidth: number | undefined;
  imageRadius: number | undefined;
  minVisibleZoom: number | undefined;
  maxVisibleZoom: number | undefined;
}
export class LayerStyling implements LayerStyle, ITableSLDStyle {
  public mapProjectID: string | undefined;
  public tableID: string | undefined;
  public styleName: string | undefined;
  public defaultStyle: boolean | undefined;
  public styleType: "SIMPLE" | "FUNCTION" | "SLD";
  public defaultVisible: boolean | undefined;
  public label: string | undefined;
  public styleFunction: string | undefined;
  public legendOverride: string | undefined;
  public fillColor: string | undefined;
  public strokeColor: string | undefined;
  public strokeWidth: number | undefined;
  public imageRadius: number | undefined;
  public minVisibleZoom: number | undefined;
  public maxVisibleZoom: number | undefined;
  public styleFile: string | undefined;
  public textEnabled: boolean;
  public textTableID: string | undefined;
  public textExpression: string | undefined;
  public textColor: string | undefined;
  public textBackgroundColor: string | undefined;
  public textBackgroundWidth: number | undefined;
  public textMaxResolution: number | undefined;
  public textFontSize: number | undefined;
  public geometryType: GeometryType | undefined;

  constructor() {
    this.styleType = "SIMPLE";
    this.textEnabled = false;
  }
}

