import {
  Box, Button, Card, CardContent, Divider, FormControl, InputLabel,
  MenuItem, Select, TextField, Tooltip, Typography
} from "@mui/material";
import { FormatDate } from "@opt/core";
import React, { useEffect, useState } from "react";
import RemoteInspection from "../remote-inspection/RemoteInspection";
import { useInspectionsStore } from "./InspectionStore";
import OlMap from "ol/Map";
import InspectionsRasterView from "./InspectionsRasterView";
import InspectionsTenantTables from "./InspectionsTenantTables";
import { useTranslation } from "react-i18next";
import { useNotifyStore } from "@opt/ui-core";
import MapReport from "../map-report/MapReport";
import STACSearcher from "../explorer/STACSearcher";

interface InspectionDetailsProps {
  inspectionID: string | undefined,
  map: OlMap | undefined,
  onUpdateStatus: () => void,
  isOpen: boolean,
  datasetID?: string
}

const InspectionDetails: React.FC<InspectionDetailsProps> = ({ inspectionID, map, onUpdateStatus, isOpen, datasetID }) => {

  const { t } = useTranslation();
  const { currentInspectionDetails, getDetails, postStatus } = useInspectionsStore();
  const [status, setStatus] = React.useState("PENDING");
  const [opinion, setOpinion] = React.useState("");
  const [requiredText, setRequiredText] = useState("");
  const { addMsg } = useNotifyStore();

  useEffect(() => {
    if (!inspectionID || inspectionID === currentInspectionDetails?.inspectionID) return;

    getDetails(inspectionID);
    setStatus("PENDING");
    setOpinion("");
  }, [inspectionID, currentInspectionDetails]);

  useEffect(() => {
    setStatus("PENDING");
    setOpinion("");
  }, [])

  useEffect(() => {
    if (status === "CONFIRMED_REMOTE" || status === "CONFIRMED_FIELD" || status === "NOT_PROBLEM") {
      setRequiredText(t('components.inspections.details.required'));
    } else {
      setRequiredText("");
    }
  }, [status])

  const handleUpdate = () => {
    postStatus(currentInspectionDetails?.inspectionID as string, status, opinion)
      .then(x => {
        onUpdateStatus();
        addMsg("success", t('components.inspections.details.onSuccess'));
      }).catch(x => {
        addMsg("error", t('components.inspections.details.onError'));
      })
  }

  return (
    <>
      {currentInspectionDetails &&
        <>
          <Tooltip title={currentInspectionDetails.projectID}>
            <Typography noWrap gutterBottom variant="h6" component="div" color="primary">
              {currentInspectionDetails.projectID}
            </Typography>
          </Tooltip>
          {currentInspectionDetails.creationType === "MANUAL" &&
            <>
              <Card sx={{ maxWidth: 370, margin: "10px 20px" }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6" component="div" color="error">
                  {t('components.inspections.details.manualType')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('components.inspections.details.manualDisclaimer')}
                </Typography>
              </Card>
              <STACSearcher map={map} width={370} />
            </>
          }
          {currentInspectionDetails.creationType === "SYSTEM" &&
            <InspectionsRasterView map={map} hideAll={!isOpen} key={currentInspectionDetails.eventID} />
          }
          <RemoteInspection map={map} zIndex={1} />
          <MapReport map={map} eventID={currentInspectionDetails.eventID} inspectionID={currentInspectionDetails.inspectionID} />
          <Card sx={{ maxWidth: 370, margin: "10px 20px" }}>
            <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
              <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
                {t('components.inspections.details.inspectionNumber')}: {currentInspectionDetails.inspectionNumber}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
                {t('components.inspections.details.currentStatus')}: {t(`components.inspections.types.status.${currentInspectionDetails.status}`)}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
                {t('components.inspections.details.inspectionType')}: {currentInspectionDetails.inspectionTypeName}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
                {t('components.inspections.details.dataset')}: {currentInspectionDetails.datasetName}
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
                {t('components.inspections.details.creationDate')}: {FormatDate(currentInspectionDetails.createdDate)}
              </Typography>
              <Tooltip title={currentInspectionDetails.observations}>
                <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.observations')}: {currentInspectionDetails.observations}
                </Typography>
              </Tooltip >
              <Divider sx={{ margin: "10px 0" }} />
              <div hidden={currentInspectionDetails.creationType === "MANUAL"}>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.algorithm')}: {currentInspectionDetails.algorithm}
                </Typography>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.parcelID')}: {currentInspectionDetails.parcelID}
                </Typography>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.projectID')}: {currentInspectionDetails.projectID}
                </Typography>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.sensor')}: {currentInspectionDetails.sensorName}
                </Typography>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.catalog')}: {currentInspectionDetails.stacCatalog}
                </Typography>
                <Typography align={"left"} variant="body2" color="text.secondary">
                  {t('components.inspections.details.sceneDate')}: {FormatDate(currentInspectionDetails.stacItemDate)}
                </Typography>
                <Tooltip title={currentInspectionDetails.stacItemID}>
                  <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                    {t('components.inspections.details.scene')}: {currentInspectionDetails.stacItemID}
                  </Typography>
                </Tooltip >
                {currentInspectionDetails?.baselineStacItemDate &&
                  <Typography align={"left"} variant="body2" color="text.secondary">
                    {t('components.inspections.details.baselineSceneDate')}: {FormatDate(currentInspectionDetails.baselineStacItemDate)}
                  </Typography>
                }
                {currentInspectionDetails.baselineStacItemID &&
                  <Tooltip title={currentInspectionDetails.baselineStacItemID}>
                    <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                      {t('components.inspections.details.baselineScene')}: {currentInspectionDetails.baselineStacItemID}
                    </Typography>
                  </Tooltip >
                }
              </div>
            </CardContent>
          </Card>
          <Divider sx={{ marginTop: "25px" }} />
          <Box sx={{ marginTop: "15px", display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ m: 1, minWidth: 340 }} size="small">
              <InputLabel id="status-select">{t('components.inspections.details.status.title')}</InputLabel>
              <Select
                labelId="status-select"
                id="status-select"
                value={status}
                label="Status"
                onChange={(value) => setStatus(value.target.value)}
              >
                <MenuItem value={"PENDING"}>{t('components.inspections.types.status.PENDING')}</MenuItem>
                <MenuItem value={"VERIFY"}>{t('components.inspections.types.status.VERIFY')}</MenuItem>
                <MenuItem value={"MONITOR"}>{t('components.inspections.types.status.MONITOR')}</MenuItem>
                <MenuItem value={"CONFIRMED_REMOTE"}>{t('components.inspections.types.status.CONFIRMED_REMOTE')}</MenuItem>
                <MenuItem value={"CONFIRMED_FIELD"}>{t('components.inspections.types.status.CONFIRMED_FIELD')}</MenuItem>
                <MenuItem value={"NOT_PROBLEM"}>{t('components.inspections.types.status.NOT_PROBLEM')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 340 }} size="small">
              <TextField
                id="multiline-opinion"
                label={t('components.inspections.details.opinion')}
                multiline
                rows={4}
                placeholder={t('components.inspections.details.opinionPlaceholder')}
                helperText={requiredText}
                value={opinion}
                onChange={(value) => setOpinion(value.target.value)}
              />

            </FormControl>
            <Button
              variant="outlined"
              sx={{ maxWidth: "100px", alignSelf: "center" }}
              disabled={status === "PENDING" || ((status === "CONFIRMED_REMOTE" || status === "CONFIRMED_FIELD" || status === "NOT_PROBLEM") && opinion.trim() === "")}
              onClick={() => handleUpdate()}>{t('components.inspections.details.save')}</Button>
          </Box>
          <InspectionsTenantTables map={map} hideAll={!isOpen} datasetID={datasetID} />
        </>
      }
    </>
  )
}

export default InspectionDetails;