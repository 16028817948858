export class DatasetUserAccess {
  
  public datasetID: string;
  public userID: string;
  public description: string;
  public hasAccess: boolean;
  
  constructor(){
    this.datasetID = "";
    this.userID = "";
    this.description = "";
    this.hasAccess = false;
  }
}