
import { useUserAuthStore } from "@opt/core";
import GeoJSON from "ol/format/GeoJSON";

export const FeatureLoadFunction = (url: string) => {

  const accessToken = useUserAuthStore.getState().accessToken;
  const tenantID = useUserAuthStore.getState().currentTenant.id;

  return async (extent: any,
    resolution: any,
    projection: any,
    success: any,
    failure: any) => {

    fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      })
    })
      .then(async (response) => {
        if (response.ok) {
          const json = await response.json();
          const features = new GeoJSON().readFeatures(json);

          success(features);
        }
        else {
          failure();
        }
      });
  };
};
