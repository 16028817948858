import { Drawer, Box, IconButton, Typography, Pagination, Divider } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { DynamicForm } from "@opt/ui-core";
import { TableMetadata } from "@opt/core";
import VectorSource from "ol/source/Vector";
import { Feature, Map } from "ol";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";

type IdentifyResultsProps = {
  tablesMetadata: TableMetadata[],
  results: Feature<Geometry>[],
  map?: Map
}

const defaultFill = new Fill({
  color: 'rgba(200,20,20,0.4)'
});

const defaultStroke = new Stroke({
  color: 'rgba(200,20,20,0.8)',
  width: 2
});

const defaultStyle: Style = new Style({
  image: new Circle({
    fill: defaultFill,
    stroke: defaultStroke,
    radius: 5,
  }),
  fill: defaultFill,
  stroke: defaultStroke,
});

const IdentifyResults: React.FC<IdentifyResultsProps> = ({ tablesMetadata, results, map }) => {

  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentResult, setCurrentResult] = useState<object>({});
  const [currentMetadata, setCurrentMetadata] = useState<TableMetadata>();
  const [vectorSource, setVectorSource] = useState<VectorSource>();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const source = new VectorSource();
    setVectorSource(source);
    const vectorLayer = new VectorLayer({
      source: source,
      style: defaultStyle
    });

    map?.addLayer(vectorLayer);
    vectorLayer.setZIndex(11);

    return () => {
      map?.removeLayer(vectorLayer);
    }
  }, [map])

  useEffect(() => {
    if (!showForm) {
      vectorSource?.clear();
    }
  }, [showForm])

  useEffect(() => {
    vectorSource?.clear();

    setShowForm(results.length > 0);
    setCurrentIndex(1);

    if (results.length) {
      const layerID = results[0].getProperties()["layer_id"];
      const layerName = results[0].getProperties()["layer"];
      const metadata = getMetadata(layerID, layerName); 

      setCurrentResult(results[0].getProperties());
      setCurrentMetadata(metadata);

      const first = results[0];
      vectorSource?.addFeature(first.clone());
    }
  }, [results])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentIndex(value);

    const idx = value - 1;
    const layerID = results[idx].getProperties()["layer_id"];
    const layerName = results[idx].getProperties()["layer"];
    const metadata = getMetadata(layerID, layerName);

    vectorSource?.clear();
    const feature = results[idx];

    setCurrentResult(feature.getProperties());
    setCurrentMetadata(metadata);

    vectorSource?.addFeature(feature.clone());
  }

  const getMetadata = (layerID: string, layerName: string) => {
    const metadata = tablesMetadata.find(x => x.id === layerID);

    if (metadata) return metadata;

    const metadata2 = tablesMetadata.find(x => x.name === layerName);

    if (metadata2) return metadata2;
  }

  return (
    <>
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "500px", maxWidth: "500px" }}>
          <Typography variant="h5" color="primary" gutterBottom component="div" textAlign="center">
            {t('components.alerts.tenantTables.identifyResults')}
          </Typography>
          {results.length > 1 &&
            <Pagination count={results.length} page={currentIndex} size="small" onChange={handleChange} />}
          <Divider sx={{ margin: "15px" }} />
          {currentMetadata && currentResult &&
            <DynamicForm
              metadata={currentMetadata}
              forReadOnly={true}
              model={currentResult}></DynamicForm>
          }
        </Box>
      </Drawer>
    </>
  )
}

export default IdentifyResults;