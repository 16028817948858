import React from "react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

interface CodeEditorProps {
  value: string,
  onChange?: (code: string) => void,
  readOnly?: boolean,
  style?: {}
}

const CodeEditor: React.FC<CodeEditorProps> = ({ value, onChange, style, readOnly }) => {

  const handleChange = (code: string) => {
    if (onChange) onChange(code);
  }

  const highlightCode = (code: string): string => {
    const highlighted = highlight(code, languages.javascript, 'javascript');
    return highlighted;
  }

  return (
    <>
      <Editor
        style={style}
        value={value}
        readOnly={readOnly}
        onValueChange={code => handleChange(code)}
        highlight={code => highlightCode(code)}
        padding={10}
      />
    </>
  )
}

export default CodeEditor;