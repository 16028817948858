export class ExecutionEvent {

    public eventID: string;
    public executionID: string;
    public tenantID: string;
    public aoiid: string;
    public projectID: string;
    public parcelID: string;
    public algorithmName: string;
    public datasetID: string;
    public alertArea: number;
    public aoiArea: number;
    public sensorID: string;
    public gridName: string;

    public get aoiID() {
        return this.aoiid;
    }

    constructor() {
        this.eventID = "";
        this.executionID = "";
        this.tenantID = "";
        this.aoiid = "";
        this.projectID = "";
        this.parcelID = "";
        this.algorithmName = "";
        this.datasetID = "";
        this.alertArea = 0;
        this.aoiArea = 0;
        this.sensorID = "";
        this.gridName = "";
    }
}