import { Box, Typography } from '@mui/material';
import { useWindowDimensions } from '@opt/core';
import React, { useState } from 'react';
import AlertCard from './AlertCard';
import AlertCardAOI from './AlertCardAOI';
import BaseAlertLayout from './BaseAlertLayout';
import OlMap from 'ol/Map';
import AlertRasterView from './AlertRasterView';
import RemoteInspection from '../remote-inspection/RemoteInspection';
import TenantTables from './TenantTables';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import InspectionPoint from './InspectionPoint';
import { useTranslation } from 'react-i18next';
import MapReport from '../map-report/MapReport';
import { useAlertsStore } from './AlertsStore';

const BurnedAreaDetector: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [map, setMap] = useState<OlMap>();
  const [alertLayer, setAlertLayer] = useState<VectorLayer<VectorSource> | undefined>();
  const [inspectionsLayer, setInspectionsLayer] = useState<VectorLayer<VectorSource> | undefined>();
  const { currentEvent } = useAlertsStore();

  const CreateDetails = (height: number,
    map: OlMap | undefined,
    alertLayer: VectorLayer<VectorSource> | undefined,
    inspectionsLayer: VectorLayer<VectorSource> | undefined) => {
    return <Box sx={{ maxHeight: height - 100, height: height - 100, scrollBehavior: 'smooth' }}>
      <Typography
        color="primary"
        fontWeight={"bold"}
        variant="h5"
        sx={{ padding: "20px" }}>
        {t('components.alerts.burnedAreaDetector.detectionDetails')}
      </Typography>
      <AlertRasterView map={map} />
      <RemoteInspection map={map} zIndex={3} />
      <InspectionPoint map={map} alertLayer={alertLayer} inspectionsLayer={inspectionsLayer} />
      <MapReport map={map} eventID={currentEvent?.eventID} />
      <AlertCardAOI />
      <AlertCard />
      <TenantTables map={map} />
    </Box>
  }

  return (
    <>
      <BaseAlertLayout
        details={CreateDetails(height, map, alertLayer, inspectionsLayer)}
        onMapCreated={setMap}
        onAlertLayerCreated={setAlertLayer}
        onInspectionsLayerCreated={setInspectionsLayer}
      />
    </>
  )
}

export default BurnedAreaDetector;