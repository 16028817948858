import {
  Paper, IconButton, Divider, MenuItem, InputAdornment, TextField,
  Menu, Popover, Typography, List, ListItemButton, ListItemText
} from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Map } from "ol";
import React, { useEffect, useRef, useState } from "react";
import { Control } from "ol/control";
import { DataFetchAPI, SearchResult, TableMetadata } from "@opt/core";
import GeoJSON from "ol/format/GeoJSON";
import { Polygon } from "ol/geom";
import { LayerDefinition, MVTFeatureAnimation, applyBuffer } from "@opt/mapping";
import VectorTileSource from 'ol/source/VectorTile';
import { useNotifyStore } from "@opt/ui-core";
import config from "../../config";
import { useExplorerStore } from "../explorer/ExplorerStore";

interface MapSearchProps {
  datasetID: string,
  map: Map | undefined,
  zoomTo?: boolean,
  onSearchDone?: (table: TableMetadata, result: SearchResult) => void
}

const doSearch = async (tableID: string, datasetID: string, term: string) => {
  const { doFetchMany } = DataFetchAPI<SearchResult>(SearchResult, `${config.apiServices.url}/explorer/${tableID}/search?term=${term}&ds=${datasetID}`);
  const result = await doFetchMany();
  return result;
}

const MapSearch: React.FC<MapSearchProps> = ({ datasetID, map, zoomTo, onSearchDone }) => {

  const { addMsg } = useNotifyStore();
  const inputRef = useRef<HTMLDivElement>(null);
  const [currentTable, setCurrentTable] = useState<TableMetadata | undefined>(undefined);
  const [term, setTerm] = useState("");
  const [layerAnchorEl, setLayerAnchorEl] = useState<null | HTMLElement>(null);
  const isLayersOpen = Boolean(layerAnchorEl);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const { tables } = useExplorerStore();
  const [searchable, setSearchable] = useState<TableMetadata[]>([]);

  useEffect(() => {
    if (!map) return;

    const control = new Control({ element: inputRef.current as HTMLElement });
    map.addControl(control);

    return () => {
      map.removeControl(control);
    }
  }, [map])

  useEffect(() => {
    const filtered = tables.filter(x => !!x.displayField && x.searchColumns.length > 0);
    setSearchable(filtered);
    if (filtered.length === 1) {
      setCurrentTable(filtered[0]);
    }
  }, [tables])

  const handlerQuery = () => {
    if (!currentTable || !term) return;

    doSearch(currentTable?.id as string, datasetID, term)
      .then(results => {
        setSearchResults(results ?? []);

        if (!results?.length) {
          addMsg("info", "A pesquisa não retornou registros.")
        }
      });
  }

  const handleShowLayers = (event: React.MouseEvent<HTMLElement>) => {
    setLayerAnchorEl(event.currentTarget);
  }

  const handleHideLayers = () => {
    setLayerAnchorEl(null);
  }

  const handleCloseResults = () => {
    setSearchResults([]);
  }

  const handleSelectItem = (item: SearchResult) => {
    if (!!zoomTo) {
      const extent = new GeoJSON().readGeometry(item.envelope);

      map?.once("loadend", () => {
        const layer = map?.getAllLayers().find(x => (x.get("LAYER_DEFINITION") as LayerDefinition)?.key === currentTable?.id);
        if (!layer?.getVisible()) {
          layer?.setVisible(true);
        }
        const animation = new MVTFeatureAnimation(map as Map, currentTable as TableMetadata, layer?.getSource() as VectorTileSource);
        animation.animate(currentTable?.name as string, item.id as string);
      })

      const buffSize = Math.max(500, Math.abs(extent.getExtent()[0] - extent.getExtent()[2]) * 0.3);
      const view = applyBuffer(extent, buffSize);
      map?.getView().fit(view as Polygon);
    }

    if (onSearchDone) {
      onSearchDone(currentTable as TableMetadata, item);
    }
  }

  return (
    <>
      <div ref={inputRef} className="ol-control" style={{ top: "7px", right: "50px" }} hidden={searchable.length === 0}>
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu" color="primary" onClick={handleShowLayers}>
            <LayersIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <TextField size="small"
            sx={{
              ml: 1, flex: 1,
              '& .MuiOutlinedInput-input': { paddingTop: "3px", paddingBottom: "3px" }
            }}
            placeholder="Buscar..."
            value={term}
            disabled={!currentTable}
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handlerQuery();
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currentTable?.label}</InputAdornment>,
              endAdornment: <IconButton sx={{ visibility: term ? "visible" : "hidden" }} onClick={() => setTerm("")} >
                <ClearIcon />
              </IconButton>
            }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton sx={{ p: '10px' }} aria-label="menu" color="primary" onClick={handlerQuery}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {searchable.length > 1 &&
        <Menu anchorEl={layerAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          id="layers-choose"
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={isLayersOpen}
          onClose={handleHideLayers}>
          {searchable.map(x => {
            return <MenuItem key={x.id} onClick={() => { setCurrentTable(x); handleHideLayers(); }}>{x.label}</MenuItem>
          })}
        </Menu>
      }
      <Popover
        sx={{ maxHeight: "450px", minWidth: "300px" }}
        id="popover-results"
        open={searchResults?.length > 0}
        anchorEl={inputRef.current}
        onClose={handleCloseResults}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography color="primary" sx={{ p: 2 }}>Resultados</Typography>
        <List component="nav" dense sx={{ width: "350px" }}>
          {searchResults.map(x => {
            return <ListItemButton key={x.id}
              onClick={() => handleSelectItem(x)}
            >
              <ListItemText primary={x.display} />
            </ListItemButton>
          })}
        </List>
      </Popover>
    </>
  )
}

export default MapSearch;