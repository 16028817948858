import { DataFetchAPI } from "@opt/core";
import { create } from "zustand";
import config from "../../config";
import { DatasetFilter } from "../../models/DatasetFilter";
import { DatasetSummary } from "../../models/summary/DatasetSummary";
import { DateSummary } from "../../models/summary/DateSummary";
import { InspectionsSummary } from "../../models/summary/InspectionsSummary";
import { InspectionType } from "../../models/InspectionType";

interface CockpitState {
  numOfExecutions: number,
  numOfEvents: number,
  datasetSummary: DatasetSummary[],
  dateSummary: DateSummary[],
  numOfInspections: number,
  inspectionsPerStatus: InspectionsSummary[],
  inspectionsPerType: InspectionsSummary[],
  inspectionPerDate: DateSummary[],
  filters: Array<DatasetFilter>;
  inspectionTypes: InspectionType[],
  datasetFilter: string;
  algorithmFilter: string;
  inspectionTypeFilter: string;
  filterLastDays: number;

  getFilters: () => Promise<DatasetFilter[] | undefined>,
  setDatasetFilter: (ds: string) => void;
  setAlgorithmFilter: (algo: string) => void;
  setFilterLastDays: (days: number) => void;
  setFilterInspectionType: (type: string) => void;
  getInspectionTypes: () => Promise<InspectionType[] | undefined>;

  getNumOfExecution: (dataset: string, algorithm: string, filterLastDays: number) => void;
  getNumOfEvents: (dataset: string, algorithm: string, filterLastDays: number) => void;
  getSummaryPerDataset: (dataset: string, algorithm: string, filterLastDays: number) => void;
  getSummaryPerDate: (dataset: string, algorithm: string, filterLastDays: number) => void;

  getNumOfInspections: (dataset: string, algorithm: string, type: string, filterLastDays: number) => void;
  getSummaryInspectionsPerStatus: (dataset: string, algorithm: string, type: string, filterLastDays: number) => void;
  getSummaryInspectionsPerDate: (dataset: string, algorithm: string, type: string, filterLastDays: number) => void;
  getSummaryInspectionsPerType: (dataset: string, algorithm: string, type: string, filterLastDays: number) => void;
}

const { doFetchMany: getFilters } = DataFetchAPI<DatasetFilter>(DatasetFilter, `${config.apiServices.url}/execution/filters`)

const getNumOfExecution = (dataset: string, algorithm: string, filterLastDays: number) => {
  const { doFetch } = DataFetchAPI<object>(Object, `${config.apiServices.url}/summary/executions?ds=${dataset}&algo=${algorithm}&days=${filterLastDays}`)
  return doFetch;
}

const getNumOfEvents = (dataset: string, algorithm: string, filterLastDays: number) => {
  const { doFetch } = DataFetchAPI<object>(Object, `${config.apiServices.url}/summary/events?ds=${dataset}&algo=${algorithm}&days=${filterLastDays}`)
  return doFetch;
}

const getSummaryPerDataset = (dataset: string, algorithm: string, filterLastDays: number) => {
  const { doFetchMany } = DataFetchAPI<DatasetSummary>(DatasetSummary, `${config.apiServices.url}/summary/per_ds?ds=${dataset}&algo=${algorithm}&days=${filterLastDays}`)
  return doFetchMany;
}

const getSummaryPerDate = (dataset: string, algorithm: string, filterLastDays: number) => {
  const { doFetchMany } = DataFetchAPI<DateSummary>(DateSummary, `${config.apiServices.url}/summary/per_date?ds=${dataset}&algo=${algorithm}&days=${filterLastDays}`)
  return doFetchMany;
}

const getSummaryInspectionsPerStatus = (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
  const { doFetchMany } = DataFetchAPI<InspectionsSummary>(InspectionsSummary, `${config.apiServices.url}/summary/inspections/per_status?ds=${dataset}&algo=${algorithm}&type=${type}&days=${filterLastDays}`)
  return doFetchMany;
}

const getSummaryNumOfInspections = (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
  const { doFetch } = DataFetchAPI<Object>(Object, `${config.apiServices.url}/summary/inspections?ds=${dataset}&algo=${algorithm}&type=${type}&days=${filterLastDays}`)
  return doFetch;
}

const getSummaryInspectionsPerDate = (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
  const { doFetchMany } = DataFetchAPI<DateSummary>(DateSummary, `${config.apiServices.url}/summary/inspections/per_date?ds=${dataset}&algo=${algorithm}&type=${type}&days=${filterLastDays}`)
  return doFetchMany;
}

const getSummaryInspectionsPerType = (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
  const { doFetchMany } = DataFetchAPI<InspectionsSummary>(InspectionsSummary, `${config.apiServices.url}/summary/inspections/per_type?ds=${dataset}&algo=${algorithm}&type=${type}&days=${filterLastDays}`)
  return doFetchMany;
}

const fetchInspectionTypes = () => {
  const { doFetchMany } = DataFetchAPI<InspectionType>(InspectionType, `${config.apiServices.url}/event/inspection/types`);
  return doFetchMany;
}

export const useCockpitStore = create<CockpitState>((set, get) => ({
  numOfExecutions: 0,
  numOfEvents: 0,
  datasetSummary: [],
  dateSummary: [],
  numOfInspections: 0,
  inspectionsPerStatus: [],
  inspectionsPerType: [],
  inspectionPerDate: [],
  filters: [],
  inspectionTypes: [],
  datasetFilter: "ALL",
  algorithmFilter: "ALL",
  inspectionTypeFilter: "ALL",
  filterLastDays: 15,

  getFilters: async () => {
    const filter = await getFilters();

    set({ filters: filter });

    return filter;
  },

  getInspectionTypes: async () => {
    const query = await fetchInspectionTypes()();
    set({ inspectionTypes: query })
    return query;
  },

  setDatasetFilter: (ds: string) => {
    set({ datasetFilter: ds });
  },

  setAlgorithmFilter: (algo: string) => {
    set({ algorithmFilter: algo });
  },

  setFilterLastDays: (days: number) => {
    set({ filterLastDays: days });
  },

  setFilterInspectionType: (type: string) => {
    set({ inspectionTypeFilter: type });
  },

  getNumOfExecution: async (dataset: string, algorithm: string, filterLastDays: number) => {
    const query = await getNumOfExecution(dataset, algorithm, filterLastDays)("");
    set({ numOfExecutions: (query as unknown) as number });
  },

  getNumOfEvents: async (dataset: string, algorithm: string, filterLastDays: number) => {
    const query = await getNumOfEvents(dataset, algorithm, filterLastDays)("");
    set({ numOfEvents: (query as unknown) as number });
  },

  getSummaryPerDataset: async (dataset: string, algorithm: string, filterLastDays: number) => {
    const query = await getSummaryPerDataset(dataset, algorithm, filterLastDays)();
    set({ datasetSummary: query });
  },

  getSummaryPerDate: async (dataset: string, algorithm: string, filterLastDays: number) => {
    const query = await getSummaryPerDate(dataset, algorithm, filterLastDays)();
    set({ dateSummary: query });
  },

  getNumOfInspections: async (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
    const query = await getSummaryNumOfInspections(dataset, algorithm, type, filterLastDays)("");
    set({ numOfInspections: (query as unknown) as number });
  },

  getSummaryInspectionsPerStatus: async (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
    const query = await getSummaryInspectionsPerStatus(dataset, algorithm, type, filterLastDays)();
    set({ inspectionsPerStatus: query });
  },

  getSummaryInspectionsPerDate: async (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
    const query = await getSummaryInspectionsPerDate(dataset, algorithm, type, filterLastDays)();
    set({ inspectionPerDate: query });
  },

  getSummaryInspectionsPerType: async (dataset: string, algorithm: string, type: string, filterLastDays: number) => {
    const query = await getSummaryInspectionsPerType(dataset, algorithm, type, filterLastDays)();
    set({ inspectionsPerType: query });
  }

}));