import React from "react";
import Keycloak from 'keycloak-js';

export class LoginController {

  constructor(protected kc: Keycloak) {

  }

  doLogout = () => {
    localStorage.setItem("opt.auth.idToken", "");
    localStorage.setItem("opt.auth.token", "");
    localStorage.setItem("opt.auth.refreshToken", "");
    localStorage.setItem("opt.auth.login.lastEmail", "");
    this.kc.logout();
  }
}

export const storeToken = (token: string) => {
  localStorage.setItem("opt.auth.token", token)
}

export const storeRefreshToken = (token: string) => {
  localStorage.setItem("opt.auth.refreshToken", token)
}

export const storeIDToken = (token: string) => {
  localStorage.setItem("opt.auth.idToken", token)
}

export const restoreToken = () => {
  return localStorage.getItem("opt.auth.token") || undefined;
}

export const restoreRefreshToken = () => {
  return localStorage.getItem("opt.auth.refreshToken") || undefined;
}

export const restoreIDToken = () => {
  return localStorage.getItem("opt.auth.idToken") || undefined;
}

export type LoginContext = {
  controller: LoginController
}

export const LoginContextProvider = React.createContext<LoginContext | undefined>(undefined);

export const useLoginContext = () => React.useContext(LoginContextProvider);

export type KeycloakConfig = {
  url: string,
  realm: string,
  clientID: string
}

export type WelcomeConfig = {
  logo?: string,
  title?: string,
  titleColor?: string,
  subtitle?: string,
  subtitleColor?: string,
  background?: string
}