import { useUserAuthStore } from "@opt/core";
import { Feature } from "ol";
import { GeoJSON } from "ol/format";

export const FeatureFetchAPI = (endpoint: string | undefined): {
  doFetch: (modelID: string) => Promise<Feature[] | undefined>
} => {

  const doFetch = async (modelID: string) => {
    if (endpoint === undefined) return;

    const url = modelID === "" || modelID === undefined ? `${endpoint}` : `${endpoint}/${modelID}`;
    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to query model.");

        const json = await response.json();
        const features = new GeoJSON().readFeatures(json);

        return features;
      });

    return result;
  }

  return { doFetch: doFetch }
}