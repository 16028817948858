export class ExecutionDetails {
    public executionID: string;
    public tenantID: string;
    public algorithm: string;
    public algoDescription: string;
    public catalogue: string;
    public item: string;
    public itemDate: Date | undefined;
    public executionDate: Date| undefined;
    public analized: boolean;
    public numberOfEvents: number;

    constructor() {
        this.executionID = "";
        this.tenantID = "";
        this.algorithm = "";
        this.algoDescription = "";
        this.catalogue = "";
        this.item = "";
        this.itemDate = undefined;
        this.executionDate = undefined;
        this.analized = false;
        this.numberOfEvents = 0;
    }
}