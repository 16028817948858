import { DataFetchAPI, DataQueryAPI, FormatDate, PostAPI, QueryFilter } from "@opt/core";
import { AlgorithmFilter } from "../../models/AlgorithmFilter";
import { DatasetFilter } from "../../models/DatasetFilter";
import { ExecutionDetails } from "../../models/ExecutionDetails";
import { create } from 'zustand';
import config from "../../config";
import { ExecutionEvent } from "../../models/ExecutionEvent";
import { startOfDay, subDays } from 'date-fns';

interface ExecutionsState {
  rows: ExecutionDetails[];
  queryFilter: QueryFilter;
  filters: DatasetFilter[],
  datasetFilter: DatasetFilter | undefined;
  algorithmFilter: AlgorithmFilter | undefined;
  startDate: Date;
  endDate: Date;
  alerts: ExecutionEvent[];
  alertsQueryFilter: QueryFilter;
  onlyNotAnalized: boolean;
  setFilters: (datasetFilter: DatasetFilter | undefined, algorithmFilter: AlgorithmFilter | undefined,
    startDate: Date, endDate: Date, onlyNotAnalized: boolean) => void;
  getDatasetFilters: () => Promise<DatasetFilter[] | undefined>;
  queryExecutionDetails: (filter: QueryFilter) => Promise<ExecutionDetails[] | undefined>;
  queryEvents: (executionID: string, filter: QueryFilter) => Promise<ExecutionEvent[] | undefined>;
  setAnalized: (executionID: string, status: boolean) => Promise<any>;
}

const queryExecution = (dataset: string, algorithm: string, startDate: Date, endDate: Date, onlyNotAnalized: boolean) => {
  const sd = FormatDate(startDate);
  const ed = FormatDate(endDate);

  const { doQuery } = DataQueryAPI<ExecutionDetails>(ExecutionDetails,
    `${config.apiServices.url}/execution/details?ds=${dataset ?? 'ALL'}&algo=${algorithm ?? 'ALL'}&sd=${sd}&ed=${ed}&pend=${onlyNotAnalized}`);

  return doQuery;
}

const queryEvents = (executionID: string) => {
  const { doQuery } = DataQueryAPI<ExecutionEvent>(ExecutionEvent,
    `${config.apiServices.url}/execution/${executionID}/events`);

  return doQuery;
}

const { doFetchMany: getFilters } = DataFetchAPI<DatasetFilter>(DatasetFilter, `${config.apiServices.url}/execution/filters`);

const postAnalizedStatus = (executionID: string, status: boolean) => {
  const { doPost } = PostAPI<Object>(Object, `${config.apiServices.url}/execution/${executionID}/${status ? "analized" : "notanalized"}`);
  return doPost;
}

export const useExecutionStore = create<ExecutionsState>((set, get) => ({
  rows: [],
  queryFilter: new QueryFilter(10, "itemDate"),
  filters: [],
  datasetFilter: undefined,
  algorithmFilter: undefined,
  startDate: startOfDay(subDays(new Date(), 5)),
  endDate: new Date(),
  alerts: [],
  alertsQueryFilter: new QueryFilter(),
  onlyNotAnalized: true,

  setFilters: (datasetFilter: DatasetFilter | undefined,
    algorithmFilter: AlgorithmFilter | undefined,
    startDate: Date, endDate: Date,
    onlyNotAnalized: boolean) => {
    set({ datasetFilter: datasetFilter, algorithmFilter: algorithmFilter, startDate: startDate, endDate: endDate, onlyNotAnalized: onlyNotAnalized });
  },

  queryExecutionDetails: async (filter: QueryFilter) => {
    const query = await queryExecution(get().datasetFilter?.id as string,
      get().algorithmFilter?.name as string,
      get().startDate,
      get().endDate,
      get().onlyNotAnalized)
      (filter);

    set({ rows: query?.items, queryFilter: query?.queryFilter });

    return query?.items;
  },

  getDatasetFilters: async () => {
    const query = await getFilters();

    set({ filters: query });

    return query;
  },

  queryEvents: async (executionID: string, filter: QueryFilter) => {
    const query = await queryEvents(executionID)(filter);

    set({ alerts: query?.items, alertsQueryFilter: query?.queryFilter });

    return query?.items;
  },

  setAnalized: async (executionID: string, status: boolean) => {
    return await postAnalizedStatus(executionID, status)("");
  }
}));