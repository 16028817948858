export class DatasetSummary {

    public dataset: string;
    public executions: number;
    public events: number;

    constructor() {
        this.dataset = "";
        this.executions = 0;
        this.events = 0;
    }
}