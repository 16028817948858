import { Box, Typography, Button, Slider, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import useMetadataStore from "./MetadataStore";
import { RgbaColor } from "react-colorful";
import ColorPicker, { fromRgbaString, toRgbaString } from "../ColorPicker";
import { CloneModels, GeometryType } from "@opt/core";
import { TableMetadataSymbol } from "@opt/core";
import { useNotifyStore } from "../Notifyer";
import CodeEditor from "../CodeEditor";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  }
}

interface SymbolMetadataViewProps {
  modelID: string | undefined
}

const SymbolMetadataView: React.FC<SymbolMetadataViewProps> = ({ modelID }) => {

  const defaultColor = { r: 105, g: 123, b: 132, a: 1 };
  const { addMsg } = useNotifyStore();
  const { currentTableMetadata, fetchTableMetadata, updateTableMetadataSymbol } = useMetadataStore();

  const [symbolType, setSymbolType] = useState<"SIMPLE" | "SLD">("SIMPLE");
  const [styleFile, setStyleFile] = useState<string>('');

  const [fillColor, setFillColor] = useState<RgbaColor>(defaultColor);
  const [strokeColor, setStrokeColor] = useState<RgbaColor>(defaultColor);

  const [strokeWidth, setStrokeWidth] = useState<number>(1);
  const [imageRadius, setImageRadius] = useState<number>(5);

  const [hasRadius, setHasRadius] = useState<boolean>(false);
  const [hasFill, setHasFill] = useState<boolean>(false);

  useEffect(() => {
    if (!modelID) return;
    fetchTableMetadata(modelID);
  }, [modelID]);

  useEffect(() => {
    if (!currentTableMetadata) return;

    const radius = currentTableMetadata.geometryType === GeometryType.Point || currentTableMetadata.geometryType === GeometryType.MultiPoint;
    setHasRadius(radius);

    const fill = !(currentTableMetadata.geometryType === GeometryType.LineString || currentTableMetadata.geometryType === GeometryType.MultiLineString);
    setHasFill(fill);

    setFillColor(fromRgbaString(currentTableMetadata.fillColor, defaultColor));
    setStrokeColor(fromRgbaString(currentTableMetadata.strokeColor, defaultColor));
    setStrokeWidth(currentTableMetadata.strokeWidth ?? 1);
    setImageRadius(currentTableMetadata.imageRadius ?? 5);
    setStyleFile(currentTableMetadata.styleFile ?? '');

    setSymbolType(currentTableMetadata.styleFile ? "SLD" : "SIMPLE");
  }, [currentTableMetadata]);

  const handleUpdate = () => {
    const model = new TableMetadataSymbol();
    model.id = currentTableMetadata?.id;
    model.fillColor = toRgbaString(fillColor);
    model.strokeColor = toRgbaString(strokeColor);
    model.strokeWidth = strokeWidth;
    model.imageRadius = imageRadius;
    model.styleFile = symbolType === "SLD" ? styleFile : undefined;

    updateTableMetadataSymbol(model)
      .then(() => {
        addMsg("success", "Simbologia atualizada com sucesso.");
      })
      .catch(() => {
        addMsg("error", "Não foi possível atualizar a simbologia.")
      });
  }

  const handleLoadFile = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event: any) {
      const result = event.target.result;
      setStyleFile(result);
    }
    reader.readAsText(file);
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Simbologia Padrão - {currentTableMetadata?.name}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Simbologia padrão da classe de feição
        </Typography>
      </Box>
      <Box>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={symbolType}
          exclusive
          onChange={(e, value) => { setSymbolType(value); }}
          aria-label="Platform"
          sx={{ maxHeight: "32px" }}
        >
          <ToggleButton value="SIMPLE">Simples</ToggleButton>
          <ToggleButton value="SLD">Arquivo SLD</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {symbolType === "SIMPLE" &&
        <Box sx={{ padding: "20px" }}>
          {hasFill &&
            <ColorPicker
              label={<Typography color="primary" sx={{ marginLeft: "10px", marginTop: "2px" }}>Cor do Preenchimento</Typography>}
              color={fillColor}
              onChange={(color) => setFillColor(color)} />
          }
          <ColorPicker
            label={<Typography color="primary" sx={{ marginLeft: "10px", marginTop: "2px" }}>Cor da Linha</Typography>}
            color={strokeColor}
            onChange={(color) => setStrokeColor(color)} />
          <Box sx={{ maxWidth: "400px" }}>
            <Typography gutterBottom align="left" color="primary">
              Espessura da linha: {strokeWidth}
            </Typography>
            <Slider
              value={strokeWidth}
              valueLabelDisplay="auto"
              onChange={(event: any) => setStrokeWidth(Number(event.target?.value))}
              step={1}
              marks
              min={1}
              max={10} />
          </Box>
          {hasRadius &&
            <Box sx={{ maxWidth: "400px" }}>
              <Typography gutterBottom align="left" color="primary">
                Tamanho do Círculo: {imageRadius}
              </Typography>
              <Slider
                value={imageRadius}
                valueLabelDisplay="auto"
                onChange={(event: any) => setImageRadius(Number(event.target?.value))}
                step={1}
                marks
                min={1}
                max={10} />
            </Box>
          }
        </Box>
      }
      {symbolType === "SLD" &&
        <Box sx={{ marginTop: "15px" }}>
          <input type="file" accept=".sld" id="sld-file-uploader" onChange={(e) => handleLoadFile(e)} />
          {styleFile &&
            <CodeEditor
              value={styleFile}
              readOnly
              style={{ border: "#878787 solid 2px", fontSize: 12 }} />
          }
        </Box>
      }
      <Box sx={styles.searchBox}>
        <Button size="small" variant="contained" onClick={() => handleUpdate()}>Salvar</Button>
      </Box>
    </>
  )
}

export default SymbolMetadataView;