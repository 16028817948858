export class SensorAlgorithmAvailability {

  public sensorID: string;
  public description: string;
  public hasAccess: boolean;
  public hasDataset: boolean;

  constructor() {
    this.sensorID = "";
    this.description = "";
    this.hasAccess = false;
    this.hasDataset = false;
  }

}