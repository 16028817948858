
export class AOI {

  public id: string;
  public tenantID: string;
  public projectID: string;
  public parcelID: string;
  public datasetName: string;
  public shapeArea: number;

  constructor() {
    this.id = "";
    this.tenantID = "";
    this.projectID = "";
    this.parcelID = "";
    this.datasetName = "";
    this.shapeArea = 0;
  }
}