import { GeometryType, ITableSLDStyle } from "@opt/core";
import { Type } from "class-transformer";
import { FieldMetadata } from "./FieldMetadata";
import { FormsMetadata } from "./FormsMetadata";

export class TableMetadata implements ITableSLDStyle {

  public id: string | undefined;
  public name: string;
  public label: string;
  public description: string;
  public spatialReferenceID: number;
  public geometryType: GeometryType;
  public isInternal: boolean;

  public fillColor: string | undefined;
  public strokeColor: string | undefined;
  public strokeWidth: number | undefined;
  public imageRadius: number | undefined;
  public displayField: string | undefined;
  public styleFile: string | undefined;
  public searchColumns: string[];

  @Type(() => FieldMetadata)
  public fieldsMetadata: FieldMetadata[];

  @Type(() => FormsMetadata)
  public formsMetadata: FormsMetadata[];

  constructor() {
    this.id = undefined;
    this.name = "";
    this.label = "";
    this.description = "";
    this.spatialReferenceID = 0;
    this.geometryType = GeometryType.None;
    this.isInternal = false;
    this.fieldsMetadata = [];
    this.formsMetadata = [];
    this.searchColumns = [];
  }

  getKeyAttribute(): string {
    const attr = this.fieldsMetadata.find(x => x.isKey);

    return attr?.name ?? "";
  }

  getDefaultForm(): FormsMetadata | undefined {
    const form = this.formsMetadata?.find(x => x.isDefault);

    return form;
  }

  clone(): TableMetadata {
    const newOne = new TableMetadata();
    newOne.id = this.id;
    newOne.name = this.name;
    newOne.label = this.label;
    newOne.description = this.description;
    newOne.spatialReferenceID = this.spatialReferenceID;
    newOne.isInternal = this.isInternal;
    newOne.geometryType = this.geometryType;
    newOne.fieldsMetadata = this.fieldsMetadata;
    newOne.formsMetadata = this.formsMetadata;
    newOne.fillColor = this.fillColor;
    newOne.strokeColor = this.strokeColor;
    newOne.strokeWidth = this.strokeWidth;
    newOne.imageRadius = this.imageRadius;
    newOne.displayField = this.displayField;
    newOne.searchColumns = this.searchColumns;

    return newOne;
  }
}