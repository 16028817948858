import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Config, Notifyer } from '@opt/ui-core';
import { BrowserRouter } from 'react-router-dom';
import { KeycloakSecureApp } from '@opt/core';
import { styled, Typography } from '@mui/material';

Config.Instance.apiEndpoint = config.apiServices.url;

const Root = styled('div')({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  background: "radial-gradient(circle, rgba(183,218,86,1) 0%, rgba(105,123,132,1) 100%)"
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const welcomeConfig = {
  logo: "monitora_logo.png",
  title: "Bem-vindo ao OPT Monitora!",
  titleColor: "#697B84",
  subtitle: "Faça seu login para prosseguir.",
  subtitleColor: "#697B84",
  background: ""
}

root.render(
  <KeycloakSecureApp
    welcomeConfig={welcomeConfig}
    AuthenticatedTemplate={
      <React.StrictMode>
        <ProSidebarProvider>
          <BrowserRouter basename={config.base_href}>
            <Notifyer>
              <App />
            </Notifyer>
          </BrowserRouter>
        </ProSidebarProvider>
      </React.StrictMode>
    }
    config={
      {
        "realm": config.auth.realm,
        "clientID": config.auth.clientId,
        "url": config.auth.url
      }
    } />
);

reportWebVitals();
