import {
  Box, Card, CardContent, Drawer, FormControl, IconButton, InputAdornment, InputLabel, ListItemButton,
  ListItemIcon, ListItemText, OutlinedInput
} from "@mui/material";
import { calculateExtent } from "@opt/mapping";
import { Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import React, { useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import OlMap from "ol/Map";
import { FormatDate, useDebounce, useWindowDimensions } from "@opt/core";
import CloseIcon from '@mui/icons-material/Close';
import InspectionDetails from "./InspectionDetails";
import { styled } from "@mui/material/styles";
import { Geometry } from "ol/geom";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { useTranslation } from "react-i18next";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useInspectionsStore } from "./InspectionStore";
import ClearIcon from '@mui/icons-material/Clear';

const DetailsDrawer = styled(Drawer)({
  '.MuiDrawer-paper': {
    marginTop: "65px",
    height: "calc(100% - 65px)"
  }
});

interface InspectionListProps {
  map: OlMap | undefined,
  inspectionsLayer: VectorLayer<VectorSource> | undefined,
  datasetID?: string
}

const InspectionList: React.FC<InspectionListProps> = ({ map, inspectionsLayer, datasetID }) => {

  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const [features, setFeatures] = useState<Feature[] | undefined>([]);
  const [showForm, setShowForm] = useState(false);
  const [inspectionID, setInspectionID] = useState();
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  let handler: NodeJS.Timeout;
  const [highlight, setHighlight] = useState<Feature | undefined>();

  const { setFilterInspectionNumber, clearCurrentInspectionDetails } = useInspectionsStore();
  const [inspectionNumber, setInspectionNumber] = React.useState('');
  const debouncedInspectionNumber = useDebounce(inspectionNumber, 500);

  useEffect(() => {
    setFilterInspectionNumber(debouncedInspectionNumber);
  }, [debouncedInspectionNumber]);

  useEffect(() => {
    const source = new VectorSource({ wrapX: false });
    setVectorSource(source);

    const style = new Style({
      stroke: new Stroke({
        color: "rgba(255,255,0,1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,255,0,0)",
      }),
    });

    new VectorLayer({
      source: source,
      style: style,
      map: map
    });

  }, [map])

  useEffect(() => {
    if (!inspectionsLayer) return;

    const updateFeatures = () => {
      setFeatures(inspectionsLayer.getSource()?.getFeatures());
    }

    updateFeatures();
    inspectionsLayer.on("change", updateFeatures);

    return () => {
      inspectionsLayer.un("change", updateFeatures)
    }
  }, [inspectionsLayer]);

  useEffect(() => {
    vectorSource?.clear();

    if (highlight) {
      const clone = highlight.clone();
      vectorSource?.addFeature(clone);

      const viewExtent = calculateExtent(highlight.clone(), 300);

      if (viewExtent) {
        map?.getView().fit(viewExtent);
      }
    }
  }, [highlight])

  const handleMoreInfo = (feature: Feature | undefined) => {
    if (handler) clearTimeout(handler);

    setHighlight(feature);
    handler = setTimeout(() => {
      setHighlight(undefined);
    }, 3000);

    setInspectionID(feature?.getProperties()["ID"]);
    setShowForm(true);
  }

  const handleUpdateStatus = () => {
    inspectionsLayer?.getSource()?.refresh();
    clearCurrentInspectionDetails();
    setShowForm(false);
  }

  const handleChangeNumber = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setInspectionNumber(e.target.value);
    }
  };

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    return (
      <>
        <ListItemButton style={style} key={`2-${index}`} onClick={() => handleMoreInfo(features?.at(index))}>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText sx={{ minWidth: "90px", width: "90px" }} secondary={`Nº ${features?.at(index)?.getProperties()["InspectionNumber"]}`} />
          <ListItemText secondary={`Apontado em ${FormatDate(features?.at(index)?.getProperties()["CreatedDate"])}`} />
        </ListItemButton>
      </>
    );
  }

  return (
    <>
      <Card sx={{ margin: "5px" }}>
        <CardContent>
          <Box sx={{ marginBottom: "10px" }}>
            <FormControl sx={{ width: "300px", marginTop: "5px" }} >
              <InputLabel htmlFor="outlined-inspection-number">{t('components.inspections.list.filters.inspectionNumber')}</InputLabel>
              <OutlinedInput
                id="outlined-inspection-number"
                size="small"
                value={inspectionNumber}
                onChange={(e) => handleChangeNumber(e)}
                label={t('components.inspections.list.filters.inspectionNumber')}
                inputProps={{ inputMode: 'numeric' }}
                endAdornment={
                  <InputAdornment position="end" sx={{ visibility: inspectionNumber ? 'visible' : 'hidden' }}>
                    <IconButton
                      onClick={() => setInspectionNumber('')}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <FixedSizeList
            height={height - 270}
            width={370}
            itemSize={30}
            itemCount={features ? features.length : 0}
            overscanCount={100}
          >
            {renderRow}
          </FixedSizeList>
        </CardContent>
      </Card>
      <DetailsDrawer
        anchor="right"
        variant="persistent"
        open={showForm}
      >
        <Box display="flex" justifyContent="flex-start">
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "380px", maxWidth: "460px" }}>
          <InspectionDetails map={map}
            inspectionID={inspectionID}
            onUpdateStatus={handleUpdateStatus}
            isOpen={showForm}
            datasetID={datasetID} />
        </Box>
      </DetailsDrawer>
    </>
  )
}

export default InspectionList;