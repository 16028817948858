import {
  Box, Typography, InputLabel, Input, FormHelperText, FormControlLabel,
  Switch, FormControl, FormGroup
} from "@mui/material";
import { Form, FormBuilder } from "@tsed/react-formio";
import { ComponentSchema } from "formiojs";
import React, { useEffect, useRef, useState } from "react";
import { FormsMetadata } from "@opt/core";
import SideEditForm from "../SideEditForm";

const options = {
  builder: {
    premium: false,
    advanced: {
      components: {
        signature: false,
        survey: false,
        address: false
      }
    },
    data: {
      components: {
        datagrid: false,
        editgrid: false,
        datamap: false,
        tree: false
      }
    }
  }
}

interface FormsMetadataFormProps {
  tableID: string,
  forUpdate: boolean,
  forReadOnly: boolean,
  model: FormsMetadata,
  onSubmit: (model: FormsMetadata) => void
}

const FormsMetadataForm: React.FC<FormsMetadataFormProps> = ({ tableID, forUpdate, forReadOnly, model, onSubmit }) => {

  const [formValue, setFormValue] = useState<FormsMetadata>(new FormsMetadata());

  useEffect(() => {
    const value = forUpdate || forReadOnly ? model : new FormsMetadata();

    const timer = setTimeout(() => {
      setFormValue(value);
    }, 200);

    return () => {
      clearTimeout(timer);
    }
  }, [forUpdate, forReadOnly])

  let frmJson = "";
  const builder = useRef<FormBuilder | null>(null);

  const handleControlValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name: propName, value } = event.target;
    Reflect.set(formValue, propName, value);
    setFormValue(formValue.clone());
  }

  const handleChangeForms = (components: ComponentSchema<any>[]) => {
    const componentsAsStr = JSON.stringify(components);
    frmJson = componentsAsStr;

    formValue.metadata = frmJson;
  }

  const handleControlCkeckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: propName, checked } = event.target;
    Reflect.set(formValue, propName, checked);
    setFormValue(formValue.clone());
  };

  return (
    <>
      <SideEditForm
        title="Forms Metadata"
        forUpdate={forUpdate}
        forReadOnly={forReadOnly}
        onSubmit={() => onSubmit(formValue)}>
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="name">Nome</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="name"
              name="name"
              aria-describedby="name-helper-text"
              value={formValue?.name}
              onChange={handleControlValueChange} />
            <FormHelperText id="name-helper-text">Nome do atributo</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="description">Descrição</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="description"
              name="description"
              aria-describedby="description-helper-text"
              value={formValue?.description}
              onChange={handleControlValueChange} />
            <FormHelperText id="description-helper-text">Label para exibição do atributo</FormHelperText>
          </FormControl>
          <FormControl sx={{ mt: 2 }}>
            <FormControlLabel sx={{ maxWidth: 135 }}
              value="top"
              control={<Switch color="primary"
                name="isDefault"
                disabled={forReadOnly}
                checked={formValue.isDefault}
                onChange={handleControlCkeckedChange} />}
              label={<Typography color="rgba(0, 0, 0, 0.6)">Formulário Principal</Typography>}
              labelPlacement="start"
            />
            <FormHelperText id="order-helper-text">Define se o formulário é o principal</FormHelperText>
          </FormControl>
          <FormGroup>
            {forReadOnly
              ? <Form form={{ display: "wizard", components: formValue.getComponents() }}
                options={{ readOnly: true }}></Form>
              : <Box sx={{ maxHeight: "550px", overflowY: "scroll", overflowX: "hidden", paddingLeft: "10px" }}>
                <FormBuilder ref={builder}
                  display={"wizard"}
                  components={formValue.getComponents()}
                  onChange={handleChangeForms}
                  options={options} />
              </Box>
            }
          </FormGroup>
        </>
      </SideEditForm>
    </>
  )
}

export default FormsMetadataForm;