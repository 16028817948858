import {
  Box, Button, Card, CardContent, Divider, LinearProgress, ListItemButton,
  ListItemIcon, ListItemText, Modal, Stack, TextField, Typography
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OlMap from 'ol/Map';
import { LayerDefinition, LayerType, MapExporter } from "@opt/mapping";
import { MapSection } from "./MapSection";
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DownloadAPI, useUserAuthStore } from "@opt/core";
import saveAs from "file-saver";
import { useNotifyStore } from "@opt/ui-core";
import { GeoJSON } from "ol/format";
import { fromExtent } from 'ol/geom/Polygon';
import { Extent } from "ol/extent";
import config from "../../config";

type MapReportProps = {
  map: OlMap | undefined,
  eventID?: string | undefined,
  inspectionID?: string | undefined
}

const MapReport: React.FC<MapReportProps> = ({ map, eventID, inspectionID }) => {

  const { addMsg } = useNotifyStore();
  const { username, email, currentTenant } = useUserAuthStore();
  const { doPostDownload } = DownloadAPI(`${config.reportServices.url}/${currentTenant.detectionReportID}`);

  const [sectionList, setSectionList] = useState<MapSection[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loadingMap, setLoadingMap] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState(new MapSection());

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    return (
      <>
        <ListItemButton style={style} key={index}>
          <ListItemIcon onClick={e => handleDeleteSection(index)}>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText secondary={`Seção ${index + 1}`} />
        </ListItemButton>
      </>
    );
  }

  const handleDeleteSection = (index: number) => {
    const deleted = sectionList.splice(index, 1);
    setSectionList([...sectionList]);
  }

  const handleAddSection = () => {
    setShowModal(true);
  }

  const handleConfirmAdd = () => {
    sectionList.push(currentSection);
    setSectionList([...sectionList])
    setShowModal(false);
    setCurrentSection(new MapSection());
  }

  const handleCloseModal = (event: any | undefined, reason: any | undefined) => {
    if (reason) {
      return;
    }

    setShowModal(false);
    setCurrentSection(new MapSection());
  }

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const extent = map?.getView().calculateExtent() as Extent;
    const polygon = fromExtent(extent);
    const geom = new GeoJSON().writeGeometry(polygon);

    let url = '';

    if (!!inspectionID || !!eventID) {
      url = !!inspectionID ? `${window.location.href}/${inspectionID}` : window.location.href;
    }

    const body = {
      "event_id": eventID,
      "inspection_id": inspectionID,
      "extent": geom,
      "sections": JSON.stringify(sectionList),
      "url": url,
      "user": `${username} (${email})`
    }

    doPostDownload(body)
      .then(data => {
        if (data === undefined) {
          addMsg("error", t('components.mapReports.report.error'));
          return;
        }
        saveAs(data, "Relatório.pdf");
        addMsg("success", t('components.mapReports.report.success'));
        setGeneratingReport(false);
      }).catch(_ => {
        addMsg("error", t('components.mapReports.report.error'));
        setGeneratingReport(false);
      });
  }

  useEffect(() => {
    if (showModal) {
      setLoadingMap(true);
    }
  }, [showModal])

  useEffect(() => {
    if (loadingMap) {
      const metadatas = map?.getAllLayers()
        .filter(x => {
          const definition = x.get("LAYER_DEFINITION") as LayerDefinition;
          return x.isVisible() && definition && definition.type === LayerType.Tiled && definition.metadata;
        })
        .map(x => (x.get("LAYER_DEFINITION") as LayerDefinition).metadata);

      const exporter = new MapExporter();
      exporter.export(map as OlMap)
        .then(x => {
          setLoadingMap(false);
          const section = currentSection.clone();
          section.map = x;
          section.metadata = metadatas?.map(x => `-Imagem do catálogo ${x.provider} de ${x.date} e identificador ${x.id}.`) ?? [];
          setCurrentSection(section);
        })
        .catch(x => {
          console.log(x)
        });
    }
  }, [loadingMap])

  return (
    <>
      {!!currentTenant.detectionReportID &&
        <>
          <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
            <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
              <Typography gutterBottom variant="h6" component="div" color="primary">
                {t('components.mapReports.title')}
              </Typography>
              <Button variant="outlined" size="small" onClick={handleAddSection}>
                {t('components.mapReports.addMap')}
              </Button>
              {sectionList.length > 0 &&
                <Box sx={{ marginTop: "10px" }}>
                  <Divider />
                  <Typography id="modal-modal-title" variant="h6" component="h2" color="primary">
                    {t('components.mapReports.sections')}
                  </Typography>
                  <FixedSizeList
                    height={100}
                    width={300}
                    itemSize={30}
                    itemCount={sectionList.length}
                    overscanCount={100}
                  >
                    {renderRow}
                  </FixedSizeList>

                  <LoadingButton
                    size="small"
                    loading={generatingReport}
                    loadingPosition="end"
                    endIcon={<FileDownloadIcon />}
                    variant="outlined"
                    sx={{ width: "200px", minWidth: "200px" }}
                    onClick={() => handleGenerateReport()}
                  >
                    {t('components.mapReports.generate')}
                  </LoadingButton>
                </Box>
              }
            </CardContent>
          </Card>
          <Modal
            open={showModal}
            onClose={handleCloseModal}
          >
            <Box sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 660,
              bgcolor: 'background.paper',
              border: '1px solid #000',
              boxShadow: 24,
              p: 4
            }}>
              <Typography id="modal-modal-title" variant="h6" component="h2" color="primary">
                {t('components.mapReports.modal.addSectionTitle')}
              </Typography>
              <TextField
                label={t('components.mapReports.modal.description')}
                multiline
                rows={2}
                value={currentSection.description}
                onChange={(e) => {
                  const section = currentSection.clone();
                  section.description = e.target.value;
                  setCurrentSection(section);
                }}
                sx={{ width: "600px", marginBottom: "10px", marginTop: "15px" }}
                disabled={loadingMap}
              />
              {loadingMap &&
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              }
              {!loadingMap &&
                <>
                  <img src={currentSection.map} width="600px" />
                  {currentSection.metadata.map((x, i) => {
                    return <Typography key={`idx_${i}`} display="block" variant="caption" color="primary">
                      {x}
                    </Typography>
                  })}
                </>
              }
              <Stack direction="row" sx={{ justifyContent: "center", gap: 2 }} p={1}>
                <Button variant="outlined" size="small" onClick={handleConfirmAdd} color="success" disabled={loadingMap}>
                  {t('components.mapReports.modal.confirm')}
                </Button>
                <Button variant="outlined" size="small" onClick={() => handleCloseModal(undefined, undefined)} disabled={loadingMap}>
                  {t('components.mapReports.modal.cancel')}
                </Button>
              </Stack>
            </Box>
          </Modal >
        </>
      }
    </>
  )
}

export default MapReport;