import { Extent } from "ol/extent";
import { GenericModel } from "../../models/GenericModel";
import { StacItem } from "../../models/StacItem";
import { ISTACSearchProvider } from "./ISTACSearchProvider";
import { transformExtent } from "ol/proj";
import { LayerDefinition, LayerMetadata, LayerType } from "@opt/mapping";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromExtent } from "ol/geom/Polygon";
import GeoJSON from 'ol/format/GeoJSON';
import { FormatDate } from "@opt/core";

export class PlanetaryComputerStacSearchProvider implements ISTACSearchProvider {

  constructor() {

  }

  getCollections = async () => {
    const model = new GenericModel();
    model.id = "sentinel-2-l2a";
    model.description = "Sentinel2-l2a";

    return [model];
  }

  queryScenes = async (collection: string, extent: Extent, startDate: Date, endDate: Date) => {
    const bbox = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    const polygon = fromExtent(bbox);
    const jsonPolygon = JSON.parse(new GeoJSON().writeGeometry(polygon));

    const filter = {
      "filter-lang": "cql2-json",
      "filter": {
        "op": "and",
        "args": [
          { "op": "=", "args": [{ "property": "collection" }, collection] },
          {
            "op": "s_intersects", "args": [{ "property": "geometry" },
              jsonPolygon
            ]
          },
          {
            "op": "anyinteracts", "args": [{ "property": "datetime" },
            { "interval": [startDate.toISOString(), endDate.toISOString()] }]
          }]
      },
      "sortby": [{ "field": "datetime", "direction": "desc" }],
      "limit": 50
    }

    const result = await fetch('https://planetarycomputer.microsoft.com/api/stac/v1/search', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/geo+json'
      }),
      body: JSON.stringify(filter)
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to post data.");
        const features = await response.json();

        const items = features?.features?.map((x: any) => {
          const item = new StacItem();
          item.collection = collection;
          item.id = x.id;
          item.date = x.properties.datetime;
          item.tile = x.properties["s2:mgrs_tile"];
          item.cloudCover = x.properties["eo:cloud_cover"]

          return item;
        });

        return items;
      });

    return result;
  }

  createItemLayer = (item: StacItem) => {
    const url = `https://planetarycomputer.microsoft.com/api/data/v1/item/tiles/WebMercatorQuad/{z}/{x}/{y}?collection=${item.collection}&item=${item.id}&assets=B04&assets=B03&assets=B02&unscale=false&resampling=nearest&color_formula=Gamma%20RGB%203.5%20Saturation%201.7%20Sigmoidal%20RGB%2015%200.35&return_mask=true`;
    const definition = new LayerDefinition("raster-free-view", LayerType.Tiled, url, "", 0, true);

    const metadata = new LayerMetadata();
    metadata.provider = item.collection;
    metadata.id = item.id;
    metadata.date = FormatDate(item.date);

    definition.metadata = metadata;

    const layer = new TileLayer({
      visible: definition.visible,
      extent: definition.extent,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        crossOrigin: "anonymous",
        cacheSize: 1000,
        url: definition.url
      })
    });

    layer.set("LAYER_DEFINITION", definition);

    return layer;
  }
}