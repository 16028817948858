import React, { useState } from "react";
import {
  Box, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Slide,
  Step, StepLabel, Stepper, styled, Switch, Typography
} from "@mui/material";
import { AlgorithmFilter } from "../models/AlgorithmFilter";
import { DatasetFilter } from "../models/DatasetFilter";
import { useExecutionStore } from "../components/execution/ExecutionStore";
import { QueryFilter } from "@opt/core";
import { DataGrid, DateRange, useNotifyStore } from "@opt/ui-core";
import { GridColDef, getGridStringOperators } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ExecutionDetails } from "../models/ExecutionDetails";
import { useTranslation } from "react-i18next";
import { startOfDay, endOfDay, subDays } from 'date-fns';

const Root = styled('div')({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  height: "100%"
});

const styles = {
  stepper: {
    maxHeight: "80px",
    minHeight: "80px",
    padding: "20px"
  }
}

const ExecutionsPage: React.FC = (): JSX.Element => {

  const { t } = useTranslation();

  const ranges: { label: string, value: [Date, Date] }[] = [
    {
      label: `${t('pages.executions.today')}`,
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: `${t('pages.executions.lastDays')} 5 ${t('pages.executions.days')}`,
      value: [startOfDay(subDays(new Date(), 5)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.executions.lastDays')} 15 ${t('pages.executions.days')}`,
      value: [startOfDay(subDays(new Date(), 15)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.executions.lastDays')} 30 ${t('pages.executions.days')}`,
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.executions.lastDays')} 60 ${t('pages.executions.days')}`,
      value: [startOfDay(subDays(new Date(), 60)), endOfDay(new Date())]
    }
  ];

  const columns: GridColDef[] = [
    {
      field: 'datasetName',
      headerName: `${t('pages.executions.gridDetailsCol.dataset')}`,
      width: 150,
      editable: false,
      filterable: false
    },
    {
      field: 'algoShortDescription',
      headerName: `${t('pages.executions.gridDetailsCol.algorithm')}`,
      width: 150,
      editable: false,
      filterable: false
    },
    {
      field: 'executionDate',
      headerName: `${t('pages.executions.gridDetailsCol.executionDate')}`,
      width: 120,
      editable: false,
      filterable: false,
      valueFormatter: params =>
        moment(params?.value).format("DD/MM/YYYY")
    },
    {
      field: 'catalogue',
      headerName: `${t('pages.executions.gridDetailsCol.catalogue')}`,
      width: 110,
      editable: false,
      filterable: false
    },
    {
      field: 'item',
      headerName: `${t('pages.executions.gridDetailsCol.item')}`,
      width: 470,
      editable: false,
      filterOperators: getGridStringOperators().filter(o => o.value === "equals")
    },
    {
      field: 'itemDate',
      headerName: `${t('pages.executions.gridDetailsCol.itemDate')}`,
      width: 95,
      editable: false,
      filterable: false,
      valueFormatter: params =>
        moment(params?.value).format("DD/MM/YYYY")
    },
    {
      field: 'numberOfEvents',
      headerName: `${t('pages.executions.gridDetailsCol.numberOfEvents')}`,
      width: 110,
      editable: false,
      filterable: false,
      align: "center"
    }
  ];

  const activeStep = 0;

  const { rows, queryFilter, filters, datasetFilter, algorithmFilter, startDate, endDate,
    setFilters, queryExecutionDetails, getDatasetFilters, onlyNotAnalized, setAnalized } = useExecutionStore();
  const { addMsg } = useNotifyStore();
  const navigate = useNavigate();
  const [selectedModel, setSelectedModel] = useState<ExecutionDetails>();
  const [anchorActions, setAnchorActions] = useState<null | HTMLElement>(null);
  const openMoreActionsMenu = Boolean(anchorActions);

  useEffect(() => {
    getDatasetFilters()
      .then(res => {
        if (!datasetFilter) {
          const ds = res?.find(x => x.id === "ALL");
          const algo = ds?.algorithms.find(x => x.name === "ALL");
          setFilters(ds, algo, startDate, endDate, onlyNotAnalized);
        }
      });
  }, []);

  useEffect(() => {
    queryExecutionDetails(queryFilter);
  }, [datasetFilter, algorithmFilter, startDate, endDate, onlyNotAnalized]);

  const handleChangeDataset = (event: SelectChangeEvent) => {
    const ds = filters.find(x => x.id === event.target.value);
    const algo = ds?.algorithms.find(x => x.name === "ALL");
    setFilters(ds, algo, startDate, endDate, onlyNotAnalized);
  }

  const handleChangeAlgorithm = (event: SelectChangeEvent) => {
    const algo = datasetFilter?.algorithms.find(x => x.name === event.target.value);
    setFilters(datasetFilter, algo, startDate, endDate, onlyNotAnalized);
  }

  const handleChangeDateRange = (dates: [Date, Date]) => {
    setFilters(datasetFilter, algorithmFilter, dates[0], dates[1], onlyNotAnalized);
  }

  const handleChangeOnlyNotAnalized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(datasetFilter, algorithmFilter, startDate, endDate, event.target.checked);
  }

  const onChangeFilters = (filter: QueryFilter) => {
    queryExecutionDetails(filter);
  }

  const handleMoreActions = (data: any, element: HTMLElement) => {
    setSelectedModel(data);
    setAnchorActions(element);
  }

  const handleCloseMoreActions = () => {
    setAnchorActions(null);
  }

  const handleSetAnalized = (executionID: string, status: boolean) => {
    setAnchorActions(null);

    setAnalized(executionID, status)
      .then(_ => {
        addMsg("success", "Informação atualizada com sucesso.");
        queryExecutionDetails(queryFilter);
      })
      .catch(_ => {
        addMsg("error", "Não foi possível atualizar informação.");
      });
  }

  return (
    <Root>
      <Box sx={styles.stepper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key={"step_1"}>
            <StepLabel>{t('pages.executions.steps.step1')}</StepLabel>
          </Step>
          <Step key={"step_2"}>
            <StepLabel>{t('pages.executions.steps.step2')}</StepLabel>
          </Step>
        </Stepper>
      </Box>
      <Slide direction="right" in={activeStep === 0} mountOnEnter unmountOnExit>
        <Box>
          {rows &&
            <>
              <Typography gutterBottom variant="h5" color="text.secondary">{t('pages.executions.title')}</Typography>
              <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="filter-dataset-label">{t('pages.executions.dataset')}</InputLabel>
                <Select
                  labelId="filter-dataset-label"
                  id="filter-dataset"
                  label={t('pages.executions.dataset')}
                  value={datasetFilter?.id ?? ""}
                  onChange={handleChangeDataset}
                >
                  {filters.map((item: DatasetFilter) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="filter-algorithm-label">{t('pages.executions.algorithm')}</InputLabel>
                <Select
                  labelId="filter-algorithm-label"
                  id="filter-algorithm"
                  label={t('pages.executions.algorithm')}
                  value={algorithmFilter?.name ?? ""}
                  onChange={handleChangeAlgorithm}
                >
                  {datasetFilter?.algorithms?.map((item: AlgorithmFilter) => (
                    <MenuItem key={item.name} value={item.name}>{item.shortDescription}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <DateRange
                  value={[startDate, endDate]}
                  label={t('pages.executions.dateRange')}
                  initialValue={[startOfDay(subDays(new Date(), 5)), endOfDay(new Date())]}
                  shortcuts={ranges}
                  onChange={handleChangeDateRange} />
              </FormControl>
              <FormControlLabel
                control={<Switch
                  checked={onlyNotAnalized}
                  onChange={handleChangeOnlyNotAnalized} />}
                label={t('pages.executions.onlyNotAnalized')}
                sx={{ marginTop: "10px" }} />

              <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
                <DataGrid
                  height={"calc(100vh - 270px)"}
                  dataRows={rows}
                  dataColumns={columns}
                  queryFilter={queryFilter}
                  rowID="executionID"
                  onChangeFilters={onChangeFilters}
                  onMoreActionsCliked={handleMoreActions} />
              </Box>
            </>
          }
        </Box>
      </Slide>
      <Menu
        id="more-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'more-actions-button',
        }}
        anchorEl={anchorActions}
        open={openMoreActionsMenu}
        onClose={handleCloseMoreActions}
      >
        <MenuItem onClick={() => navigate(`/execution/${selectedModel?.executionID}`)}>
          {t('pages.executions.gotoAlerts')}
        </MenuItem>
        {!selectedModel?.analized &&
          <MenuItem onClick={() => handleSetAnalized(selectedModel?.executionID as string, true)}>
            {t('pages.executions.maskAsAnalized')}
          </MenuItem>
        }
        {selectedModel?.analized &&
          <MenuItem onClick={() => handleSetAnalized(selectedModel?.executionID as string, false)}>
            {t('pages.executions.maskAsNotAnalized')}
          </MenuItem>
        }
      </Menu>
    </Root>
  )
}

export default ExecutionsPage;