import { useEffect, useRef } from "react";

interface LegendIconProps {
  canvas: HTMLCanvasElement,
  width?: any,
  height?: any
}

const LegendIcon: React.FC<LegendIconProps> = ({ canvas, width, height }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!container || !container.current || !canvas) return;

    container.current.innerHTML = "";
    container.current.append(canvas);
  }, [container, canvas]);

  return (
    <div style={{ width: width ?? "32px", height: height ?? "24px" }} ref={container} />
  )
}

export default LegendIcon;