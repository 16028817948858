import { Typography, Stepper, Step, StepButton } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { ExecutionEventMoment } from "../../models/ExecutionEventMoment";
import { useAlertsStore } from "./AlertsStore";
import { useTranslation } from "react-i18next";

const AlertMoments: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const { targetEvent, moments, getMoments, getExecutionEvent } = useAlertsStore();

  useEffect(() => {
    if (!targetEvent) return;

    getMoments(targetEvent?.eventID);
  }, [targetEvent]);

  function handleSetHistoryEvent(eventID: string) {
    getExecutionEvent(eventID);
  }

  return (
    <>
      <Typography gutterBottom variant="h6" color="text.secondary" sx={{ paddingTop: "10px" }}>
        {t('components.alerts.moments.title')}
      </Typography>
      {moments && moments.length > 0 ? (
        <Stepper nonLinear activeStep={activeStep}>
          {moments.map((item: ExecutionEventMoment, idx: number) => (
            <Step key={item.eventID}>
              <StepButton color="inherit" onClick={() => { handleSetHistoryEvent(item.eventID); setActiveStep(idx); }}>
                {moment(item.stacItemDate).format('DD/MM/YY')}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography gutterBottom variant="h6" color="text.secondary">
          {t('components.alerts.moments.noData')}
        </Typography>
      )}
    </>
  )
}

export default AlertMoments;