
import { useUserAuthStore } from "@opt/core";
import WKB from 'ol/format/WKB';
import msgpack from 'msgpack-lite';
import { Feature } from "ol";

export const OPTFeatureLoadFunction = (url: string) => {

  const accessToken = useUserAuthStore.getState().accessToken;
  const tenantID = useUserAuthStore.getState().currentTenant.id;

  return async (extent: any,
    resolution: any,
    projection: any,
    success: any,
    failure: any) => {

    const fullUrl = url.indexOf("?") > -1 ? `${url}&bbox=${extent.join(',')}` : `${url}?bbox=${extent.join(',')}`;

    fetch(fullUrl, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      })
    })
      .then(async (response) => {
        if (response.ok) {
          const blob = await response.blob();
          const bytes = new Uint8Array(await blob.arrayBuffer());
          const value = msgpack.decode(bytes);

          const properties = value["properties"] as string[];
          const data = value["values"];
          const idField = value["idField"];
          const geomField = value["geomField"];
          const layerName = value["layerName"];

          const geomIndex = properties.indexOf(geomField);
          const idIndex = properties.indexOf(idField);

          const parser = new WKB();
          const features = [];

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const geometry = parser.readGeometry(element[geomIndex]);

            var props: { [id: string]: any; } = {};

            for (let j = 0; j < properties.length; j++) {
              if (j === geomIndex) continue;
              props[properties[j]] = element[j];
            }

            props["layer"] = layerName;

            const feature = new Feature(geometry);
            feature.setProperties(props);
            feature.setId(element[idIndex]);
            features.push(feature);
          }

          success(features);
        }
        else {
          failure();
        }
      });
  };
};
