import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Card, CardContent, Typography, ListItemButton, ListItemText, ListItemIcon, Menu, MenuItem, styled } from "@mui/material";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Map } from "ol";
import { SubMenuItem } from "@opt/ui-core";
import { LegendBuilder, LegendIcon, LegendLayerItem } from "@opt/mapping";
import { unByKey } from "ol/Observable";

const ControlDiv = styled('div')({
  marginTop: "3px"
});

const LegendIconDiv = styled('div')({
  height: 24,
  paddingRight: 4
});

type TOCSubMenuItem = {
  icon?: any,
  key: string | undefined,
  label: string | undefined,
  handler: (layerItem: LegendLayerItem, selectedItem: any) => void | undefined
}

type TOCMenuItem = {
  key: string,
  label: string,
  handler: (layerItem: LegendLayerItem, selectedItem: any) => void,
  getSubItems?: (layerItem: LegendLayerItem) => TOCSubMenuItem[] | undefined
}

type MapTOCProps = {
  map: Map | undefined,
  height: number,
  width: string | number,
  menuActions: TOCMenuItem[]
}

const MapTOC: React.FC<MapTOCProps> = ({ map, width, height, menuActions }) => {

  const [layerItems, setLayerItems] = useState<LegendLayerItem[]>([]);
  const listRef = useRef<VariableSizeList<any>>(null);

  useEffect(() => {
    if (!map) return;

    getAllLayers();

    const handler = map.getLayers().on("propertychange", function (e) {
      getAllLayers();
    });

    return () => {
      if (handler) unByKey(handler);
    }
  }, [map]);

  useEffect(() => {
    for (let index = 0; index < layerItems.length; index++) {
      listRef.current?.resetAfterIndex(index, true);
    }
  }, [layerItems]);

  const getAllLayers = () => {
    const layers = LegendBuilder.fromMap(map as Map);
    const ordered = layers?.sort((a, b) => b.order - a.order) as LegendLayerItem[];
    setLayerItems(ordered);
  }

  function useDoUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  }

  const doUpdate = useDoUpdate();

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const [anchorActions, setAnchorActions] = useState<null | HTMLElement>(null);
    const openMoreActionsMenu = Boolean(anchorActions);
    const [selectedModel, setSelectedModel] = useState<LegendLayerItem>();

    const handleMoreActions = (data: LegendLayerItem, element: HTMLElement) => {
      setSelectedModel(data)
      setAnchorActions(element);
    }

    const handleCloseMoreActions = () => {
      setAnchorActions(null);
    }

    const createSingleEntry = () => {
      return <>
        <ListItemButton style={style} key={index} sx={{ pl: 0, fontSize: "12px", paddingRight: 0 }} >
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <LegendIconDiv className={classNames("MuiListItemIcon-root")} >
                <LegendIcon canvas={layerItems[index].items[0].icon} />
              </LegendIconDiv>
              <ListItemText
                primary={<Typography noWrap variant="body2" fontSize={"11px"}>{layerItems[index].label}</Typography>}
              />
              <ListItemIcon sx={{ minWidth: "36px" }}
                onClick={() => {
                  layerItems[index].layer?.setVisible(!layerItems[index].layer?.getVisible());
                  doUpdate();
                }}>
                {layerItems[index].layer?.getVisible() ?
                  <VisibilityIcon fontSize="small" sx={{ width: "36px" }} /> :
                  <VisibilityOffIcon fontSize="small" sx={{ width: "36px" }} />}
              </ListItemIcon>
              <ListItemIcon sx={{ minWidth: "36px" }} onClick={(e) => {
                handleMoreActions(layerItems[index], e.currentTarget)
              }}>
                <MoreVertIcon id={`more-actions-${index}`} fontSize="small" sx={{ width: "36px" }} />
              </ListItemIcon>
            </div>
          </div>
        </ListItemButton >
      </>
    }

    const createMultiEntry = () => {
      return <>
        <ListItemButton style={style} key={index} sx={{ pl: 0, fontSize: "12px", paddingRight: 0 }} >
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <ListItemText
                primary={<Typography noWrap variant="body2" fontSize={"11px"}>{layerItems[index].label}</Typography>}
              />
              <ListItemIcon sx={{ minWidth: "36px" }}
                onClick={() => {
                  layerItems[index].layer?.setVisible(!layerItems[index].layer?.getVisible());
                  doUpdate();
                }}>
                {layerItems[index].layer?.getVisible() ?
                  <VisibilityIcon fontSize="small" sx={{ width: "36px" }} /> :
                  <VisibilityOffIcon fontSize="small" sx={{ width: "36px" }} />}
              </ListItemIcon>
              <ListItemIcon sx={{ minWidth: "36px" }}
                onClick={(e) => {
                  handleMoreActions(layerItems[index], e.currentTarget)
                }}>
                <MoreVertIcon id={`more-actions-${index}`} fontSize="small" sx={{ width: "36px" }} />
              </ListItemIcon>
            </div>

            {layerItems[index].items.map(x => {
              return <div key={x.label} style={{ display: "inline-flex", width: "100%", marginLeft: "20px" }}>
                <LegendIconDiv className={classNames("MuiListItemIcon-root")} >
                  <LegendIcon canvas={x.icon} />
                </LegendIconDiv>
                <ListItemText
                  primary={<Typography noWrap variant="body2" fontSize={"11px"}>{x.label}</Typography>} />
              </div>
            })
            }
          </div>
        </ListItemButton >
      </>
    }

    const createItems = () => {
      if (layerItems[index].type === "SINGLE" || layerItems[index].items.length === 1) {
        return createSingleEntry();
      }
      else {
        return createMultiEntry();
      }
    }

    return (
      <>
        {createItems()}
        {selectedModel &&
          <Menu
            id="more-actions-menu"
            MenuListProps={{
              'aria-labelledby': 'more-actions-button',
            }}
            anchorEl={anchorActions}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={openMoreActionsMenu}
            onClose={handleCloseMoreActions}
          >
            {menuActions.map(item => {
              if (item.getSubItems) {
                const getSubItems = item.getSubItems;
                const subItems = getSubItems(selectedModel as LegendLayerItem);
                const subMenus = subItems?.map(x => {
                  return <MenuItem
                    key={x.key}
                    onClick={() => {
                      x.handler(selectedModel as LegendLayerItem, x);
                      handleCloseMoreActions();
                    }}>
                    {x.icon &&
                      <ListItemIcon>
                        {x.icon}
                      </ListItemIcon>
                    }
                    {x.label}
                  </MenuItem>
                })

                return <SubMenuItem key={item.key}
                  label={item.label} items={subMenus} />
              }
              else {
                return <MenuItem
                  key={item.key}
                  onClick={() => {
                    item.handler(selectedModel as LegendLayerItem, item);
                    handleCloseMoreActions();
                  }}>
                  {item.label}
                </MenuItem>
              }
            })}
          </Menu>
        }
      </>
    );
  }

  const getSize = (index: number) => {
    const item = layerItems[index];
    return ((item.items.length - 1) * 24) + 50;
  }

  return (
    <>
      <ControlDiv >
        <Card>
          <CardContent sx={{ padding: '5px' }}>
            <Typography gutterBottom variant="h4" component="div" color="primary" sx={{ marginBottom: "10px", marginTop: "10px", width: "100%" }}>
              Camadas
            </Typography>
            <VariableSizeList
              ref={listRef}
              height={height}
              width={width}
              itemSize={getSize}
              itemCount={layerItems.length}
              overscanCount={100}
            >
              {renderRow}
            </VariableSizeList>
          </CardContent>
        </Card>
      </ControlDiv>
    </>
  )
}

export default MapTOC;