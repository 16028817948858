import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Control } from "ol/control";
import classNames from "classnames";
import {
  Card, CardContent, Typography, ListItemButton, ListItemText,
  ListItemIcon, styled
} from "@mui/material";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMapContext } from "./AppMap";
import { LegendBuilder, LegendIcon, LegendLayerItem } from "@opt/mapping";
import OlMap from 'ol/Map';
import { unByKey } from "ol/Observable";

const ControlDiv = styled('div')({
  zIndex: 100,
  left: '7px',
  bottom: '40px'
});

const LegendIconDiv = styled('div')({
  height: 20,
  paddingRight: 4
});

interface MapLegendProps extends HTMLAttributes<HTMLDivElement> {

}

const MapLegend: React.FC<MapLegendProps> = ({ ...props }) => {

  const mapContext = useMapContext();
  const [layerItems, setLayerItems] = useState<LegendLayerItem[]>([]);
  const legendRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList<any>>(null);
  const [state, setState] = useState(0);

  const getAllLayers = () => {
    const layers = LegendBuilder.fromMap(mapContext?.map as OlMap);
    const ordered = layers?.sort((a, b) => b.order - a.order) as LegendLayerItem[];
    setLayerItems(ordered);

    mapContext?.map.getAllLayers().forEach(x => {
      x.un("change:visible", handleVisibilityChange);
      x.on("change:visible", handleVisibilityChange);
    })
  }

  const handleVisibilityChange = () => {
    setState(s => s + 1);
  }

  useEffect(() => {

    if (!mapContext?.map) return;

    const control = new Control({ element: legendRef.current as HTMLElement });
    mapContext?.map.addControl(control);

    getAllLayers();

    const handler = mapContext?.map.getLayers().on("propertychange", function (e) {
      getAllLayers();
    });

    return () => {
      mapContext?.map.removeControl(control);
      if (handler) unByKey(handler);
    }
  }, [mapContext]);

  useEffect(() => {
    for (let index = 0; index < layerItems.length; index++) {
      listRef.current?.resetAfterIndex(index, true);
    }
  }, [layerItems]);

  function useDoUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
  }

  const doUpdate = useDoUpdate();

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;

    const createSingleEntry = () => {
      return <>
        <ListItemButton style={style} key={index} sx={{ pl: 0, fontSize: "12px", paddingRight: 0 }} >
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <LegendIconDiv className={classNames("MuiListItemIcon-root")} >
                <LegendIcon canvas={layerItems[index].items[0].icon} />
              </LegendIconDiv>
              <ListItemText
                primary={<Typography noWrap variant="body2" fontSize={"11px"}>{layerItems[index].label}</Typography>}
              />
              <ListItemIcon sx={{ minWidth: "36px" }}
                onClick={() => {
                  layerItems[index].layer?.setVisible(!layerItems[index].layer?.getVisible());
                  doUpdate();
                }}>
                {layerItems[index].layer?.getVisible() ?
                  <VisibilityIcon fontSize="small" sx={{ width: "36px" }} /> :
                  <VisibilityOffIcon fontSize="small" sx={{ width: "36px" }} />}
              </ListItemIcon>
            </div>
          </div>
        </ListItemButton >
      </>
    }

    const createMultiEntry = () => {
      return <>
        <ListItemButton style={style} key={index} sx={{ pl: 0, fontSize: "12px", paddingRight: 0 }} >
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <ListItemText
                primary={<Typography noWrap variant="body2" fontSize={"11px"}>{layerItems[index].label}</Typography>}
              />
              <ListItemIcon sx={{ minWidth: "36px" }}
                onClick={() => {
                  layerItems[index].layer?.setVisible(!layerItems[index].layer?.getVisible());
                  doUpdate();
                }}>
                {layerItems[index].layer?.getVisible() ?
                  <VisibilityIcon fontSize="small" sx={{ width: "36px" }} /> :
                  <VisibilityOffIcon fontSize="small" sx={{ width: "36px" }} />}
              </ListItemIcon>
            </div>

            {layerItems[index].items.map(x => {
              return <div key={x.label} style={{ display: "inline-flex", width: "100%", paddingLeft: "15px" }}>
                <LegendIconDiv className={classNames("MuiListItemIcon-root")} >
                  <LegendIcon canvas={x.icon} />
                </LegendIconDiv>
                <ListItemText
                  primary={<Typography noWrap variant="body2" fontSize={"11px"}>{x.label}</Typography>} />
              </div>
            })
            }
          </div>
        </ListItemButton >
      </>
    }

    const createItems = () => {
      if (layerItems[index].type === "SINGLE" || layerItems[index].items.length === 1) {
        return createSingleEntry();
      }
      else {
        return createMultiEntry();
      }
    }

    return (
      <>
        {createItems()}
      </>
    );
  }

  const getSize = (index: number) => {
    if (layerItems[index].type === "SINGLE") {
      return 28;
    }

    const item = layerItems[index];
    return ((item.items.length) * 24) + 28;
  }

  return (
    <ControlDiv ref={legendRef} className={classNames("ol-control")} {...props}>
      <Card sx={{ maxWidth: 210, maxHeight: 275 }}>
        <CardContent sx={{ padding: '5px' }}>
          <Typography gutterBottom variant="h6" component="div" color="primary" sx={{ marginBottom: 0 }}>
            Legenda
          </Typography>
          <VariableSizeList
            ref={listRef}
            height={230}
            width={200}
            itemSize={getSize}
            itemCount={layerItems.length}
            overscanCount={100}
          >
            {renderRow}
          </VariableSizeList>
        </CardContent>
      </Card>
    </ControlDiv>
  )
}

export default MapLegend;