export class DateSummary {

    public date: Date | undefined;
    public executions: number;
    public events: number;
    public count: number;

    get shortDate(): string {
        return this.date === undefined ? '' : `${this.date.getDate()}/${this.date.getMonth() + 1}`;
    }

    constructor() {
        this.date = undefined;
        this.executions = 0;
        this.events = 0;
        this.count = 0;
    }
}