import {
  Box, Button, Card, CardContent, CardHeader, FormControl,
  IconButton, MenuItem, Select, TextField, Typography
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useUserAuthStore } from "@opt/core";
import { useNotifyStore } from "@opt/ui-core";
import { Draw, Modify } from "ol/interaction";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { DrawEvent } from "ol/interaction/Draw";
import { Control } from "ol/control";
import { ControlAddedToMapSharedArea } from "@opt/mapping";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorLayer from "ol/layer/Vector";
import { Geometry } from "ol/geom";
import { Select as MapSelect } from "ol/interaction";
import Map from "ol/Map";
import { useAlertsStore } from "../alerts/AlertsStore";
import { GeoJSON } from "ol/format";
import { featureCollection } from "@turf/turf";
import { useExplorerStore } from "./ExplorerStore";

type ManualInspectionAreaProps = {
  map?: Map,
  datasetID: string,
  onInspectionCreated?: () => void
}

const ManualInspectionArea: React.FC<ManualInspectionAreaProps> = ({ map, datasetID, onInspectionCreated }) => {

  const { t } = useTranslation();
  const { currentTenant } = useUserAuthStore();
  const { addMsg } = useNotifyStore();
  const [showControl, setShowControl] = useState(false);
  const [hasDraw, setHasDraw] = useState(false);
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  const contrlRef = useRef<HTMLDivElement>(null);
  const [mapDrawer, setMapDrawer] = useState<Draw>();
  const [mapModify, setMapModify] = useState<Modify>();
  const [mapSelect, setMapSelect] = useState<MapSelect>();
  const [selectedType, setSelectedType] = useState('');
  const [observations, setObservations] = useState('');
  const { getInspectionTypes, inspectionTypes } = useAlertsStore();
  const { createInspection } = useExplorerStore();

  const handleControlAdded = (control: any) => {
    if (control.controlName !== "InspectionPoint") {
      setShowControl(false);
    }
  }

  useEffect(() => {
    getInspectionTypes();
  }, [])

  useEffect(() => {
    if (!map || !currentTenant?.features?.inspectionPoint) return;

    const control = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(control);

    map.addEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);

    const source = new VectorSource({ wrapX: false });
    setVectorSource(source);

    const style = new Style({
      stroke: new Stroke({
        color: "rgba(255,255,0,1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,255,0,0)",
      }),
    });

    const layer = new VectorLayer({
      source: source,
      map: map,
      style: style
    });

    const draw = new Draw({
      type: 'Polygon'
    });

    const select = new MapSelect({
      layers: [layer]
    });

    const modify = new Modify({
      features: select.getFeatures(),
    });

    setMapDrawer(draw);
    setMapSelect(select);
    setMapModify(modify);

    draw.on('drawend', (event: DrawEvent) => {
      const drawed = event.feature;

      if (!drawed) return;

      source.clear();
      source.addFeature(drawed);
      setHasDraw(true);

      map?.removeInteraction(draw);
    });

    return () => {
      map.removeControl(control);
      map.removeEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);
    }
  }, [map, currentTenant]);

  useEffect(() => {
    if (showControl) {
      map?.addInteraction(mapDrawer as Draw);
      map?.addInteraction(mapSelect as MapSelect);
      map?.addInteraction(mapModify as Modify);
    } else {
      map?.removeInteraction(mapDrawer as Draw);
      map?.removeInteraction(mapSelect as MapSelect);
      map?.removeInteraction(mapModify as Modify);

      setHasDraw(false);
      setSelectedType("");
      setObservations("");

      vectorSource?.clear();
    }
  }, [showControl])

  const handleClear = () => {
    setSelectedType("");
    setObservations("");
    setHasDraw(false);
    vectorSource?.clear();
    map?.addInteraction(mapDrawer as Draw);
  }

  const handleSaveInspection = () => {
    const format = new GeoJSON();
    const selecteds = vectorSource?.getFeatures().map(feature => format.writeFeatureObject(feature));

    if (!selecteds?.length) return;

    const features = featureCollection(selecteds);

    createInspection(datasetID, selectedType, observations, features)
      .then(x => {
        addMsg("success", t('components.explorer.manualInspectionAreas.createSucess'));
        if (onInspectionCreated) onInspectionCreated();
        setShowControl(false);
      }).catch(x => {
        addMsg("error", t('components.explorer.manualInspectionAreas.createError'));
      });
  }

  return currentTenant?.features?.inspectionPoint ? (
    <>
      <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
        <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {t('components.explorer.manualInspectionAreas.title')}
          </Typography>
          <Button variant="outlined" size="small" onClick={() => setShowControl(true)}>
            {t('components.explorer.manualInspectionAreas.drawArea')}
          </Button>
        </CardContent>
      </Card>
      <div ref={contrlRef} className="ol-control" style={{ top: '7px', right: 'calc(50% - 200px)', zIndex: 100 }}>
        {showControl &&
          <Card sx={{ maxWidth: 400, minWidth: 400 }}>
            <CardHeader
              sx={{ paddingBottom: 0 }}
              action={
                <IconButton onClick={() => setShowControl(false)}>
                  <CloseIcon />
                </IconButton>
              }
              title={t('components.explorer.manualInspectionAreas.createInpectionPoint')}
              subheader={t('components.explorer.manualInspectionAreas.createOnMap')}
              titleTypographyProps={{ variant: 'h6', color: "primary" }}
            />
            <CardContent sx={{ display: "flex", flexFlow: "column", alignItems: "center", paddingTop: 0, paddingBottom: "5px!important" }}>
              {hasDraw &&
                <>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }} size="small">
                    <Select
                      id="inspection-type"
                      displayEmpty
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      {inspectionTypes.map(x => {
                        return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <TextField
                      id="observations-text"
                      label="Observações"
                      multiline
                      maxRows={3}
                      value={observations}
                      onChange={(e) => setObservations(e.target.value)}
                      variant="standard"
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: "200px", fontSize: "14px!important", border: "1px #697B84 solid!important" }}
                    onClick={() => handleSaveInspection()}
                    disabled={!selectedType}>
                    {t('components.explorer.manualInspectionAreas.createInspection')}
                  </Button>
                  <Box display="flex" flexDirection="row">
                    <Typography marginTop={"2px"} gutterBottom variant="body2" component="div" color="primary">
                      {t('components.explorer.manualInspectionAreas.clear')}
                    </Typography>
                    <IconButton aria-label="clear" size="small"
                      onClick={() => handleClear()}
                    >
                      <ClearIcon color="error" />
                    </IconButton>
                  </Box>
                </>
              }
            </CardContent>
          </Card>
        }
      </div>
    </>
  ) : (<></>)
}

export default ManualInspectionArea;