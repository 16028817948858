export enum FieldDataType {
  Text = "Text",
  Integer = "Integer",
  Float = "Float",
  Boolean = "Boolean",
  Date = "Date",
  DateTime = "DateTime"
}

export class FieldMetadata {

  public id: string | undefined;
  public name: string;
  public label: string;
  public editable: boolean;
  public visible: boolean;
  public isKey: boolean;
  public autoGenerate: boolean;
  public order?: number;
  public length?: number;
  public precision?: number;
  public dataType: FieldDataType;
  public tableID: string;

  constructor() {
    this.id = undefined;
    this.name = "";
    this.label = "";
    this.editable = false;
    this.visible = false;
    this.isKey = false;
    this.autoGenerate = false;
    this.order = undefined;
    this.length = undefined;
    this.precision = undefined;
    this.dataType = FieldDataType.Text;
    this.tableID = "";
  }
}