export class ExecutionEventMoment {

    public eventID: string;
    public relatedID: string;
    public status: string;
    public stacItemDate: Date | undefined;

    constructor() {
        this.eventID = "";
        this.relatedID = "";
        this.status = "";
        this.stacItemDate = undefined;
    }
}