import { Feature, Map } from "ol";
import BaseVectorLayer from "ol/layer/BaseVector";
import { LayerDefinition } from "../LayerDefinition";
import { GeometryType, ITableSLDStyle, TableMetadata } from "@opt/core";
import Style from "ol/style/Style";
import { LayerStyling } from "./LayerStyling";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import { StyleBuilder } from "./StyleBuilder";
import SLDReader from "@nieuwlandgeo/sldreader";

type IconInfo = {
  type: "POINT" | "POLYGON" | "LINE",
  fill: any,
  stroke: any,
  image?: any,
  lineDash?: any
}

type LegendItem = {
  label: string,
  type: "POINT" | "POLYGON" | "LINE",
  icon: HTMLCanvasElement
}

type LegendLayerItem = {
  id: any,
  label: string,
  type: "SINGLE" | "MULTI",
  layer?: BaseVectorLayer<any, any>,
  definition?: LayerDefinition,
  visible?: boolean,
  items: LegendItem[],
  styles: LayerStyling[],
  order: number
}

type LegendInfo = {
  table: TableMetadata,
  style?: LayerStyling,
  styles: LayerStyling[],
  visible: boolean,
  order: number
}

class LegendBuilder {

  constructor() {

  }

  public static fromList(items: LegendInfo[]) {
    const builder = new LegendBuilder();

    const layers = items.map(item => {
      if (item.style) {
        const legendItems = builder.getItemsFromStyleDefinition(item.style);
        const legendItem: LegendLayerItem = {
          id: item.table.id,
          label: item.style.label ?? item.table.name,
          type: item.style.styleType === "SIMPLE" ? "SINGLE" : "MULTI",
          items: legendItems,
          styles: item.styles,
          visible: item.visible,
          order: item.order
        }
        return legendItem;
      }
      else {
        const legendItems = builder.getItemFromTableMetadata(item.table);
        const legendItem: LegendLayerItem = {
          id: item.table.id,
          label: item.table.name,
          type: "SINGLE",
          items: legendItems,
          styles: [],
          visible: item.visible,
          order: item.order
        }
        return legendItem;
      }
    });

    return layers;
  }

  public static fromMap(map: Map) {
    const builder = new LegendBuilder();

    const layers = map?.getAllLayers()
      .filter(layer => layer instanceof BaseVectorLayer && (layer.get("LAYER_DEFINITION") as LayerDefinition)?.showInLegend)
      .map(layer => {
        const vlayer = layer as BaseVectorLayer<any, any>;
        const definition = layer.get("LAYER_DEFINITION") as LayerDefinition;

        if (!definition.styleDefinition) {
          const items = definition.legend.length ? definition.legend : builder.getItemFromLayerDefinition(definition);
          const item: LegendLayerItem = {
            id: definition.key,
            label: definition.name,
            type: definition.legend.length > 1 ? "MULTI" : "SINGLE",
            layer: vlayer,
            definition: definition,
            styles: [],
            items: items,
            order: definition.zIndex
          }
          return item;
        }
        else {
          const items = builder.getItemsFromStyleDefinition(definition.styleDefinition);
          const item: LegendLayerItem = {
            id: definition.key,
            label: definition.styleDefinition.label ?? definition.name,
            type: definition.styleDefinition.styleType === "SIMPLE" ? "SINGLE" : "MULTI",
            layer: vlayer,
            definition: definition,
            styles: [],
            items: items,
            order: definition.zIndex
          }
          return item;
        }
      });

    return layers;
  }

  public static fromSLD(sld: ITableSLDStyle) {
    const builder = new LegendBuilder();

    return builder.getItemsFromSLDStyleDefinition(sld);
  }

  private createIcon(item: IconInfo) {
    const canvas = document.createElement("canvas");
    canvas.width = 26;
    canvas.height = 20;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    if (item.type === "POLYGON") {
      const dash = item.lineDash ? [3, 5] : [];
      ctx.beginPath();
      ctx.rect(0, 0, 26, 20);
      ctx.fillStyle = item.fill;
      ctx.lineWidth = 4;
      ctx.strokeStyle = item.stroke ?? item.fill ?? "gray";
      ctx.fill();
      ctx.setLineDash(dash);
      ctx.stroke();
    }
    else if (item.type === "LINE") {
      const dash = item.lineDash ? [3, 5] : [];
      ctx.beginPath();
      ctx.moveTo(2, 2);
      ctx.lineTo(24, 18);
      ctx.strokeStyle = item.stroke;
      ctx.lineWidth = 4;
      ctx.setLineDash(dash);
      ctx.stroke();
    }
    else {
      ctx.beginPath();
      ctx.arc(12, 11, 7, 0, 2 * Math.PI);
      ctx.strokeStyle = item.image?.getStroke()?.getColor();
      ctx.fillStyle = item.image?.getFill()?.getColor();
      ctx.lineWidth = 2;
      ctx.fill();
      ctx.stroke();
    }

    return canvas;
  }

  private getItemsFromStyleDefinition(definition: LayerStyling) {
    if (definition.styleType === "SIMPLE") {
      return this.getItemFromSimpleStyleDefinition(definition);
    }
    else if (definition.styleType === "FUNCTION") {
      return this.getItemsFromFunctionStyleDefinition(definition);
    }
    else {
      return this.getItemsFromSLDStyleDefinition(definition);
    }
  }

  private getItemsFromSLDStyleDefinition(definition: ITableSLDStyle) {
    const sldObject = SLDReader.Reader(definition.styleFile);
    const sldLayer = SLDReader.getLayer(sldObject);
    const style = sldLayer.styles[0];
    const featureTypeStyle = style.featuretypestyles[0];

    const styles = [];
    for (const rule of featureTypeStyle.rules) {
      if (!rule.name || rule.textsymbolizer) continue;

      const name = featureTypeStyle.rules.length === 1 ? definition.label : rule.name;
      const geomType = this.getOLTypeFromGeometryType(definition.geometryType);
      const result = SLDReader.createOlStyle(rule, geomType)[0];
      let lineStyle;

      if (geomType === "Polygon" && rule.linesymbolizer) {
        lineStyle = SLDReader.createOlStyle(rule, "LineString")[0];
      }

      const iconInfo = {
        type: this.getTypeFromGeometryType(definition.geometryType),
        fill: result?.getFill()?.getColor(),
        stroke: result?.getStroke()?.getColor() ?? lineStyle?.getStroke()?.getColor(),
        image: result?.getImage(),
        lineDash: result?.getStroke()?.getLineDash() ?? lineStyle?.getStroke()?.getLineDash()
      }

      const item: LegendItem = {
        label: name,
        type: this.getTypeFromGeometryType(definition.geometryType),
        icon: this.createIcon(iconInfo)
      }

      styles.push(item);
    }

    return styles;
  }

  private getItemsFromFunctionStyleDefinition(definition: LayerStyling) {
    const type = this.getTypeFromGeometryType(definition.geometryType);
    const items = eval(definition.legendOverride as string).map((x: any) => {

      const iconInfo = {
        type: type,
        fill: x.fill,
        stroke: x.stroke
      }

      const item: LegendItem = {
        label: x.label,
        type: type,
        icon: this.createIcon(iconInfo)
      }
      return item;
    });
    return items;
  }

  private getItemFromSimpleStyleDefinition(definition: LayerStyling) {
    const type = this.getTypeFromGeometryType(definition.geometryType);

    const fill = new Fill({
      color: definition.fillColor
    });

    const stroke = new Stroke({
      color: definition.strokeColor,
      width: definition.strokeWidth ?? 2
    });

    const image = new Circle({
      fill: fill,
      stroke: stroke,
      radius: definition.imageRadius ?? 3,
    });

    const style = new Style({
      image: image,
      fill: fill,
      stroke: stroke
    });

    const iconInfo = {
      type: type,
      fill: style?.getFill()?.getColor(),
      stroke: style?.getStroke()?.getColor(),
      image: style?.getImage()
    }

    const item: LegendItem = {
      label: definition.label as string,
      type: type,
      icon: this.createIcon(iconInfo)
    }

    return [item];
  }

  private getItemFromLayerDefinition(definition: LayerDefinition) {
    const stl = definition.style;
    let style: Style;

    if (stl instanceof Style) {
      style = stl as Style;
    }
    else if (stl instanceof Array) {
      style = stl[0] as Style;
    }
    else {
      const styleFunc = stl as any;
      const feature = new Feature();
      style = styleFunc(feature, 0);
    }

    const iconInfo = {
      type: this.getTypeFromGeometryType(definition.geometryType),
      fill: style?.getFill()?.getColor(),
      stroke: style?.getStroke()?.getColor(),
      image: style?.getImage()
    }

    const item: LegendItem = {
      label: definition.name,
      type: this.getTypeFromGeometryType(definition.geometryType),
      icon: this.createIcon(iconInfo)
    }

    return [item];
  }

  private getItemFromTableMetadata(metadata: TableMetadata) {
    if (metadata.styleFile) {
      return this.getItemsFromSLDStyleDefinition(metadata);
    }
    else {
      const style = StyleBuilder.FromTableMetadata(metadata);

      const iconInfo = {
        type: this.getTypeFromGeometryType(metadata.geometryType),
        fill: style?.getFill()?.getColor(),
        stroke: style?.getStroke()?.getColor(),
        image: style?.getImage()
      }

      const item: LegendItem = {
        label: metadata.name,
        type: this.getTypeFromGeometryType(metadata.geometryType),
        icon: this.createIcon(iconInfo)
      }

      return [item];
    }
  }

  private getTypeFromGeometryType(geomType: GeometryType | undefined): "POINT" | "POLYGON" | "LINE" {
    if (geomType === GeometryType.Point || geomType === GeometryType.MultiPoint) {
      return "POINT";
    }
    else if (geomType === GeometryType.LineString || geomType === GeometryType.MultiLineString) {
      return "LINE";
    }

    return "POLYGON";
  }

  private getOLTypeFromGeometryType(geomType: GeometryType | undefined): "Point" | "Polygon" | "LineString" {
    if (geomType === GeometryType.Point || geomType === GeometryType.MultiPoint) {
      return "Point";
    }
    else if (geomType === GeometryType.LineString || geomType === GeometryType.MultiLineString) {
      return "LineString";
    }

    return "Polygon";
  }
}

export default LegendBuilder;

export type { LegendLayerItem, LegendItem, LegendInfo }