import React, { useEffect, useState } from "react";
import {
  DataGrid as MuiDataGrid, GridCallbackDetails, GridColDef, GridFilterModel,
  GridRenderCellParams, GridRowId, GridSelectionModel, GridSortModel
} from '@mui/x-data-grid';
import { QueryFilter } from "@opt/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableViewIcon from '@mui/icons-material/TableView';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Stack } from "@mui/material";

interface DataGridProps {
  height: any,
  dataColumns: GridColDef[],
  dataRows: any[],
  queryFilter: QueryFilter,
  isLoading?: boolean,
  rowID?: string,
  onChangeFilters?: (filter: QueryFilter) => void,
  onEditClicked?: (data: any) => void,
  onDeleteClicked?: (data: any) => void,
  onDetailsClicked?: (data: any) => void,
  onMoreActionsCliked?: (data: any, element: HTMLElement) => void,
  onSelectionChange?: (selection: GridRowId[]) => void
}

const DataGrid: React.FC<DataGridProps> = ({
  height,
  dataColumns,
  dataRows,
  queryFilter,
  isLoading = false,
  rowID = undefined,
  onChangeFilters,
  onEditClicked,
  onDeleteClicked,
  onDetailsClicked,
  onMoreActionsCliked,
  onSelectionChange
}) => {

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const actionsColumn = {
    field: "action",
    headerName: "",
    width: 180,
    sortable: false,
    editable: false,
    filterable: false,
    resizable: false,
    hideable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {

      const onEdit = (evt: any) => {
        evt.stopPropagation();

        if (onEditClicked) {
          onEditClicked(params.row);
        }
      }

      const onDelete = (evt: any) => {
        evt.stopPropagation();

        if (onDeleteClicked) {
          onDeleteClicked(params.row);
        }
      }

      const onView = (evt: any) => {
        evt.stopPropagation();

        if (onDetailsClicked) {
          onDetailsClicked(params.row);
        }
      }

      const handleClickMoreActions = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (onMoreActionsCliked) {
          onMoreActionsCliked(params.row, event.currentTarget);
        }
      };

      return <Stack direction="row" spacing={2}>
        {onEditClicked &&
          <IconButton aria-label="Editar" size="small" onClick={onEdit}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        }
        {onDeleteClicked &&
          <IconButton aria-label="Excluir" size="small" onClick={onDelete}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        }
        {onDetailsClicked &&
          <IconButton aria-label="Visualizar" size="small" onClick={onView}>
            <TableViewIcon fontSize="inherit" />
          </IconButton>
        }
        {onMoreActionsCliked &&
          <IconButton aria-label="Mais opções" id="more-actions" size="small" onClick={handleClickMoreActions}>
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
        }
      </Stack>
    }
  };

  const selectionEnabled = Boolean(onSelectionChange !== undefined);

  useEffect(() => {
    if (!dataColumns) return;

    const allColumns = [...dataColumns];

    if (onDeleteClicked || onEditClicked || onDetailsClicked || onMoreActionsCliked) {
      allColumns.push(actionsColumn);
    }

    setColumns(allColumns);

  }, [dataColumns])

  const handleFilterChange = (filter: GridFilterModel) => {
    if (!queryFilter || !filter.items.length) return;

    const newFilter = queryFilter.clone();
    newFilter.filterField = filter.items[0].columnField;
    newFilter.filterOperator = filter.items[0].operatorValue as string;
    newFilter.filterValue = filter.items[0].value;

    if (onChangeFilters) {
      onChangeFilters(newFilter);
    }
  }

  const handleSortModelChange = (sort: GridSortModel) => {
    if (!queryFilter || !sort.length) return;

    const filter = queryFilter.clone();
    filter.orderField = sort[0].field;
    filter.orderSort = sort[0].sort as string;

    if (onChangeFilters) {
      onChangeFilters(filter);
    }
  }

  const handlePageIndexChanged = (page: number) => {
    if (!queryFilter) return;

    const filter = queryFilter.clone();
    filter.pageIndex = page;

    if (onChangeFilters) {
      onChangeFilters(filter);
    }
  }

  const handlePageSize = (size: number) => {
    if (!queryFilter) return;

    const filter = queryFilter.clone();
    filter.pageSize = size;

    if (onChangeFilters) {
      onChangeFilters(filter);
    }
  }

  const handleSelectionChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    if (onSelectionChange) {
      onSelectionChange(selectionModel);
    }
  }

  return (
    <>
      <MuiDataGrid
        sx={{ height: height }}
        rows={dataRows}
        columns={columns}
        page={queryFilter?.pageIndex}
        pageSize={queryFilter?.pageSize}
        rowCount={queryFilter?.totalCount ?? 0}
        loading={isLoading}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        pagination
        checkboxSelection={selectionEnabled}
        onSelectionModelChange={handleSelectionChange}
        getRowId={(row) => row[rowID ?? 'id']}
        paginationMode="server"
        onPageChange={handlePageIndexChanged}
        onPageSizeChange={handlePageSize}
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </>
  )
}

export default DataGrid;