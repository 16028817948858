import { Components } from 'formiojs';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import FormioWebMapComp from './FormioWebMapComp';
import settingsForm from "./FormioWebMapSettings";
import { PropObservable } from './PropObservable';

const baseComponent = (Components as any).components.base;

export default class FormioWebMap extends (baseComponent as any) {

  private root: Root | undefined;

  private webmap: any;

  private mapValue: PropObservable<string> = new PropObservable<string>();

  constructor(component: any, options: any, data: any) {
    component.validate.required = true;
    super(component, options, data);

    this.webmap = React.createElement(FormioWebMapComp, {
      component: this.component,
      value: this.mapValue,
      onChange: (val: any) => this.handleOnChange(val),
      readOnly: options.readOnly
    });
  }

  handleOnChange(value: any) {
    this.dataValue = value;
    this._currentForm.triggerChange();
  }

  static schema() {
    return baseComponent.schema({
      type: 'formiowebmap',
      label: "WebMAP",
      key: "geom"
    });
  }

  public static editForm = settingsForm;

  static builderInfo = {
    title: "WebMAP",
    icon: "map",
    group: "advanced",
    weight: -1,
    noNewEdit: false,
    schema: FormioWebMap.schema()
  }

  getRoot(container: HTMLElement): Root | undefined {
    if (this.root) return this.root;

    if (!container) return undefined;

    const node = document.createElement('div') as HTMLElement;
    container.appendChild(node);
    this.root = createRoot(node);

    return this.root;
  }

  destroy() {
    this.webmap = undefined;
    this.root = undefined;
  }

  render(content: any) {
    return super.render(`<div></div>`);
  }

  attach(element: any) {
    const superAttach = super.attach(element);
    this.getRoot(element)?.render(this.webmap);

    return superAttach;
  }

  detach(element: any) {
    this.getRoot(element)?.unmount();
  }

  public getValue() {
    return this.dataValue;
  }

  public setValue(value: any) {
    this.mapValue.triggerNext(value);
    this.dataValue = value;
  }

  isEmpty(value: any): boolean {
    const condition = this.dataValue === undefined || this.dataValue === null || this.dataValue === "";

    return condition;
  }
}
