import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { useInspectionsStore } from "./InspectionStore";

const InspectionAOIDetails: React.FC = () => {

  const { current } = useInspectionsStore();

  return (
    <>
      <Tooltip title={current?.projectID}>
        <Typography
          noWrap
          color="primary"
          fontWeight={"bold"}
          variant="h6"
          sx={{ padding: "10px" }}>{current?.projectID}</Typography>
      </Tooltip>
    </>
  )
}

export default InspectionAOIDetails;