import React, { useState } from "react";
import { useUserAuthStore } from "./UserAuthStore";
import RedirectToKeycloak from "./behaviours/RedirectToKeycloak";
import ShowWelcome from "./behaviours/ShowWelcome";
import {
  KeycloakConfig, LoginContext, LoginContextProvider, LoginController,
  WelcomeConfig, storeIDToken, storeRefreshToken, storeToken
} from "./LoginContext";
import Keycloak from 'keycloak-js';

type KeycloakSecureAppProps = {
  AuthenticatedTemplate: JSX.Element,
  config: KeycloakConfig,
  behaviour?: "RedirectToKeycloak" | "ShowWelcome",
  welcomeConfig?: WelcomeConfig
}

const KeycloakSecureApp: React.FC<KeycloakSecureAppProps> = ({
  AuthenticatedTemplate,
  config,
  behaviour,
  welcomeConfig }) => {

  const keycloak = new Keycloak({
    url: config.url,
    realm: config.realm,
    clientId: config.clientID
  });

  const { authenticated } = useUserAuthStore();
  const [loginContext, setLoginContext] = useState<LoginContext>({ controller: new LoginController(keycloak) });

  keycloak.onAuthSuccess = () => {
    storeRefreshToken(keycloak.refreshToken as string);
    storeToken(keycloak.token as string);
    storeIDToken(keycloak.idToken as string);
  }

  const renderBehaviour = () => {
    if (behaviour === undefined || behaviour === "RedirectToKeycloak") {
      return <RedirectToKeycloak config={config} keycloak={keycloak} />
    }
    else if (behaviour === "ShowWelcome") {
      return <ShowWelcome config={config} pageConfig={welcomeConfig} keycloak={keycloak} />
    }
  }

  return (
    <>
      <LoginContextProvider.Provider value={loginContext}>
        {authenticated &&
          AuthenticatedTemplate
        }
        {!authenticated &&
          renderBehaviour()
        }
      </LoginContextProvider.Provider>
    </>
  )
}

export default KeycloakSecureApp;