
export class FormsMetadata {

  public id: string | undefined;
  public name: string;
  public description: string;
  public metadata: string;
  public isDefault: boolean;
  public tableID: string;

  constructor() {
    this.id = undefined;
    this.name = "";
    this.description = "";
    this.metadata = "";
    this.isDefault = false;
    this.tableID = "";
  }

  getComponents(hideMap?: boolean): [] {
    if (this.metadata === "") return [];

    const json = JSON.parse(this.metadata);

    if (hideMap && json?.length) {
      const geomIdx = json.findIndex((x: any) => x.key === "geom");

      if (geomIdx > -1) {
        json.splice(geomIdx, 1);

        json.push({
          "label": "geom",
          "key": "geom",
          "type": "hidden",
          "input": true,
          "tableView": false
        });
      }
    }

    return json;
  }

  clone(): FormsMetadata {
    const newOne = new FormsMetadata();
    newOne.id = this.id;
    newOne.name = this.name;
    newOne.description = this.description;
    newOne.metadata = this.metadata;
    newOne.isDefault = this.isDefault;
    newOne.tableID = this.tableID;

    return newOne;
  }
}