import { Geometry } from "ol/geom";

export class StacItem {

  public id: string | undefined;
  public collection: string | undefined;
  public date: Date | undefined;
  public geometry: Geometry | undefined;
  public tile: string | undefined;
  public cloudCover: number | undefined;

  constructor() {

  }
}