export default {
  translations: {
    pages: {
      executions: {
        steps: {
          step1: "Selecione uma execução para ver as detecções relacionadas",
          step2: "Selecione uma detecção para ver seus detalhes"
        },
        title: "Lista de Execuções",
        dataset: "Conjunto",
        algorithm: "Algoritmo",
        lastDays: "Últimos",
        today: "Hoje",
        days: "dias",
        dateRange: "Período",
        onlyNotAnalized: "Apenas pendentes de análise",
        gotoAlerts: "Ver Detecções",
        maskAsAnalized: "Marcar como analisado",
        maskAsNotAnalized: "Marcar como pendente de análise",
        gridCols: {
          dataset: "Conjunto",
          algorithm: "Algoritmo",
          projectID: "ID Projeto",
          parcelID: "ID Polígono",
          alertArea: "Área Detecção (ha)",
          aoiArea: "Área Total (ha)"
        },
        gridDetailsCol: {
          dataset: "Conjunto",
          algorithm: "Algoritmo",
          executionDate: "Data Execução",
          catalogue: "Catálogo",
          item: "ID Cena",
          itemDate: "Data Cena",
          numberOfEvents: "Nº Detecções"
        },
        backExecutionList: "Voltar para a lista de execuções",
        alertsTitle: "Lista de Detecções",
        gotoDetails: "Ver Detalhes no Mapa",
        goBack: "Voltar"
      },
      inspections: {
        title: 'Lista de Inspeções',
        dataset: "Conjunto",
        lastDays: "Últimos",
        today: "Hoje",
        days: "dias",
        dateRange: "Período",
        statusLabel: "Status",
        type: "Tipo de Inspeção",
        backTooltip: "Voltar para lista de inspeções",
        goBack: "Voltar",
        gridCols: {
          dataset: "Conjunto",
          projectID: "ID",
          parcelID: "Área de Interesse",
          numOfInspections: "Nº Apontamentos"
        },
        gotoDetails: "Ver Inspeções no Mapa",
        aoiNotFound: "Área de Interesse não encontrada!",
        status: {
          ALL: "Todos",
          PENDING: "Pendente de Análise",
          VERIFY: "Verificar em Campo",
          MONITOR: "Monitorar",
          CONFIRMED_REMOTE: "Confirmado Remotamente",
          CONFIRMED_FIELD: "Confirmado em Campo",
          NOT_PROBLEM: "Não é um problema"
        }
      },
      explorer: {
        title: 'Lista de Conjuntos',
        gridCols: {
          id: "ID",
          name: "Nome",
          description: "Descrição"
        },
        gotoDetails: "Ver no Mapa",
      }
    },
    components: {
      explorer: {
        inspectionTypes: {
          status: {
            PENDING: "Pendente de Análise",
            VERIFY: "Verificar em Campo",
            MONITOR: "Monitorar",
            CONFIRMED_REMOTE: "Confirmado Remotamente",
            CONFIRMED_FIELD: "Confirmado em Campo",
            NOT_PROBLEM: "Não é um problema"
          }
        },
        manualInspectionAreas: {
          title: "Criar Área de Inspeção",
          drawArea: "Desenhar Área",
          createInpectionPoint: "Criar Área de Inspeção",
          createOnMap: "Crie a área de inspeção desenhando no mapa",
          createInspection: "Criar Inspeções",
          clear: "Começar de novo",
          createSucess: "Área de Inspeção criado com sucesso.",
          createError: "Não foi possível criar Área de Inspeção."
        },
        stacSearcher: {
          title: "Catálogo de Cenas",
          subtitle: "Pesquisar Cenas",
          button: "Procurar",
          collection: "Sensor",
          dateRange: "Período",
          provider: "Catálogos",
          localProvider: "Meu Catálogo",
          localProviderTip: "Catálogo de imagens do monitora com as cenas utilizadas para o processamento e com filtro de percentual máximo de nuvem.",
          onlineProvider: "Catálogo Online",
          onlineProviderTip: "Catálogo de imagens online com todas as cenas disponíveis.",
          noResults: "Sem resultados.",
          providerUnavailable: "Catálogo indisponível no momento.",
          removeLayer: "remover camada",
          addLayer: "Adicionar no mapa",
          itemOptions: "OPÇÕES"
        },
        layers: {
          aoiLayer: "Área Monitorada",
          inspections: "Áreas de Inspeção"
        },
        title: "Explorar"
      },
      alerts: {
        baseLayout: {
          layers: {
            aoiLayer: "Área Monitorada",
            clippedLayer: "Área Monitorada Cena",
            alertLayer: "Detecções",
            maskedLayer: "Áreas ignoradas",
            inspectionsLayer: "Áreas de Inspeção"
          }
        },
        alertCard: {
          title: "Detecção",
          algorithm: "Algoritmo",
          area: "Área",
          dateExecution: "Data Execução",
          sceneDate: "Data Cena",
          sensor: "Sensor",
          scene: "Cena",
          baselineSceneDate: "Data Cena de Referência",
          baselineScene: "Cena de Referência"
        },
        aoiCard: {
          title: "Área de Interesse",
          dataset: "Conjunto",
          parcelID: "ID da Área de Interesse",
          projectID: "Projeto",
          area: "Área"
        },
        aoiIndexStats: {
          title: "Estatísticas",
          min: "Min",
          max: "Max",
          median: "Median",
          mean: "Mean",
          std: "Std",
          count: "Count"
        },
        moments: {
          title: "Histórico",
          noData: "Sem Dados Histórico"
        },
        rasterView: {
          title: "Camadas",
          layers: "Camadas",
          none: "NENHUMA",
          overlay: "Sobrepor",
          archived: "Imagens Arquivadas"
        },
        alertsNavigator: {
          title: "Lista das Áreas",
          area: "Área"
        },
        changeDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        burnedAreaDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        chlorophyllDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        daninhasDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        highAnthropizationDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        highVegetationDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        ndbiDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        ndviDetector: {
          detectionDetails: "Detalhes da Detecção"
        },
        tenantTables: {
          identifyResults: "Resultados"
        },
        inspectionPoints: {
          title: "Criar Área de Inspeção",
          selectDetection: "Selecionar Detecções",
          createInpectionPoint: "Criar Área de Inspeção",
          selectOnMap: "Selecione as detecções no mapa",
          createInspection: "Criar Inspeções",
          singleAlertSelected: "Detecção selecionada",
          createSucess: "Área de Inspeção criado com sucesso.",
          createError: "Não foi possível criar Área de Inspeção."
        }
      },
      remoteInspection: {
        title: "Inspeção Remota",
        selectArea: "Selecionar Área",
        cardTitle: "Inspeção Remota",
        cardSelectArea: "Selecione a área no mapa",
        getLastScene: "Mais recente",
        chooseScene: "Escolher Cena",
        availableScenes: "Lista de Cenas Disponíveis",
        sceneID: "ID",
        sceneDate: "Data",
        sceneCloudCover: "Cobertura de Nuvem",
        providerNotAvailable: "Não foi possível obter dados para inspeção remota.",
        report: {
          generateReport: "Gerar Relatório",
          success: "Relatório gerado com sucesso.",
          error: "Não foi possível gerar o relatório."
        }
      },
      inspections: {
        baseLayout: {
          layers: {
            aoiLayer: "Área Monitorada",
            inspectionsLayer: "Áreas de Inspeção"
          }
        },
        types: {
          status: {
            PENDING: "Pendente de Análise",
            VERIFY: "Verificar em Campo",
            MONITOR: "Monitorar",
            CONFIRMED_REMOTE: "Confirmado Remotamente",
            CONFIRMED_FIELD: "Confirmado em Campo",
            NOT_PROBLEM: "Não é um problema"
          }
        },
        details: {
          onSuccess: "Inspeção atualizada com sucesso.",
          onError: "Não foi possível atualizar Inspeção.",
          inspectionType: "Classificação",
          dataset: "Conjunto",
          currentStatus: "Status",
          inspectionNumber: "Nº Inspeção",
          algorithm: "Detecção",
          parcelID: "ID Polígono",
          projectID: "Projeto",
          sensor: "Sensor",
          sceneDate: "Data Cena",
          catalog: "Catálogo",
          creationDate: "Data de Criação",
          scene: "Cena",
          baselineSceneDate: "Data Cena de Referência",
          baselineScene: "Cena de Referência",
          observations: "Observações",
          required: "Preenchimento obrigatório para este Status",
          status: {
            title: "Status"
          },
          opinion: "Parecer",
          opinionPlaceholder: "Escreva seu parecer aqui...",
          save: "Salvar",
          manualType: "Inspeção Manual",
          manualDisclaimer: "Esta inspeção foi criada manualmente e alguns recursos não estarão disponíveis."
        },
        list: {
          title: "Lista de Inspeções",
          inspection: "Inspeção",
          inspections: "Inspeções",
          filters: {
            lastDays: "Últimos",
            days: "dias",
            inspectionNumber: "Nº de Inspeção",
            status: {
              title: "Status",
              all: "Todos",
            },
          }
        },
        rasterView: {
          title: "Camadas",
          layers: "Camadas",
          none: "NENHUMA",
          overlay: "Sobrepor",
          archived: "Imagens Arquivadas"
        },
        tenantTables: {
          identifyResults: "Resultados"
        }
      },
      mapReports: {
        title: "Relatório",
        addMap: "Adicionar Mapa Atual",
        sections: "Seções",
        generate: "Gerar Relatório",
        modal: {
          addSectionTitle: "Adicionar Seção",
          confirm: "Confirmar",
          cancel: "Cancelar",
          description: "Descrição (opcional)"
        },
        report: {
          success: "Relatório gerado com sucesso.",
          error: "Não foi possível gerar o relatório."
        }
      },
      cockpit: {
        title: "COCKPIT",
        filters: {
          dataset: "Conjunto",
          algorithm: "Tipo de Detecção",
          inspectionType: "Tipo de Inspeção",
          lastDays: "Últimos",
          days: "dias",
          all: "Todos"
        },
        cards: {
          alertsPerDataset: {
            title: "Detecções por Conjunto"
          },
          alertsPerDate: {
            title: "Detecções por Data"
          },
          alertsTotal: {
            title: "Total de Detecções",
            subtitle: "Total de Detecções no Período"
          },
          inspectionsPerDate: {
            title: "Inspeções por Data"
          },
          inspectionsPerStatus: {
            title: "Inspeções por Status"
          },
          inspectionsTotal: {
            title: "Total de Inspeções",
            subtitle: "Total de Inspeções Criadas no Período"
          },
          inspectionsPerType: {
            title: "Inspeções por Tipo"
          }
        }
      }
    }
  },
}