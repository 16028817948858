import { Geometry, Polygon } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { GeoJSON } from "ol/format";
import { RemoteInspectionItem } from "./RemoteInspectionItem";
import config from "../../config";
import { TileLoadFunction } from "@opt/mapping/src/ol/utils/TileLoadFunction";
import { PostAPI } from "@opt/core";
import { LayerDefinition, LayerMetadata, LayerType } from "@opt/mapping";

const { doPost } = PostAPI(Object, `${config.gisServices.url}/planet-api/data/v1/quick-search?_sort=acquired desc`);

export class PlanetProvider {

  constructor() {

  }

  public createInspecLayer(): TileLayer<XYZ> {
    const url = "";

    const definition = new LayerDefinition("planet-remote-inspection", LayerType.Tiled, "", "");
    const metadata = new LayerMetadata();
    metadata.provider = "Planet";
    definition.metadata = metadata;

    const layer = new TileLayer({
      visible: false,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        url: url,
        tileLoadFunction: TileLoadFunction()
      })
    });

    layer.set("LAYER_DEFINITION", definition);

    return layer;
  }

  public async getSceneList(geometry: Geometry): Promise<RemoteInspectionItem[]> {
    const geom = geometry?.transform("EPSG:3857", "EPSG:4326") as Polygon;
    const geomAsGeojson = new GeoJSON().writeGeometry(geom);
    const dateFilter = new Date();
    dateFilter.setDate(dateFilter.getDate() - 30);

    const jsonFilter = {
      "filter":
      {
        "type": "AndFilter",
        "config":
          [
            {
              "type": "GeometryFilter",
              "field_name": "geometry",
              "config": JSON.parse(geomAsGeojson)
            },
            {
              "type": "DateRangeFilter",
              "field_name": "acquired",
              "config":
              {
                "gte": dateFilter.toISOString()
              }
            },
            {
              "type": "RangeFilter",
              "field_name": "cloud_cover",
              "config":
              {
                "lte": 0.1
              }
            }
          ]
      },
      "item_types": ["PSScene"]
    };

    const query: any = await doPost(jsonFilter);

    const result = query.features.map((x: any) => {
      return {
        id: x.id,
        thumbUrl: `${config.gisServices.url}/planet/data/v1/item-types/PSScene/items/${x.id}/thumb`,
        date: x.properties.acquired,
        cloudPercent: `${x.properties.cloud_cover * 10}%`,
        layerUrl: `${config.gisServices.url}/planet/data/v1/PSScene/${x.id}/{z}/{x}/{y}.png`
      } as RemoteInspectionItem
    });

    return result;
  }
}