export class TenantUserAccess {
  
  public tenantID: string;
  public description: string;
  public hasAccess: boolean;
  
  constructor(){
    this.tenantID = "";
    this.description = "";
    this.hasAccess = false;
  }
}