import { Box, Typography, Button, Drawer, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { FormsMetadata } from "@opt/core";
import useMetadataStore from "./MetadataStore";
import DeleteDialog from "../DeleteDialog";
import { QueryFilter } from "@opt/core";
import DataGrid from "../DataGrid";
import FormsMetadataForm from "./FormsMetadataForm";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  },
  gridBox: {
    margin: "20px",
    flexGrow: 1
  }
}

interface FormsMetadataViewProps {
  modelID: string | undefined
}

const FormsMetadataView: React.FC<FormsMetadataViewProps> = ({ modelID }) => {

  const { currentTableMetadata, formsMetadata, lastQueryFilterFormsMetadata, fetchTableMetadata,
    queryFormsMetadata, createFormsMetadata, updateFormsMetadata, deleteFormsMetadata } = useMetadataStore();
  const [showForm, setShowForm] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedModel, setSelectedModel] = useState<FormsMetadata>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 150,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 350,
      editable: false,
    },
    {
      field: 'isDefault',
      headerName: 'Formulário Principal?',
      width: 150,
      editable: false,
    }
  ];

  useEffect(() => {
    if (!modelID) return;
    fetchTableMetadata(modelID);
  }, [modelID])

  useEffect(() => {
    queryFormsMetadata(lastQueryFilterFormsMetadata);
  }, [currentTableMetadata])

  useEffect(() => {

    if (!showForm) {
      setSelectedModel(undefined);
      setUpdateMode(false);
      setReadOnlyMode(false);
    }

  }, [showForm])

  const handleSubmitForm = (model: any) => {

    if (updateMode) {
      updateFormsMetadata(model).then(_ => {
        setShowForm(false);
        queryFormsMetadata(lastQueryFilterFormsMetadata);
      });
    } else {
      createFormsMetadata(model).then(_ => {
        setShowForm(false);
        queryFormsMetadata(lastQueryFilterFormsMetadata);
      });
    }
  }

  const handleOnEdit = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(false);
    setUpdateMode(true);
    setShowForm(true);
  }

  const handleOnDelete = (data: any) => {
    setSelectedModel(data);
    setShowDeleteDialog(true);
  }

  const handleOnDetails = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(true);
    setShowForm(true);
  }

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      deleteFormsMetadata(selectedModel?.id as string).then(_ => {
        queryFormsMetadata(lastQueryFilterFormsMetadata);
        setShowDeleteDialog(false);
      })
    }
  }

  const onChangeFilters = (filter: QueryFilter) => {
    queryFormsMetadata(filter);
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Forms Metadata - {currentTableMetadata?.name}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Cadastro dos formulários de atributos da tabela ou classe de feição
        </Typography>
      </Box>
      <Box sx={styles.searchBox}>
        <Button size="small" variant="contained" onClick={() => setShowForm(true)}>Novo</Button>
      </Box>
      <Box sx={styles.gridBox}>
        <DataGrid
          height="calc(100vh - 250px)"
          dataRows={formsMetadata}
          dataColumns={columns}
          queryFilter={lastQueryFilterFormsMetadata}
          onChangeFilters={onChangeFilters}
          onEditClicked={handleOnEdit}
          onDeleteClicked={handleOnDelete}
          onDetailsClicked={handleOnDetails}
        />
      </Box>
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "960px", maxWidth: "960px" }}>
          <FormsMetadataForm
            tableID={modelID as string}
            forUpdate={updateMode}
            forReadOnly={readOnlyMode}
            model={selectedModel as FormsMetadata}
            onSubmit={handleSubmitForm}
          />
        </Box>
      </Drawer>
      <DeleteDialog confirmation={handleDeleteConfirmation} showDialog={showDeleteDialog} />
    </>
  )
}

export default FormsMetadataView;
