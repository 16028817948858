import { TableMetadata } from "@opt/core";
import { Feature, Map } from "ol";
import { Geometry } from "ol/geom";
import VectorTileSource from 'ol/source/VectorTile';
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { unionGeometries } from "./utils/gisUtils";
import RenderFeature, { toFeature } from "ol/render/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { unByKey } from 'ol/Observable.js';
import { getVectorContext } from 'ol/render.js';
import { easeOut } from 'ol/easing.js';
import { EventsKey } from "ol/events";

export class MVTFeatureAnimation {

  private vectorSource: VectorSource;
  private selectionLayer: VectorLayer<VectorSource> | undefined;
  private handler: EventsKey | undefined;

  constructor(private map: Map,
    private metadata: TableMetadata,
    private source: VectorTileSource,
    private highlightColor: { r: number, g: number, b: number } = { r: 229, g: 20, b: 219 }) {

    this.vectorSource = new VectorSource();
    this.selectionLayer = new VectorLayer({
      source: this.vectorSource,
      map: this.map
    });

    this.handler = this.vectorSource.on("addfeature", (e) => this.flash(e.feature))
  }

  public animate(layerName: string, featureID: string) {
    const features = this.source.getFeaturesInExtent(this.map.getView().getViewStateAndExtent().extent);
    const key = this.metadata.getKeyAttribute();
    const targets = features
      .filter(x => x.get("layer") === layerName && x.get(key) == featureID)
      .map(x => x instanceof RenderFeature ? toFeature(x) : x);

    const merged = unionGeometries(targets);

    if (merged) this.vectorSource.addFeature(merged);
  }

  private flash(feature: Feature | undefined) {
    if (!feature || !this.selectionLayer) return;

    const duration = 3000;
    const start = Date.now();
    const flashGeom = feature.getGeometry()?.clone() as Geometry;
    const listenerKey = this.selectionLayer.on('postrender', animate);

    const self = this;

    function animate(event: any) {
      const frameState = event.frameState;
      const elapsed = frameState.time - start;
      if (elapsed >= duration) {
        unByKey(listenerKey);
        self.destroy();
        return;
      }
      const vectorContext = getVectorContext(event);
      const elapsedRatio = elapsed / duration;
      const opacity = easeOut(1 - elapsedRatio);

      const defaultFill = new Fill({
        color: `rgba(${self.highlightColor.r},${self.highlightColor.g},${self.highlightColor.b},${opacity / 2})`
      });

      const defaultStroke = new Stroke({
        color: `rgba(${self.highlightColor.r},${self.highlightColor.g},${self.highlightColor.b},${opacity})`,
        width: 0.25 + (3 * opacity)
      });

      const defaultStyle: Style = new Style({
        image: new Circle({
          fill: defaultFill,
          stroke: defaultStroke,
          radius: 5,
        }),
        fill: defaultFill,
        stroke: defaultStroke,
      });

      vectorContext.setStyle(defaultStyle);
      vectorContext.drawGeometry(flashGeom);
      self.map?.render();
    }
  }

  public destroy() {
    this.vectorSource.clear();    
    if (this.handler) unByKey(this.handler);
    this.vectorSource.dispose();
    this.selectionLayer?.setMap(null);
    this.selectionLayer?.changed();
  }
}