export class MapSection {

  public description: string | undefined;

  public map: string | undefined;

  public metadata: string[];

  constructor() {
    this.metadata = [];
  }

  public clone(): MapSection {
    const newOne = new MapSection();
    newOne.description = this.description;
    newOne.map = this.map;
    newOne.metadata = this.metadata;

    return newOne;
  }
}