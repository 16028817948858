import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertsStore } from "../components/alerts/AlertsStore";
import { ExecutionEvent } from "../models/ExecutionEvent";
import ChangeDetector from "../components/alerts/ChangeDetector";
import BurnedAreaDetector from "../components/alerts/BurnedAreaDetector";

const ChlorophyllInWaterDetector = React.lazy(() => import("../components/alerts/ChlorophyllInWaterDetector"));
const DaninhasDetector = React.lazy(() => import("../components/alerts/DaninhasDetector"));
const HighAnthropizationDetector = React.lazy(() => import("../components/alerts/HighAnthropizationDetector"));
const HighVegetationDetector = React.lazy(() => import("../components/alerts/HighVegetationDetector"));
const NDVIDetector = React.lazy(() => import("../components/alerts/NDVIDetector"));

const styles = {
  spinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 100
  }
}

const CreateComponentHandler = (alert: ExecutionEvent) => {
  if (alert.algorithmName === "HIGH_NDVI_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <NDVIDetector />
      </Suspense>
    );
  }
  else if (alert.algorithmName === "LOW_NDVI_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <NDVIDetector />
      </Suspense>
    );
  }
  else if (alert.algorithmName === "HIGH_VEGETATION_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <HighVegetationDetector />
      </Suspense>
    );
  }
  else if (alert.algorithmName === "DANINHAS_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <DaninhasDetector />
      </Suspense>
    );
  }
  else if (alert.algorithmName === "HIGH_ANTHROPIZATION_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <HighAnthropizationDetector />
      </Suspense>
    );
  } else if (alert.algorithmName === "CHLOROPHYLL_IN_WATER_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <ChlorophyllInWaterDetector />
      </Suspense>
    );
  } else if (alert.algorithmName === "CHANGE_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <ChangeDetector />
      </Suspense>
    );
  } else if (alert.algorithmName === "BURNED_AREA_DETECTOR") {
    return (
      <Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
        <BurnedAreaDetector />
      </Suspense>
    );
  }
  else {
    return <div> Algoritmo Inválido </div>;
  }
}

const AlertDetailsPage: React.FC = (): JSX.Element => {

  const { id } = useParams<string>();
  const { targetEvent, setTargetEvent, getExecutionEvent } = useAlertsStore();
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    if (!id) return;

    getExecutionEvent(id)
      .then(event => {
        setTargetEvent(event);
        setShowNotFound(event === undefined);
      });
  }, [id]);

  return (
    <>
      {targetEvent &&
        CreateComponentHandler(targetEvent)
      }
      {!targetEvent &&
        <CircularProgress size={68} sx={styles.spinner} />
      }
      <Snackbar
        open={showNotFound}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Alerta não encontrado!
        </Alert>
      </Snackbar>
    </>
  )
}

export default AlertDetailsPage;