import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip } from "recharts";
import { useCockpitStore } from "../CockpitStore";
import { FormatNumber } from "@opt/core";
import { useTranslation } from "react-i18next";

const AlertsPerDate: React.FC = () => {

  const { t } = useTranslation();
  const { dateSummary } = useCockpitStore();

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card>
          <CardContent>
            <Typography gutterBottom variant="body1" color="primary">{`${new Date(label).getDate().toString().padStart(2, "0")}/${(new Date(label).getMonth() + 1).toString().padStart(2, "0")}`}</Typography>
            <span>{FormatNumber(payload[0].value)}</span>
          </CardContent>
        </Card>
      );
    }

    return null;
  }

  return (
    <>
      <Typography gutterBottom variant="h6" color="primary">
        {t('components.cockpit.cards.alertsPerDate.title')}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" maxHeight={300} minHeight={300}>
        <BarChart
          width={400}
          height={400}
          data={dateSummary}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={(value, index) => `${new Date(value).getDate().toString().padStart(2, "0")}/${(new Date(value).getMonth() + 1).toString().padStart(2, "0")}`}>
          </XAxis>
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="events" fill="#8884d8" legendType="none"
            label={{ position: "insideTop", fill: "white" }} />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default AlertsPerDate;