import {
  FormControl, FormControlLabel,
  FormHelperText, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography
} from "@mui/material";
import { CloneModels } from "@opt/core";
import React, { useState } from "react";
import { FieldDataType, FieldMetadata } from "@opt/core";
import SideEditForm from "../SideEditForm";

interface FieldsMetadataFormProps {
  tableID: string,
  forUpdate: boolean,
  forReadOnly: boolean,
  model: FieldMetadata,
  onSubmit: (model: FieldMetadata) => void
}

const FieldsMetadataForm: React.FC<FieldsMetadataFormProps> = ({ tableID, forUpdate, forReadOnly, model, onSubmit }) => {

  const [formValue, setFormValue] = useState<FieldMetadata>(forUpdate || forReadOnly ? CloneModels.deepCopy<FieldMetadata>(model) : new FieldMetadata());

  const handleControlValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<FieldDataType>) => {
    const { name: propName, value } = event.target;
    setFormValue({ ...formValue, [propName]: value });
  }

  const handleControlCkeckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: propName, checked } = event.target;
    setFormValue({ ...formValue, [propName]: checked });
  };

  const handleControlNumbersOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: propName, value } = event.target;
    const isNumber = /^[0-9]*$/.test(value)

    if (isNumber) {
      setFormValue({ ...formValue, [propName]: value });
    }
  };

  return (
    <>
      <SideEditForm
        title="Fields Metadata"
        forUpdate={forUpdate}
        forReadOnly={forReadOnly}
        onSubmit={() => onSubmit(formValue)}>
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="name">Nome</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="name"
              name="name"
              aria-describedby="name-helper-text"
              value={formValue?.name}
              onChange={handleControlValueChange} />
            <FormHelperText id="name-helper-text">Nome do atributo</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="label">Label</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="label"
              name="label"
              aria-describedby="label-helper-text"
              value={formValue?.label}
              onChange={handleControlValueChange} />
            <FormHelperText id="label-helper-text">Label para exibição do atributo</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormControlLabel sx={{ maxWidth: 100 }}
              value="top"
              control={<Switch color="primary"
                name="editable"
                disabled={forReadOnly}
                checked={formValue.editable}
                onChange={handleControlCkeckedChange} />}
              label={<Typography color="rgba(0, 0, 0, 0.6)">Editável</Typography>}
              labelPlacement="start"
            />
            <FormHelperText id="editable-helper-text">Define se o atributo é editável ou não</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormControlLabel sx={{ maxWidth: 100 }}
              value="top"
              control={<Switch color="primary"
                name="visible"
                disabled={forReadOnly}
                checked={formValue.visible}
                onChange={handleControlCkeckedChange} />}
              label={<Typography color="rgba(0, 0, 0, 0.6)">Visível</Typography>}
              labelPlacement="start"
            />
            <FormHelperText id="editable-helper-text">Define se o atributo é visível ou não</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <FormControlLabel sx={{ maxWidth: 135 }}
              value="top"
              control={<Switch color="primary"
                name="isKey"
                disabled={forReadOnly}
                checked={formValue.isKey}
                onChange={handleControlCkeckedChange} />}
              label={<Typography color="rgba(0, 0, 0, 0.6)">Identificador</Typography>}
              labelPlacement="start"
            />
            <FormHelperText id="order-helper-text">Define se o atributo é identificador</FormHelperText>
          </FormControl>
          {formValue.isKey &&
            <FormControl fullWidth sx={{ mt: 2 }}>
              <FormControlLabel sx={{ maxWidth: 160 }}
                value="top"
                control={<Switch color="primary"
                  name="autoGenerate"
                  disabled={forReadOnly}
                  checked={formValue.autoGenerate}
                  onChange={handleControlCkeckedChange} />}
                label={<Typography color="rgba(0, 0, 0, 0.6)">Auto-gerado?</Typography>}
                labelPlacement="start"
              />
              <FormHelperText id="order-helper-text">Define se o atributo de ID será gerado automaticamente</FormHelperText>
            </FormControl>
          }
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="order">Ordem</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="order"
              name="order"
              aria-describedby="order-helper-text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={formValue?.order ?? ''}
              onChange={handleControlNumbersOnlyChange} />
            <FormHelperText id="order-helper-text">Ordem do atributo</FormHelperText>
          </FormControl>
          {(formValue.dataType == FieldDataType.Text || formValue.dataType == FieldDataType.Integer || formValue.dataType == FieldDataType.Float) &&
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="length">Tamanho</InputLabel>
              <Input
                readOnly={forReadOnly}
                id="length"
                name="length"
                aria-describedby="length-helper-text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={formValue?.length ?? ''}
                onChange={handleControlNumbersOnlyChange} />
              <FormHelperText id="length-helper-text">Tamanho do atributo em número de caracteres</FormHelperText>
            </FormControl>
          }
          {formValue.dataType == FieldDataType.Float &&
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="precision">Precisão</InputLabel>
              <Input
                readOnly={forReadOnly}
                id="precision"
                name="precision"
                aria-describedby="precision-helper-text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={formValue?.precision ?? ''}
                onChange={handleControlNumbersOnlyChange} />
              <FormHelperText id="precision-helper-text">Precisão do atributo em número de casas decimais</FormHelperText>
            </FormControl>
          }
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="data-type-select-label">Tipo de Dado</InputLabel>
            <Select
              readOnly={forReadOnly}
              labelId="data-type-select-label"
              id="data-type"
              name="dataType"
              label="Tipo de Geometria"
              value={formValue?.dataType}
              onChange={handleControlValueChange}
            >
              <MenuItem value={FieldDataType.Text}>Texto</MenuItem>
              <MenuItem value={FieldDataType.Integer}>Número Inteiro</MenuItem>
              <MenuItem value={FieldDataType.Float}>Número Decimal</MenuItem>
              <MenuItem value={FieldDataType.Date}>Data</MenuItem>
              <MenuItem value={FieldDataType.DateTime}>Data e Hora</MenuItem>
              <MenuItem value={FieldDataType.Boolean}>Booleano</MenuItem>
            </Select>
            <FormHelperText id="geometryType-helper-text">Tipo de dado do atributo</FormHelperText>
          </FormControl>
        </>
      </SideEditForm>
    </>
  )
}

export default FieldsMetadataForm;