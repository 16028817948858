import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import OlMap from "ol/Map";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { Control } from "ol/control";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardHeader, IconButton, Typography } from "@mui/material";
import ReactDOM from "react-dom";
import { ControlAddedToMapSharedArea } from "./events/ControlAddedToMapSharedArea";
import { LayerDefinition } from "./LayerDefinition";

interface IdentifyToolProps extends HTMLAttributes<HTMLDivElement> {
  map: OlMap | undefined,
  setIdentifyResults: (results: Feature<Geometry>[]) => void;
  targetLayers: string[]
}

const IdentifyTool: React.FC<IdentifyToolProps> = ({ map, targetLayers, setIdentifyResults, ...props }) => {

  const btnRef = useRef<HTMLDivElement>(document.createElement('div'));
  const contrlRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [showControl, setShowControl] = useState<boolean>(false);
  const [hasResults, setHasResults] = useState<boolean>(true);

  const handleControlAdded = (control: any) => {
    if (control.controlName !== "IdentifyTool") {
      setShowControl(false);
    }
  }

  useEffect(() => {
    if (!map) return;

    map.addEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);

    return () => {
      map.removeEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);
    }
  }, [map])

  useEffect(() => {
    if (showControl) {
      map?.dispatchEvent(new ControlAddedToMapSharedArea("IdentifyTool"));
    }
  }, [showControl])

  const IdentifyBtn = () => {
    return ReactDOM.createPortal(
      <div style={{ top: "295px", left: ".5em" }} className="ol-control" {...props}>
        <IconButton onClick={handleOpenControl} aria-label="identify" className="styled-ol-control">
          <NotListedLocationIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
        </IconButton>
      </div>,
      btnRef.current as Element
    );
  }

  const IdentifyCard = () => {
    return ReactDOM.createPortal(
      <div className="ol-control" style={{ top: "7px", right: "calc(50% - 160px)", zIndex: 100 }} >
        {showControl &&
          <Card sx={{ maxWidth: 320, minWidth: 320 }}>
            <CardHeader
              action={
                <IconButton onClick={handleCloseControl} color="primary">
                  <CloseIcon />
                </IconButton>
              }
              title="Identificar"
              subheader="Selecione feições no mapa"
              titleTypographyProps={{ variant: 'h6', color: "primary" }}
            />
            {hasResults === false &&
              <Typography color="red" variant="caption" display="block" gutterBottom>
                Nenhuma feição identificada neste ponto
              </Typography>
            }
          </Card>
        }
      </div>,
      contrlRef.current as Element
    );
  }

  useEffect(() => {

    if (!map) return;

    const controlBtn = new Control({ element: btnRef.current as HTMLElement });
    map.addControl(controlBtn);

    const controlCard = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(controlCard);

    return () => {
      map.removeControl(controlBtn);
      map.removeControl(controlCard);
    }
  }, [map]);

  const onMapClicked = (e: MapBrowserEvent<MouseEvent>) => {

    const results: Feature[] = [];

    map?.forEachFeatureAtPixel(e.pixel, (feature, layer) => {

      const definition = layer?.get("LAYER_DEFINITION") as LayerDefinition;

      if (definition?.identifiable) {
        if (targetLayers.find(x => x === definition.key)) {
          const attribs = feature.getProperties();
          attribs["layer_id"] = definition.key;
          const ff = new Feature(feature.getGeometry());
          ff.setProperties(attribs);
          results.push(ff);
        }
      }
    });

    setIdentifyResults(results);
    setHasResults(results.length > 0);
  }

  useEffect(() => {

    if (!map) return;

    map.getViewport().style.cursor = showControl ? 'pointer' : '';

    if (showControl) {
      map.on('click', onMapClicked);
    }
    else {
      map.un('click', onMapClicked);
    }

    return () => {
      map.un('click', onMapClicked);
    }

  }, [showControl]);

  const handleCloseControl = () => {
    setShowControl(false);
  }

  const handleOpenControl = () => {
    setShowControl(true);
  }

  return (
    <>
      <IdentifyBtn></IdentifyBtn>
      <IdentifyCard></IdentifyCard>
    </>
  )
}

export default IdentifyTool;