import React, { useCallback, useEffect, useRef, useState } from "react";
import { RgbaColor, RgbaColorPicker } from "react-colorful";
import { styled } from '@mui/system';
import { Stack } from "@mui/material";

export const toRgbaString = (color: RgbaColor) => {
  const { r, g, b, a } = color;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export const fromRgbaString = (color: string | undefined, defaultColor: RgbaColor = { r: 0, g: 0, b: 0, a: 1 }) => {
  if (color === undefined) return defaultColor;

  const matcher = /rgba?\(?\s*(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?[,\s]+(-?\d*\.?\d+)(%)?,?\s*[\s]*(-?\d*\.?\d+)?(%)?\s*\)?/i;
  const match = matcher.exec(color);

  if (!match) return defaultColor;

  return {
    r: Number(match[1]),
    g: Number(match[3]),
    b: Number(match[5]),
    a: match[7] === undefined ? 1 : Number(match[7]),
  };
}

const useClickOutside = (ref: any, handler: (any: any) => void) => {

  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event: any) => {
      if (startedInside || !startedWhenMounted) return;
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event: any) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

interface ColorPickerProps {
  color: RgbaColor,
  onChange: (color: RgbaColor) => void,
  label: JSX.Element
}

const ColorPicker: React.FC<ColorPickerProps> = ({ label, color, onChange }) => {

  const PickerDiv = styled("div")({
    position: "relative",
    marginTop: "15px",
    marginBottom: "15px",
    marginRight: "15px"
  });

  const SwatchDiv = styled("div")({
    width: "28px",
    height: "28px",
    borderRadius: "8px",
    border: "3px solid #fff",
    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
    cursor: "pointer"
  });

  const PopoverDiv = styled("div")({
    position: "absolute",
    top: "calc(100% + 2px)",
    left: 0,
    borderRadius: "9px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
    zIndex: 100
  });

  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const colorToString = (color: RgbaColor) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }

  return (
    <>
      <PickerDiv>
        <Stack direction="row">
          <SwatchDiv
            style={{ backgroundColor: colorToString(color) }}
            onClick={() => toggle(true)}
          />
          {label}
        </Stack>

        {isOpen && (
          <PopoverDiv ref={popover}>
            <RgbaColorPicker color={color} onChange={onChange} />
          </PopoverDiv>
        )}
      </PickerDiv>
    </>
  )
}

export default ColorPicker;