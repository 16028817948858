import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import OlMap from "ol/Map";
import React, { useEffect, useRef, useState } from "react";
import {
  Card, CardContent, Typography, Button, CardHeader,
  IconButton, Box, FormControl, Select, MenuItem, TextField
} from "@mui/material";
import { ControlAddedToMapSharedArea } from "@opt/mapping";
import CloseIcon from '@mui/icons-material/Close';
import { Control } from "ol/control";
import { Geometry, MultiPolygon } from "ol/geom";
import Draw, { DrawEvent, createBox } from "ol/interaction/Draw";
import { Select as MapSelect } from "ol/interaction";
import { Modify } from "ol/interaction";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import { GeoJSON } from "ol/format";
import booleanContains from "@turf/boolean-contains";
import { featureCollection } from "@turf/turf";
import convex from "@turf/convex";
import { Feature } from "ol";
import { useAlertsStore } from "./AlertsStore";
import { useNotifyStore } from "@opt/ui-core";
import { useUserAuthStore } from "@opt/core";
import { useTranslation } from "react-i18next";
import ClearIcon from '@mui/icons-material/Clear';

interface InspectionPointProps {
  map: OlMap | undefined;
  alertLayer: VectorLayer<VectorSource> | undefined,
  inspectionsLayer: VectorLayer<VectorSource> | undefined
}

const InspectionPoint: React.FC<InspectionPointProps> = ({ map, alertLayer, inspectionsLayer }) => {

  const { t } = useTranslation();
  const { currentTenant } = useUserAuthStore();
  const { createInspection, currentEvent, getInspectionTypes, inspectionTypes } = useAlertsStore();
  const [showControl, setShowControl] = React.useState(false);
  const contrlRef = useRef<HTMLDivElement>(null);
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  const { addMsg } = useNotifyStore();
  const [selectedType, setSelectedType] = useState('');
  const [observations, setObservations] = useState('');

  const [hasSelection, setHasSelection] = useState<boolean>(false);
  const [mapDrawer, setMapDrawer] = useState<Draw>();
  const [mapSelect, setMapSelect] = useState<MapSelect>();
  const [mapModify, setMapModify] = useState<Modify>();

  const handleControlAdded = (control: any) => {
    if (control.controlName !== "InspectionPoint") {
      setShowControl(false);
    }
  }

  useEffect(() => {
    getInspectionTypes();
  }, [])

  useEffect(() => {
    if (!map || !currentTenant?.features?.inspectionPoint) return;

    const control = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(control);

    map.addEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);

    const source = new VectorSource({ wrapX: false });
    setVectorSource(source);

    const style = new Style({
      stroke: new Stroke({
        color: "rgba(255,255,0,1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,255,0,0)",
      }),
    });

    const layer = new VectorLayer({
      source: source,
      map: map,
      style: style
    });

    const draw = new Draw({
      type: 'Circle',
      geometryFunction: createBox()
    });

    setMapDrawer(draw);

    draw.on('drawend', (event: DrawEvent) => {

      const drawed = event.feature.getGeometry();

      if (!drawed) return;

      const allPolygons = alertLayer?.getSource()?.getFeatures().flatMap((feature, idx, arr) => (feature.getGeometry() as MultiPolygon).getPolygons());

      const format = new GeoJSON();
      const filter = format.writeFeatureObject(event.feature);
      const selecteds = allPolygons?.filter(x => booleanContains(filter, format.writeFeatureObject(new Feature(x))));

      if (!selecteds || !selecteds.length) return;

      const turfFeatures = selecteds.map(x => format.writeFeatureObject(new Feature(x)));
      const collection = featureCollection(turfFeatures);
      const hull = convex(collection as any);
      const area = format.readFeature(hull);

      source.clear();
      source.addFeature(area);
      setHasSelection(source.getFeatures().length > 0);
    });

    const select = new MapSelect({
      layers: [layer]
    });

    const modify = new Modify({
      features: select.getFeatures(),
    });

    setMapSelect(select);
    setMapModify(modify);
    //map.addInteraction(select);
    //map.addInteraction(modify);

    return () => {
      map.removeControl(control);
      map.removeEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);

      map?.removeInteraction(mapSelect as MapSelect);
      map?.removeInteraction(mapModify as Modify);
    }
  }, [map, alertLayer, currentTenant]);

  useEffect(() => {
    if (hasSelection && showControl) {
      map?.removeInteraction(mapDrawer as Draw);

      map?.addInteraction(mapSelect as MapSelect);
      map?.addInteraction(mapModify as Modify);
    } else {
      map?.addInteraction(mapDrawer as Draw);

      map?.removeInteraction(mapSelect as MapSelect);
      map?.removeInteraction(mapModify as Modify);
    }
  }, [hasSelection, showControl])

  useEffect(() => {
    if (showControl) {
      map?.addInteraction(mapDrawer as Draw);
      map?.dispatchEvent(new ControlAddedToMapSharedArea("InspectionPoint"));
      setHasSelection(false);
    } else {
      map?.removeInteraction(mapDrawer as Draw);
      vectorSource?.clear();
      setObservations('');
      setSelectedType('');
    }
  }, [showControl])

  const handleSaveInspection = () => {
    const format = new GeoJSON();
    const selecteds = vectorSource?.getFeatures().map(feature => format.writeFeatureObject(feature));

    if (!selecteds?.length) return;

    const features = featureCollection(selecteds);

    createInspection(currentEvent?.eventID as string, selectedType, observations, features)
      .then(x => {
        addMsg("success", t('components.alerts.inspectionPoints.createSucess'));
        inspectionsLayer?.getSource()?.refresh();
        setShowControl(false);
      }).catch(x => {
        addMsg("error", t('components.alerts.inspectionPoints.createError'));
      });
  }

  const handleClear = () => {
    setHasSelection(false);
    setObservations('');
    setSelectedType('');
    vectorSource?.clear();
  }

  return currentTenant?.features?.inspectionPoint ? (
    <>
      <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
        <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {t('components.alerts.inspectionPoints.title')}
          </Typography>
          <Button variant="outlined" size="small" onClick={() => setShowControl(true)}>
            {t('components.alerts.inspectionPoints.selectDetection')}
          </Button>
        </CardContent>
      </Card>
      <div ref={contrlRef} className="ol-control" style={{ top: '7px', right: 'calc(50% - 150px)', zIndex: 100 }}>
        {showControl &&
          <Card sx={{ maxWidth: 320, minWidth: 320 }}>
            <CardHeader
              sx={{ paddingBottom: 0 }}
              action={
                <IconButton onClick={() => setShowControl(false)}>
                  <CloseIcon />
                </IconButton>
              }
              title={t('components.alerts.inspectionPoints.createInpectionPoint')}
              subheader={t('components.alerts.inspectionPoints.selectOnMap')}
              titleTypographyProps={{ variant: 'h6', color: "primary" }}
            />
            <CardContent sx={{ display: "flex", flexFlow: "column", alignItems: "center", paddingTop: 0, paddingBottom: "5px!important" }}>
              {hasSelection &&
                <>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 240 }} size="small">
                    <Select
                      id="inspection-type"
                      displayEmpty
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                    >
                      {inspectionTypes.map(x => {
                        return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <TextField
                      id="observations-text"
                      label="Observações"
                      multiline
                      maxRows={3}
                      value={observations}
                      onChange={(e) => setObservations(e.target.value)}
                      variant="standard"
                    />
                  </FormControl>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: "200px", fontSize: "14px!important", border: "1px #697B84 solid!important" }}
                    onClick={() => handleSaveInspection()}
                    disabled={!selectedType}>
                    {t('components.alerts.inspectionPoints.createInspection')}
                  </Button>
                  <Box display="flex" flexDirection="row">
                    <Typography marginTop={"2px"} gutterBottom variant="body2" component="div" color="primary">
                      {t('components.alerts.inspectionPoints.singleAlertSelected')}
                    </Typography>
                    <IconButton aria-label="clear" size="small" onClick={() => handleClear()} >
                      <ClearIcon color="error" />
                    </IconButton>
                  </Box>
                </>
              }
            </CardContent>
          </Card>
        }
      </div>
    </>
  ) : (<></>)
}

export default InspectionPoint;