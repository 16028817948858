export interface IQueryFilter {

  hasSameFilters(other: IQueryFilter | undefined): boolean;

  buildQueryString(): string;

}

export class QueryFilter implements IQueryFilter {

  public pageIndex: number;
  public pageSize: number;
  public totalCount: number;

  public orderField: string | undefined;
  public orderSort: string | undefined;

  public filterField: string | undefined;
  public filterOperator: string | undefined;
  public filterValue: string | undefined;

  constructor(pageSize: number = 10, orderField: string = "", orderSort: string = "desc") {
    this.pageIndex = 0;
    this.pageSize = pageSize;
    this.totalCount = 0;
    this.orderField = orderField;
    this.orderSort = orderSort;
  }

  public hasSameFilters(other: QueryFilter | undefined): boolean {

    if (other === undefined) return false;

    return (this.filterField === other.filterField &&
      this.filterOperator === other.filterOperator &&
      this.filterValue === other.filterValue &&
      this.orderField === other.orderField &&
      this.orderSort === other.orderSort &&
      this.pageIndex === other.pageIndex &&
      this.pageSize === other.pageSize);
  }

  public buildQueryString(): string {
    const str = `${this.buildPageClause()}&${this.buildFilterClause()}&${this.buildSortClause()}`;

    return str;
  }

  public clone(): QueryFilter {
    const clone = new QueryFilter();
    clone.pageIndex = this.pageIndex;
    clone.pageSize = this.pageSize;
    clone.totalCount = this.totalCount;
    clone.filterField = this.filterField;
    clone.filterOperator = this.filterOperator;
    clone.filterValue = this.filterValue;
    clone.orderField = this.orderField;
    clone.orderSort = this.orderSort;

    return clone;
  }

  public buildFilterClause() {

    const filterClause = `filterField=${this.filterField ?? ""}&filterOperator=${this.filterOperator ?? ""}&filterValue=${this.filterValue ?? ""}`;

    return filterClause;
  }

  public buildPageClause() {

    const pageClause = `pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`;

    return pageClause;
  }

  public buildSortClause() {

    const sortClause = `orderField=${this.orderField ?? ""}&orderSort=${this.orderSort ?? ""}`;

    return sortClause;
  }
}