import React, { useEffect, useState } from "react";
import { PropObservable } from "./PropObservable";
import { WKT } from "ol/format";
import { Feature } from "ol";
import { Geometry, LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Circle from "ol/style/Circle";
import { Extent } from "ol/extent";
import OlMap from "ol/Map";
import { Draw, Modify, Snap } from 'ol/interaction';
import { DrawEvent } from "ol/interaction/Draw";
import { AppMap, calculateExtent } from "@opt/mapping";
import { ModifyEvent } from "ol/interaction/Modify";

interface FormioWebMapCompProps {
  component: any,
  value: PropObservable<string>,
  onChange: (value: any) => any,
  readOnly: boolean
}

const FormioWebMapComp: React.FC<FormioWebMapCompProps> = ({ component, value, onChange, readOnly }) => {

  const [mapHeight, setMapHeight] = useState(300);
  const [geomType, setGeomType] = useState('point');
  const [contextLayers, setContextLayers] = useState<{ type: string, url: string }>();

  const [mapValue, setMapValue] = useState("");
  const [vectorSource, setVectorSource] = useState<VectorSource>();
  const [map, setMap] = useState<OlMap>();

  const [drawer, setDrawer] = useState<Draw>();
  const [snap, setSnap] = useState<Snap>();
  const [modify, setModify] = useState<Modify>();

  useEffect(() => {

    if (!map) return;

    const layer = getLayer();
    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    }
  }, [map]);

  useEffect(() => {

    if (!vectorSource || readOnly) return;

    let type: "Point" | "Polygon" | "MultiPolygon" | "LineString" | "MultiLineString" = "Point";

    if (geomType === 'Polygon' || geomType === 'MultiPolygon') type = "Polygon";
    if (geomType === 'LineString' || geomType === 'MultiLineString') type = "LineString";

    const modify = new Modify({ source: vectorSource });
    map?.addInteraction(modify);
    setModify(modify);

    const draw = new Draw({ source: vectorSource, type: type });
    map?.addInteraction(draw);
    setDrawer(draw);

    const snap = new Snap({ source: vectorSource });
    map?.addInteraction(snap);
    setSnap(snap);

    draw.on('drawstart', (event: DrawEvent) => {
      vectorSource.clear();
    });

    draw.on('drawend', (event: DrawEvent) => {
      let drawed = event.feature.getGeometry();

      if (!drawed) return;

      handleDrawChanged(drawed);
    });

    modify.on("modifyend", (event: ModifyEvent) => {
      let drawed = event.features.item(0).getGeometry() as Geometry;

      if (!drawed) return;

      handleDrawChanged(drawed);
    })

    return () => {
      map?.removeInteraction(modify);
      map?.removeInteraction(draw);
      map?.removeInteraction(snap);
    }
  }, [vectorSource]);

  const handleDrawChanged = (drawed: Geometry) => {
    if (drawed.getType() === 'Polygon') {
      if (geomType === 'MultiPolygon') {
        drawed = new MultiPolygon([drawed as Polygon]);
      }
    }
    else if (drawed.getType() === 'LineString') {
      if (geomType === 'MultiLineString') {
        drawed = new MultiLineString([drawed as LineString]);
      }
    }
    else if (drawed.getType() === 'Point') {
      if (geomType === 'MultiPoint') {
        drawed = new MultiPoint([(drawed as Point).getCoordinates()]);
      }
    }

    const wkt = new WKT().writeGeometry(drawed);

    onChange(wkt);
  }

  useEffect(() => {

    if (value) {
      value.subscribe((item) => setMapValue(item));
    }

    if (value.lastItem) {
      setMapValue(value.lastItem);
    }

    setMapHeight(component.mapHeight);
    setGeomType(component.geometryType);
    setContextLayers(component.layers?.map((x: any) => { return { type: x.layertype, url: x.url }; }));

    map?.updateSize();

  }, [component, value]);

  useEffect(() => {

    if (!mapValue || mapValue === "" || !vectorSource) return;

    const feature = new WKT().readFeature(mapValue);

    vectorSource.clear();
    vectorSource.addFeature(feature as Feature<Geometry>);
    vectorSource.changed();

    const view = calculateExtent(feature.clone(), 20);

    map?.getView().fit(view as Extent);

  }, [mapValue, vectorSource]);

  function getLayer() {

    const source = new VectorSource();
    setVectorSource(source);

    const fill = new Fill({
      color: 'rgba(255, 179, 179, 0.5)'
    });

    const stroke = new Stroke({
      color: 'red',
      width: 2
    });

    const vector = new VectorLayer({
      visible: true,
      source: source,
      style: new Style({
        image: new Circle({
          fill: fill,
          stroke: stroke,
          radius: 5,
        }),
        fill: fill,
        stroke: stroke,
      }),
    });

    return vector;
  }

  return (
    <div style={{ minHeight: mapHeight }}>
      <AppMap mapHeight={`${mapHeight}px`} onMapCreated={setMap}>

      </AppMap>
    </div>
  )
}

export default FormioWebMapComp;