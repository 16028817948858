export class InspectionDetails {

  public inspectionID: string;
  public eventID: string;
  public executionID: string;
  public aoi: string;
  public tenantID: string;
  public datasetID: string;
  public sensorID: string;
  public algorithmName: string;
  public gridName: string;
  public stacCatalog: string;
  public stacItemID: string;
  public stacItemDate: Date | undefined;
  public baselineStacItemID: string;
  public baselineStacItemDate: Date | undefined;
  public sensorName: string;
  public datasetName: string;
  public algorithm: string;
  public projectID: string;
  public parcelID: string;
  public inspectionTypeID: string;
  public inspectionTypeName: string;
  public observations: string;
  public inspectionNumber: string;
  public status: string;
  public archived: boolean | undefined;
  public creationType: "MANUAL" | "SYSTEM";
  public createdDate: Date | undefined;

  constructor() {
    this.inspectionID = "";
    this.eventID = "";
    this.executionID = "";
    this.aoi = "";
    this.tenantID = "";
    this.datasetID = "";
    this.sensorID = "";
    this.algorithmName = "";
    this.gridName = "";
    this.stacCatalog = "";
    this.stacItemID = "";
    this.baselineStacItemID = "";
    this.sensorName = "";
    this.datasetName = "";
    this.algorithm = "";
    this.projectID = "";
    this.parcelID = "";
    this.inspectionTypeID = "";
    this.inspectionTypeName = "";
    this.observations = "";
    this.inspectionNumber = "";
    this.status = "";
    this.creationType = "SYSTEM";
  }
}
