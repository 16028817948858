import { styled } from '@mui/material';
import {
  AppMap, FeatureLayer, LayerDefinition,
  LayerType, MapLegend, MVTLayer
} from '@opt/mapping';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import config from '../../config';
import AlertMoments from './AlertMoments';
import { useAlertsStore } from './AlertsStore';
import OlMap from 'ol/Map';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { Extent } from 'ol/extent';
import AlertsNavigator from './AlertsNavigator';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { useTranslation } from 'react-i18next';

const Root = styled('div')({
  display: "flex",
  height: "100%",
  width: "100%"
});

const MapArea = styled('div')({
  flex: "5 1 0"
});

const MapDiv = styled('div')({
  flex: "1",
  width: "100%",
  height: "calc(100% - 100px)"
});

const HistoryArea = styled('div')({
  maxHeight: "100px",
  minHeight: "100px"
});

const DetailsArea = styled('div')({
  flex: "2 1 0",
  padding: "5px",
  maxWidth: "420px",
  overflowY: "scroll",
  height: "calc(100% - 5px)!important",
  maxHeight: "calc(100% - 5px)!important"
});

interface BaseAlertLayoutProps {
  details: JSX.Element,
  onMapCreated?: (map: OlMap) => void;
  onAlertLayerCreated?: (layer: VectorLayer<VectorSource>) => void;
  onInspectionsLayerCreated?: (layer: VectorLayer<VectorSource>) => void;
  zoneBuffer?: number,
  hideAlertNavigator?: boolean
}

const BaseAlertLayout: React.FC<BaseAlertLayoutProps> = (
  {
    details,
    onMapCreated,
    onAlertLayerCreated,
    onInspectionsLayerCreated,
    zoneBuffer,
    hideAlertNavigator
  }): JSX.Element => {

  const { t } = useTranslation();

  const { keepExtent, currentEvent, getExtension } = useAlertsStore();
  const [map, setMap] = useState<OlMap>();
  const [layers, setLayers] = useState<LayerDefinition[]>([]);
  const [alertLayer, setAlertLayer] = useState<VectorLayer<VectorSource> | undefined>();
  const [inspectionsLayer, setInspectionsLayer] = useState<VectorLayer<VectorSource> | undefined>();

  useEffect(() => {
    if (!currentEvent || !map) return;

    getExtension(currentEvent.eventID, zoneBuffer ?? 1000)
      .then(bbox => {
        CreateLayers(bbox as Extent);
      })
  }, [currentEvent, map]);

  useEffect(() => {
    if (onAlertLayerCreated && alertLayer) {
      onAlertLayerCreated(alertLayer);
    }
  }, [alertLayer])

  useEffect(() => {
    if (onInspectionsLayerCreated && inspectionsLayer) {
      onInspectionsLayerCreated(inspectionsLayer);
    }
  }, [inspectionsLayer])

  const CreateLayers = (viewExtent: Extent) => {

    if (!currentEvent || !map) return;

    const aoiStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(255, 0, 255, 0)'
      }),
      stroke: new Stroke({
        color: 'rgba(204, 0, 204, 1)',
        width: 2
      })
    });

    const aoiDefinition = new LayerDefinition("aoi_layer", LayerType.MVT,
      `${config.gisServices.url}/mvt/aoi/${currentEvent.aoiID}/{x}/{y}/{z}.pbf`,
      t('components.alerts.baseLayout.layers.aoiLayer'), 10, false, true, false, undefined, aoiStyle);

    const clipStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(179, 204, 255, 0)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 68, 204, 1)',
        width: 2
      })
    });

    const clipDefinition = new LayerDefinition("clipped_aoi_layer", LayerType.MVT,
      `${config.gisServices.url}/mvt/aoi/${currentEvent.aoiID}/{x}/{y}/{z}/clip?sensor=${currentEvent.sensorID}&grid=${currentEvent.gridName}`,
      t('components.alerts.baseLayout.layers.clippedLayer'), 11, true, true, false, viewExtent, clipStyle);

    const alertStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(255, 179, 179, 0)'
      }),
      stroke: new Stroke({
        color: 'red',
        width: 2
      })
    });

    const alertDefinition = new LayerDefinition("alert_layer", LayerType.Feature,
      `${config.gisServices.url}/feature/event/${currentEvent.eventID}`,
      t('components.alerts.baseLayout.layers.alertLayer'), 12, true, true, false, viewExtent, alertStyle);

    const maskedStyle: Style = new Style({
      fill: new Fill({
        color: '#F72F35'
      }),
      stroke: new Stroke({
        color: '#2FF7F1',
        width: 2
      })
    });

    const maskedDefinition = new LayerDefinition("masked_layer", LayerType.Feature,
      `${config.gisServices.url}/feature/event/${currentEvent.eventID}/masked`,
      t('components.alerts.baseLayout.layers.maskedLayer'), 13, true, true, false, viewExtent, maskedStyle);

    const inspectionsStyle: Style = new Style({
      fill: new Fill({
        color: 'rgba(102,204,0,0.5)'
      }),
      stroke: new Stroke({
        color: '#336600',
        width: 2
      })
    });

    const inspectionsDefinition = new LayerDefinition("inspections_layer", LayerType.Feature,
      `${config.gisServices.url}/feature/event/${currentEvent.eventID}/inspections`,
      t('components.alerts.baseLayout.layers.inspectionsLayer'), 14, true, true, false, viewExtent, inspectionsStyle);

    setLayers([aoiDefinition, clipDefinition, alertDefinition, maskedDefinition, inspectionsDefinition]);

    if (!keepExtent) {
      map.getView().fit(viewExtent as Extent);
    }
  }

  const handleMapCreated = (map: OlMap) => {
    setMap(map);
    if (onMapCreated) onMapCreated(map);
  }

  const vectorLayers = useMemo(() => {
    const l = layers.map(layer => {
      if (layer.type === LayerType.MVT) {
        return <MVTLayer key={layer.key} definition={layer} />
      }
      else if (layer.type === LayerType.Feature) {
        if (layer.key === "alert_layer") {
          return <FeatureLayer key={layer.key} definition={layer} setCreatedLayer={setAlertLayer} />
        } else if (layer.key === "inspections_layer") {
          return <FeatureLayer key={layer.key} definition={layer} setCreatedLayer={setInspectionsLayer} />
        } else {
          return <FeatureLayer key={layer.key} definition={layer} />
        }
      }
    });

    return l;
  }, [layers])

  return (
    <>
      <Root>
        <MapArea>
          <MapDiv>
            <AppMap mapHeight="100%" onMapCreated={(map: any) => handleMapCreated(map)}>
              {layers.length > 0 && vectorLayers}
              <MapLegend></MapLegend>
              {!hideAlertNavigator && <AlertsNavigator map={map} alertLayer={alertLayer}></AlertsNavigator>}
            </AppMap>
          </MapDiv>
          <HistoryArea>
            <AlertMoments />
          </HistoryArea>
        </MapArea>
        <DetailsArea>
          {details}
        </DetailsArea>
      </Root>
    </>
  )
}

export default BaseAlertLayout;