import { DataFetchAPI, DataQueryAPI, FormatDate, PostAPI, QueryFilter } from "@opt/core";
import { create } from 'zustand';
import config from "../../config";
import { InspectionPerAOI } from "../../models/InspectionPerAOI";
import { calculateExtent, FeatureFetchAPI } from "@opt/mapping";
import { Extent } from "ol/extent";
import { Feature } from "ol";
import { InspectionDetails } from "../../models/InspectionDetails";
import { RasterView } from "../../models/RasterView";
import { InspectionFilters } from "../../models/InspectionFilters";
import { startOfDay, endOfDay, subDays } from 'date-fns';

interface InspectionsState {
  current: InspectionPerAOI | undefined,
  currentInspectionDetails: InspectionDetails | undefined,
  rows: InspectionPerAOI[];
  queryFilter: QueryFilter;
  filters: InspectionFilters,
  filterDataset: string,
  filterStatus: string,
  filterRangeDate: [Date, Date],
  filterType: string,
  viewExtent: Extent | undefined,
  filterInspectionNumber: string,
  queryInspections: (filter: QueryFilter) => Promise<InspectionPerAOI[] | undefined>,
  getFilters: () => Promise<InspectionFilters | undefined>;
  getAOIInspection: (aoi: string) => Promise<InspectionPerAOI | undefined>;
  getExtension: (aoiID: string, zoneBuffer: number) => Promise<Extent | undefined>;
  getDetails: (inspectionID: string) => Promise<InspectionDetails | undefined>;
  getRasterViews: (inspectionID: string) => Promise<RasterView[] | undefined>;
  postStatus: (inspectionID: string, status: string, opinion: string) => Promise<any>;
  setFilterStatus: (value: string) => void;
  setFilterDataset: (value: string) => void;
  setFilterType: (value: string) => void;
  setFilterDateRange: (value: [Date, Date]) => void;
  setFilterInspectionNumber: (value: string) => void;
  clearCurrentInspectionDetails: () => void;
  getDatasetTables: (datasetID: string) => Promise<string[] | undefined>;
}

const queryInspections = (dataset: string, status: string, type: string, startDate: Date, endDate: Date) => {
  const sd = FormatDate(startDate);
  const ed = FormatDate(endDate);

  const { doQuery } = DataQueryAPI<InspectionPerAOI>(InspectionPerAOI,
    `${config.apiServices.url}/inspections/details?ds=${dataset}&s=${status}&t=${type}&sd=${sd}&ed=${ed}`);

  return doQuery;
}

const fetchExtension = (aoiID: string) => {
  const { doFetch } = FeatureFetchAPI(`${config.gisServices.url}/feature/aoi/${aoiID}/bbox`);
  return doFetch;
}

const { doFetch: queryFilters } = DataFetchAPI<InspectionFilters>(InspectionFilters, `${config.apiServices.url}/inspections/filters`);

const { doFetch: getAOIInspections } = DataFetchAPI<InspectionPerAOI>(InspectionPerAOI, `${config.apiServices.url}/inspections/aoi`);

const getDetails = (inspectionID: string) => {
  const { doFetch } = DataFetchAPI<InspectionDetails>(InspectionDetails, `${config.apiServices.url}/inspections/${inspectionID}/details`);
  return doFetch;
}

const fetchRasterViews = (inspectionID: string) => {
  const { doFetchMany } = DataFetchAPI<RasterView>(RasterView, `${config.apiServices.url}/inspections/${inspectionID}/rasters`);
  return doFetchMany;
}

const postStatus = (inspectionID: string) => {
  const { doPost } = PostAPI(Object, `${config.apiServices.url}/inspections/${inspectionID}/status`);
  return doPost;
}

const fetchDatasetTables = (datasetID: string) => {
  const { doFetchMany } = DataFetchAPI<Object>(Object, `${config.apiServices.url}/inspections/${datasetID}/tables`);
  return doFetchMany;
}

export const useInspectionsStore = create<InspectionsState>((set, get) => ({
  current: undefined,
  currentInspectionDetails: undefined,
  rows: [],
  queryFilter: new QueryFilter(),
  filters: new InspectionFilters(),
  filterDataset: "ALL",
  filterStatus: "ALL",
  filterRangeDate: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
  filterType: "ALL",
  viewExtent: undefined,
  filterInspectionNumber: '',

  clearCurrentInspectionDetails: () => {
    set({ currentInspectionDetails: undefined });
  },

  setFilterDataset: (value: string) => {
    set({ filterDataset: value });
  },

  setFilterType: (value: string) => {
    set({ filterType: value });
  },

  setFilterStatus: (value: string) => {
    set({ filterStatus: value });
  },

  setFilterDateRange: (value: [Date, Date]) => {
    set({ filterRangeDate: value });
  },

  setFilterInspectionNumber: (value: string) => {
    set({ filterInspectionNumber: value });
  },

  queryInspections: async (filter: QueryFilter) => {
    const query = await queryInspections(get().filterDataset, get().filterStatus, get().filterType,
      get().filterRangeDate[0], get().filterRangeDate[1])
      (filter);

    set({ rows: query?.items, queryFilter: query?.queryFilter });

    return query?.items;
  },

  getFilters: async () => {
    const query = await queryFilters("");
    set({ filters: query });

    return query;
  },

  getAOIInspection: async (aoi: string) => {
    const query = await getAOIInspections(aoi);

    set({
      current: query,
      currentInspectionDetails: undefined
    });

    return query;
  },

  getExtension: async (aoiID: string, zoneBuffer: number) => {
    const query = await fetchExtension(aoiID)("");
    const feature = query?.at(0) as Feature;

    const viewExtent = calculateExtent(feature, zoneBuffer);

    set({ viewExtent: viewExtent });

    return viewExtent;
  },

  getDetails: async (inspectionID: string) => {
    const query = await getDetails(inspectionID)("");

    set({ currentInspectionDetails: query });

    return query;
  },

  getRasterViews: async (inspectionID: string) => {
    const query = await fetchRasterViews(inspectionID)();

    return query;
  },

  postStatus: async (inspectionID: string, status: string, opinion: string) => {
    const post = await postStatus(inspectionID)({ inspectionID: inspectionID, status: status, opinion: opinion });
    return post;
  },

  getDatasetTables: async (datasetID: string) => {
    const query = await fetchDatasetTables(datasetID)();

    return query as string[];
  },
}));