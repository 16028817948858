import React, { useEffect, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import {
  AppBar, Toolbar, IconButton, Box, Typography,
  Divider, Menu, MenuItem, Button
} from "@mui/material";
import { useUserAuthStore } from "@opt/core";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  logoArea?: JSX.Element,
  profileArea?: JSX.Element,
  logoutArea?: JSX.Element,
  notificationArea?: JSX.Element
}

const Header: React.FC<HeaderProps> = ({ logoArea, profileArea, logoutArea, notificationArea }) => {

  const navigate = useNavigate();
  const { username, currentTenant, setCurrentTenant, tenants, tenantChanged } = useUserAuthStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (tenantChanged > 0) {
      navigate("/");
    }
  }, [tenantChanged])

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTenant = (tenant: any) => {
    setCurrentTenant(tenant);
  }

  const menuId = 'primary-account-menu';

  const ProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled={true}>
        <div>
          <Typography variant="h6">{username}</Typography>
          <Typography variant="body2">{currentTenant?.name}</Typography>
        </div>
      </MenuItem>
      <Divider />
      <Stack>
        {tenants.map((x: any) => {
          return <Button key={x.id} size="small" onClick={() => handleChangeTenant(x)}>{x.name}</Button>
        })}
      </Stack>
      <Divider />
      {profileArea && profileArea}
      <Divider />
      {logoutArea && logoutArea}
    </Menu>
  );

  return (
    <>
      <AppBar>
        <Toolbar>
          {logoArea && logoArea}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {notificationArea && notificationArea}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              aria-controls={menuId}
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {ProfileMenu}
    </>
  )
}

export default Header;