import React from "react";
import CockpitView from "../components/cockpit/CockpitView";

const HomePage: React.FC = () => {

  return (
    <>
      <CockpitView />
    </>
  )
}

export default HomePage;