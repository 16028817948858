import { Components } from 'formiojs';

const nestedComponentForm = (Components as any).components.base.editForm;

export default function (...extend: any) {
  return nestedComponentForm([
    {
      key: 'display',
      components: [
        {
          key: 'labelPosition',
          ignore: true
        },
        {
          key: 'placeholder',
          ignore: true
        },
        {
          key: 'description',
          ignore: true
        },
        {
          key: 'hideLabel',
          ignore: true
        },
        {
          key: 'autofocus',
          ignore: true
        },
        {
          key: 'tooltip',
          ignore: true
        },
        {
          key: 'tabindex',
          ignore: true
        },
        {
          key: 'disabled',
          ignore: true
        },
        {
          type: 'textfield',
          label: 'Nome do Mapa',
          key: 'mapName',
          input: true,
          weight: 1,
          placeholder: 'Nome do mapa',
          tooltip: 'Digite o nome do mapa.'
        },
        {
          type: 'number',
          label: 'Altura do mapa',
          key: 'mapHeight',
          defaultValue: 300,
          input: true,
          weight: 1,
          placeholder: 'Altura do mapa',
          tooltip: 'Digite um tamanho de altura para o mapa.'
        },
        {
          type: 'select',
          input: true,
          weight: 0,
          tooltip: 'Tipo de geometria',
          key: 'geometryType',
          defaultValue: 'Point',
          label: 'Tipo de Geometria',
          dataSrc: 'values',
          data: {
            values: [
              { label: 'Point', value: 'Point' },
              { label: 'MultiPoint', value: 'MultiPoint' },
              { label: 'Polygon', value: 'Polygon' },
              { label: 'MultiPolygon', value: 'MultiPolygon' },
              { label: 'LineString', value: 'LineString' },
              { label: 'MultiLineString', value: 'MultiLineString' }
            ],
          },
        },
        {
          type: 'datagrid',
          input: true,
          label: 'Lista de Camadas',
          key: 'layers',
          tooltip: 'Lista de camadas de referência a ser incluídas no mapa',
          weight: 10,
          reorder: true,
          defaultValue: [{ layertype: '', url: '' }],
          components: [
            {
              label: 'Tipo',
              key: 'layertype',
              input: true,
              type: 'select',
              defaultValue: '',
              dataSrc: 'values',
              data: {
                values: [
                  { label: 'MVT', value: 'mvt' },
                  { label: 'WMTS', value: 'wmts' }
                ],
              }
            },
            {
              label: 'URL',
              key: 'url',
              input: true,
              type: 'textfield'
            },
          ]
        }
      ]
    },
    {
      key: 'data',
      ignore: true
    },
    {
      key: 'validation',
      ignore: true
    },
    {
      key: 'addons',
      ignore: true
    }
  ], ...extend);
}