import { plainToClassFromExist } from "class-transformer";
import { useUserAuthStore } from "../auth/UserAuthStore";
import { PagedResult } from "../PagedResult";
import { IQueryFilter } from "../QueryFilter";

export const DataQueryAPI = <TModel>(typeFactory: Function, endpoint: string | undefined): {
  doQuery: (queryFilter: IQueryFilter | undefined) => Promise<PagedResult<TModel> | undefined>
} => {

  const doQuery = async (queryFilter: IQueryFilter | undefined) => {
    if (endpoint === undefined) return;

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = `${endpoint}${endpoint.indexOf('?') < 0 ? '?' : '&'}${queryFilter?.buildQueryString()}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to query model.");

        return plainToClassFromExist(new PagedResult<TModel>(typeFactory), await response.json());
      });

    return result;
  }

  return { doQuery: doQuery };
}