import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map";
import { Box, Paper, Slider } from "@mui/material";
import { Control } from "ol/control";
import { addMonths, format, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from "date-fns/locale";
import { useDebounce } from "@opt/core";

type TimeSliderProps = {
  map: Map | undefined;
  initialValue: [number, number],
  onChage: (startDate: Date, endDate: Date) => void;
}

const TimeSlider: React.FC<TimeSliderProps> = ({ map, initialValue, onChage }) => {

  const contrlRef = useRef<HTMLDivElement>(null);

  const [range, setRange] = React.useState<number[]>(initialValue);
  const [marks, setMarks] = useState<{ value: number; label: string; }[]>([]);
  const debouncedRage = useDebounce(range, 700);

  useEffect(() => {
    const start = startOfMonth(addMonths(new Date(), range[0] - 12));
    const end = endOfMonth(addMonths(new Date(), range[1] - 12));

    onChage(start, end);
  }, [debouncedRage])

  useEffect(() => {
    if (!map) return;

    const control = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(control);

    const values = [];
    for (let index = 1; index <= 12; index++) {
      const month = addMonths(new Date(), index - 12);

      const value = {
        value: index,
        label: format(month, 'MMM/yy', { locale: ptBR }),
      }

      values.push(value);
    }

    setMarks(values);

    return () => {
      map.removeControl(control);
    }
  }, [map])

  const valueLabelFormat = (value: number) => {
    const month = addMonths(new Date(), value - 12);

    return format(month, 'MMM/yy', { locale: ptBR });
  }

  const handleChangeRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 1;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setRange([clamped - minDistance, clamped]);
      }
    } else {
      setRange(newValue as number[]);
    }
  };

  return (
    <>
      <div ref={contrlRef} className="ol-control" style={{ bottom: '20px', right: 'calc(50% - 300px)', zIndex: 100 }}>
        <Paper
          elevation={3}
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 600 }}
        >
          <Box sx={{ marginRight: "20px", marginLeft: "20px", width: "100%" }}>
            <Slider
              size="small"
              defaultValue={range}
              value={range}
              onChange={handleChangeRange}
              valueLabelFormat={valueLabelFormat}
              step={1}
              marks={marks}
              min={1}
              max={12}
              valueLabelDisplay="auto"
              disableSwap
            />
          </Box>
        </Paper>
      </div>
    </>
  )
}

export default TimeSlider;