import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { QueryFilter } from "@opt/core";
import { FieldMetadata } from "@opt/core";
import DeleteDialog from "../DeleteDialog";
import useMetadataStore from "./MetadataStore";
import DataGrid from "../DataGrid";
import FieldsMetadataForm from "./FieldsMetadataForm";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  },
  gridBox: {
    margin: "20px",
    flexGrow: 1
  }
}

interface FieldMetadataViewProps {
  modelID: string | undefined
}

const FieldMetadataView: React.FC<FieldMetadataViewProps> = ({ modelID }) => {

  const { currentTableMetadata, fieldsMetadata: fieldMetadata, lastQueryFilterFieldMetadata: lastQueryFilter,
    fetchTableMetadata, queryFieldMetadata, createFieldMetadata, updateFieldMetadata, deleteFieldMetadata } = useMetadataStore();

  const [showForm, setShowForm] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedModel, setSelectedModel] = useState<FieldMetadata>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 120,
      editable: false,
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 200,
      editable: false,
    },
    {
      field: 'editable',
      headerName: 'Editável',
      width: 80,
      editable: false,
    },
    {
      field: 'visible',
      headerName: 'Visível',
      width: 80,
      editable: false,
    },
    {
      field: 'isKey',
      headerName: 'Identificador',
      width: 100,
      editable: false,
    },
    {
      field: 'autoGenerate',
      headerName: 'Auto-Gerado',
      width: 100,
      editable: false,
    },
    {
      field: 'order',
      headerName: 'Ordem',
      width: 80,
      editable: false,
    },
    {
      field: 'length',
      headerName: 'Tamanho',
      width: 80,
      editable: false,
    },
    {
      field: 'precision',
      headerName: 'Precisão',
      width: 80,
      editable: false,
    },
    {
      field: 'dataType',
      headerName: 'Tipo',
      width: 100,
      editable: false,
    }
  ];

  useEffect(() => {
    if (!modelID) return;
    fetchTableMetadata(modelID);
  }, [modelID])

  useEffect(() => {
    queryFieldMetadata(lastQueryFilter);
  }, [currentTableMetadata])

  useEffect(() => {

    if (!showForm) {
      setSelectedModel(undefined);
      setUpdateMode(false);
      setReadOnlyMode(false);
    }

  }, [showForm])

  const handleSubmitForm = (model: any) => {

    if (updateMode) {
      updateFieldMetadata(model).then(_ => {
        setShowForm(false);
        queryFieldMetadata(lastQueryFilter);
      });
    } else {
      createFieldMetadata(model).then(_ => {
        setShowForm(false);
        queryFieldMetadata(lastQueryFilter);
      });
    }
  }

  const handleOnEdit = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(false);
    setUpdateMode(true);
    setShowForm(true);
  }

  const handleOnDelete = (data: any) => {
    setSelectedModel(data);
    setShowDeleteDialog(true);
  }

  const handleOnDetails = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(true);
    setShowForm(true);
  }

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      deleteFieldMetadata(selectedModel?.id as string).then(_ => {
        queryFieldMetadata(lastQueryFilter);
        setShowDeleteDialog(false);
      })
    }
  }

  const onChangeFilters = (filter: QueryFilter) => {
    queryFieldMetadata(filter);
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Fields Metadata - {currentTableMetadata?.name}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Cadastro dos atributos da tabela ou classe de feição
        </Typography>
      </Box>
      <Box sx={styles.searchBox}>
        <Button size="small" variant="contained" onClick={() => setShowForm(true)}>Novo</Button>
      </Box>
      <Box sx={styles.gridBox}>
        <DataGrid
          height="calc(100vh - 250px)"
          dataRows={fieldMetadata}
          dataColumns={columns}
          queryFilter={lastQueryFilter}
          onChangeFilters={onChangeFilters}
          onEditClicked={handleOnEdit}
          onDeleteClicked={handleOnDelete}
          onDetailsClicked={handleOnDetails}
        />
      </Box>
      <Drawer
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "500px", maxWidth: "500px" }}>
          <FieldsMetadataForm
            tableID={modelID as string}
            forUpdate={updateMode}
            forReadOnly={readOnlyMode}
            model={selectedModel as FieldMetadata}
            onSubmit={handleSubmitForm}
          ></FieldsMetadataForm>
        </Box>

      </Drawer>
      <DeleteDialog confirmation={handleDeleteConfirmation} showDialog={showDeleteDialog} />
    </>
  );
}

export default FieldMetadataView;