import { Type } from "class-transformer";
import { AlgorithmFilter } from "./AlgorithmFilter";

export class DatasetFilter {
    
    public id: string;
    public name: string;

    @Type(() => AlgorithmFilter)
    public algorithms: AlgorithmFilter[];

    constructor() {
        this.id = "";
        this.name = "";
        this.algorithms = [];
    }
}