export function FormatString(str: string, ...val: string[]) {

  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }

  return str;
}

export function FormatDecimalNumber(number: number) {
  if (number) {
    return number.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return "";
}

export function FormatNumber(number: number) {
  if (number) {
    return number.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  return "";
}

export function FormatDate(date: any) {
  function formatFromDate(dt: Date) {
    return new Intl.DateTimeFormat('pt-BR').format(dt);
  }

  if (date instanceof Date) {
    const dt = date as Date;

    return formatFromDate(dt);
  }

  if (typeof date === "string") {
    const dtStr = date as string;

    //dd-mm-yyyy "01-01-2021"
    const regexExp01 = "[0-9]{2}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{4}";
    const m1 = dtStr.match(regexExp01);

    if (m1) {
      const dateSplitted = m1[0].split(m1[1]);
      const day = Number.parseInt(dateSplitted[0]);
      const month = Number.parseInt(dateSplitted[1]) -1;
      const year = Number.parseInt(dateSplitted[2]);

      return formatFromDate(new Date(year, month, day));
    }

    //yyyy-mm-dd "2022-01-01"
    const regexExp02 = "[0-9]{4}([\-/ \.])[0-9]{2}[\-/ \.][0-9]{2}";
    const m2 = dtStr.match(regexExp02);

    if (m2) {
      const dateSplitted = m2[0].split(m2[1]);
      const year = Number.parseInt(dateSplitted[0]);
      const month = Number.parseInt(dateSplitted[1]) -1;
      const day = Number.parseInt(dateSplitted[2]);

      return formatFromDate(new Date(year, month, day));
    }
  }
}