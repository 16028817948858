import BaseEvent from "ol/events/Event"


export class ControlAddedToMapSharedArea extends BaseEvent {

  static readonly EventName = "opt-map-control-added-to-shared-area";

  constructor(public controlName: string) {
    super(ControlAddedToMapSharedArea.EventName)
  }
}