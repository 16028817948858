import VectorTile from 'ol/layer/VectorTile';
import WebGLVectorTileLayerRenderer from 'ol/renderer/webgl/VectorTileLayer';
import { asArray } from 'ol/color';
import { packColor, parseLiteralStyle } from 'ol/webgl/styleparser';

const result = parseLiteralStyle({
  'fill-color': ['get', 'fillColor'],
  'stroke-color': ['get', 'strokeColor'],
  'stroke-width': ['get', 'strokeWidth'],
  symbol: {
    symbolType: 'circle',
    size: 8,
    color: '#777',
  },
});

// Experimental!!
export class WebGLVectorTileLayer extends VectorTile {
  createRenderer() {
    return new WebGLVectorTileLayerRenderer(this, {
      style: {
        fill: {
          fragment: result.builder.getFillFragmentShader(),
          vertex: result.builder.getFillVertexShader(),
        },
        stroke: {
          fragment: result.builder.getStrokeFragmentShader(),
          vertex: result.builder.getStrokeVertexShader(),
        },
        symbol: {
          fragment: result.builder.getSymbolFragmentShader(),
          vertex: result.builder.getSymbolVertexShader(),
        },       
        attributes: {
          fillColor: {
            size: 2,
            callback: (feature) => {
              const style = (this.getStyle() as any)(feature, 1);
              const color = asArray(style?.getFill()?.getColor() || '#eee');
              return packColor(color);
            },
          },
          strokeColor: {
            size: 2,
            callback: (feature) => {
              const style = (this.getStyle() as any)(feature, 1);
              const color = asArray(style?.getStroke()?.getColor() || '#eee');
              return packColor(color);
            },
          },
          strokeWidth: {
            size: 1,
            callback: (feature) => {
              const style = (this.getStyle() as any)(feature, 1);
              return style?.getStroke()?.getWidth() || 0;
            },
          },
        },
      },
    }) as any;
  }
}
