import { Box, Paper, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

interface FlipPanelProps {
  front: JSX.Element,
  back: JSX.Element,
  flipped: boolean
}

const Root = styled(Paper)({
  width: "100%",
  height: "100%",
  position: "relative",
  transition: "transform 1s",
  transformStyle: "preserve-3d",
  '&.flipped': {
    transform: "rotateY(180deg)"
  }
});

const FrontSide = styled(Box)({
  position: "absolute",
  backfaceVisibility: "hidden",
  width: "100%",
  height: "100%",
  background: "white",
  overflow: "hidden"
});

const BackSide = styled(Box)({
  position: "absolute",
  backfaceVisibility: "hidden",
  width: "100%",
  height: "100%",
  background: "white",
  transform: "rotateY( 180deg )",
  overflow: "hidden"
});

const FlipPanel: React.FC<FlipPanelProps> = ({ front, back, flipped }) => {

  const card = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (flipped) {
      card?.current?.classList.add("flipped");
    } else {
      card?.current?.classList.remove("flipped");
    }
  }, [flipped])

  return (
    <>
      <Root ref={card}>
        <FrontSide>{front}</FrontSide>
        <BackSide>{back}</BackSide>
      </Root>
    </>
  )
}

export default FlipPanel;