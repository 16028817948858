import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import { FormatDate, FormatDecimalNumber } from "@opt/core";
import React, { useEffect } from "react";
import { useAlertsStore } from "./AlertsStore";
import { useTranslation } from "react-i18next";

const AlertCard: React.FC = (): JSX.Element => {

  const { t } = useTranslation();
  const { currentEvent, eventDetails, getEventDetails } = useAlertsStore();

  useEffect(() => {
    if (!currentEvent) return;
    getEventDetails(currentEvent.eventID);
  }, [currentEvent]);

  return (
    <>
      {eventDetails &&
        <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
          <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
            <Typography gutterBottom variant="h6" component="div" color="primary">
              {t('components.alerts.alertCard.title')}
            </Typography>
            <Typography sx={{ fontWeight: 'bold' }} align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.alertCard.algorithm')}: {eventDetails.algorithmShortDescription}
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.alertCard.area')}: {FormatDecimalNumber(eventDetails.shapeArea)} ha
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.alertCard.dateExecution')}: {FormatDate(eventDetails.executionDate)}
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.alertCard.sensor')}: {eventDetails.stacCatalogue}
            </Typography>
            <Typography align={"left"} variant="body2" color="text.secondary">
              {t('components.alerts.alertCard.sceneDate')}: {FormatDate(eventDetails.stacItemDate)}
            </Typography>
            <Tooltip title={eventDetails.stacItemID}>
              <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                {t('components.alerts.alertCard.scene')}: {eventDetails.stacItemID}
              </Typography>
            </Tooltip >
            {eventDetails?.baselineSTACItemDate &&
              <Typography align={"left"} variant="body2" color="text.secondary">
                {t('components.alerts.alertCard.baselineSceneDate')}: {FormatDate(eventDetails.baselineSTACItemDate)}
              </Typography>
            }
            {eventDetails?.baselineSTACItemID &&
              <Tooltip title={eventDetails.baselineSTACItemID}>
                <Typography noWrap align={"left"} variant="body2" color="text.secondary">
                  {t('components.alerts.alertCard.baselineScene')}: {eventDetails.baselineSTACItemID}
                </Typography>
              </Tooltip >
            }
          </CardContent>
        </Card>
      }
    </>
  )
}

export default AlertCard;