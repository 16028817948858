import { Box, Button, Tooltip } from "@mui/material";
import { useWindowDimensions } from "@opt/core";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import React, { useState } from "react";
import BaseInspectionLayout from "./BaseInspectionLayout";
import InspectionList from "./InspectionList";
import OlMap from "ol/Map";
import InspectionAOIDetails from "./InspectionAOIDetails";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type InspectionViewProps = {
  showInspectionID?: string | undefined,
  datasetID?: string
}

const InspectionView: React.FC<InspectionViewProps> = ({ showInspectionID, datasetID }) => {

  const CreateDetails = (height: number, map: OlMap | undefined, inspectionsLayer: VectorLayer<VectorSource> | undefined) => {
    return <Box sx={{ maxHeight: height - 100, height: height - 100, scrollBehavior: 'smooth' }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Tooltip title={t('pages.inspections.backTooltip')} placement="top">
          <Button size="small" onClick={() => navigate("/inspections")}>
            <ArrowBackIcon />
            {t('pages.executions.goBack')}
          </Button>
        </Tooltip>
      </Box>
      <InspectionAOIDetails />
      <InspectionList map={map} inspectionsLayer={inspectionsLayer} datasetID={datasetID} />
    </Box>
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [map, setMap] = useState<OlMap>();
  const [inspectionsLayer, setInspectionsLayer] = useState<VectorLayer<VectorSource> | undefined>();
  const { height } = useWindowDimensions();
  const details = CreateDetails(height, map, inspectionsLayer);

  return (
    <BaseInspectionLayout
      details={details}
      showInspectionID={showInspectionID}
      datasetID={datasetID}
      onMapCreated={setMap}
      onInspectionsLayerCreated={setInspectionsLayer} />
  )
}

export default InspectionView;