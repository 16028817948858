import { useCallback, useState } from "react";


const useStack = <T>(initialList: T[]): {
  clear: () => void;
  isEmpty: () => boolean;
  peek: () => T | undefined;
  pop: () => T | undefined;
  push: (item: T) => number;
} => {
  const [list, setList] = useState<T[]>([...initialList]);

  const push = useCallback((item: T) => {
    list.push(item);
    setList([...list]);

    return list.length;
  },
    [list]
  );

  const pop = useCallback(() => {
    if (list.length > 0) {
      const lastItem = list[list.length - 1];
      setList([...list.slice(0, list.length - 1)]);

      return lastItem;
    }

    return undefined;
  }, [list]);

  const peek = useCallback(() => {
    if (list.length > 0) {
      return list[list.length - 1];
    }

    return undefined;
  }, [list]);

  const clear = () => {
    list.splice(0, list.length);
    setList([...list]); 
  }

  const isEmpty = useCallback(() => list.length === 0, [list]);

  const controls = {
    clear,
    isEmpty,
    peek,
    pop,
    push,
  };

  return controls;
}

export default useStack;