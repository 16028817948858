import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import React from "react";

interface DeleteDialogProps {
  showDialog: boolean,
  confirmation: (confirmation: boolean) => void
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({showDialog, confirmation: dialogResponse}) => {

  const handleConfirm = () => {
    dialogResponse(true);
  };

  const handleCloseOrCancel = () => {
    dialogResponse(false);
  };

  return (
    <>
      <Dialog
        open={showDialog}
        onClose={handleCloseOrCancel}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          {"Confirmação de exclusão de registro."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você confirma a exclusão deste registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseOrCancel}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDialog;