export class TableMetadataSymbol {

  public id: string | undefined;
  public fillColor: string | undefined;
  public strokeColor: string | undefined;
  public strokeWidth: number | undefined;
  public imageRadius: number | undefined;
  public styleFile: string | undefined;

  constructor() {
  }
}