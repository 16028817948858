
export class RasterView {
  public name: string;
  public algorithm: string;
  public sensorID: string;
  public queryString: string;
  public description: string;
  public useBaseline: boolean;
  public perTenant: boolean;
  public swipeTo: string;

  constructor() {
    this.name = "";
    this.algorithm = "";
    this.sensorID = "";
    this.queryString = "";
    this.description = "";
    this.useBaseline = false;
    this.perTenant = false;
    this.swipeTo = "";
  }
}
