import { GeometryType, ITableSLDStyle } from "@opt/core";
import { TableMetadata } from "@opt/core";
import { FeatureLike } from "ol/Feature";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style, { StyleLike } from "ol/style/Style";
import { LayerStyling } from "./LayerStyling";
import { ColorLike } from "ol/colorlike";
import Text from "ol/style/Text";
import SLDReader from "@nieuwlandgeo/sldreader";

export class StyleBuilder {

  private static TEXT_MAX_LENGTH = 18;

  private readonly _styleDefinition: LayerStyling;

  constructor(styleDefinition: LayerStyling) {
    this._styleDefinition = styleDefinition;
  }

  private static defaultFill = new Fill({
    color: 'rgba(255, 0, 255, 0)'
  });

  private static defaultStroke = new Stroke({
    color: 'rgba(204, 0, 204, 1)',
    width: 2
  });

  public createPolygonStyle(fillColor: string, strokeColor: string, strokeWidth: number): StyleLike {
    const styleFunc = (feature: FeatureLike | undefined, resolution: number): Style => {
      const style: Style = new Style({
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
        text: (this._styleDefinition.textEnabled && !this._styleDefinition.textTableID) ?
          StyleBuilder.createTextStyle(feature, resolution, this._styleDefinition) :
          undefined
      });

      return style;
    }

    return styleFunc;
  }

  public createLineStyle(strokeColor: string, strokeWidth: number) {
    const styleFunc = (feature: FeatureLike | undefined, resolution: number): Style => {
      const style: Style = new Style({
        stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
        text: (this._styleDefinition.textEnabled && !this._styleDefinition.textTableID) ?
          StyleBuilder.createTextStyle(feature, resolution, this._styleDefinition) :
          undefined
      });

      return style;
    }

    return styleFunc;
  }

  public createPointStyle(fillColor: string, strokeColor: string, strokeWidth: number, radius: number) {
    const styleFunc = (feature: FeatureLike | undefined, resolution: number): Style => {
      const style: Style = new Style({
        image: new Circle({
          fill: new Fill({ color: fillColor }),
          stroke: new Stroke({ color: strokeColor, width: strokeWidth }),
          radius: radius
        }),
        text: (this._styleDefinition.textEnabled && !this._styleDefinition.textTableID) ?
          StyleBuilder.createTextStyle(feature, resolution, this._styleDefinition) :
          undefined
      });

      return style;
    }

    return styleFunc;
  }

  public static FromTableMetadata(table: TableMetadata): Style {

    if (table.styleFile) {
      return StyleBuilder.fromSLD(table);
    }
    else {
      const hasFill = !(table.geometryType === GeometryType.LineString || table.geometryType === GeometryType.MultiLineString);

      const fill = table.fillColor ? new Fill({
        color: hasFill ? table.fillColor : table.strokeColor
      }) : StyleBuilder.defaultFill;

      const stroke = table.strokeColor ? new Stroke({
        color: table.strokeColor,
        width: table.strokeWidth ?? 2
      }) : StyleBuilder.defaultStroke;

      const image = new Circle({
        fill: fill,
        stroke: stroke,
        radius: table.imageRadius ?? 3,
      })

      const style: Style = new Style({
        image: image,
        fill: fill,
        stroke: stroke,
      });

      return style;
    }
  }

  private static truncText(text: string) {
    return text.length > StyleBuilder.TEXT_MAX_LENGTH ? text.substring(0, StyleBuilder.TEXT_MAX_LENGTH - 1) + '...' : text;
  }

  private static stringDivider(str: string, width: number, spaceReplacer: string): string {
    if (str.length > width) {
      let p = width;
      while (p > 0 && str[p] != ' ' && str[p] != '-') {
        p--;
      }
      if (p > 0) {
        let left;
        if (str.substring(p, p + 1) == '-') {
          left = str.substring(0, p + 1);
        } else {
          left = str.substring(0, p);
        }
        const right = str.substring(p + 1);
        return left + spaceReplacer + StyleBuilder.stringDivider(right, width, spaceReplacer);
      }
    }
    return str;
  }

  private static getText = (feature: FeatureLike | undefined, resolution: number, maxResolution: number, expression: string) => {
    if (resolution > maxResolution) {
      return undefined;
    } else {
      const reg = /\${(.*?)\}/g
      const matchs = expression.match(reg);

      if (!matchs) return undefined;

      let text = expression;
      for (const match of matchs) {
        const property = match.substring(2, match.length - 1);
        const value = feature?.get(property) ?? "";
        text = text.replace(match, value);
      }

      //return text.length > StyleBuilder.TEXT_MAX_LENGTH ? text.substring(0, StyleBuilder.TEXT_MAX_LENGTH - 1) + '...' : text;
      return StyleBuilder.stringDivider(text, 16, '\n');
    }
  }

  private static createTextStyle = (feature: FeatureLike | undefined, resolution: number, table: LayerStyling) => {

    const maxResolution: number = table.textMaxResolution ?? 0;
    const fillColor: ColorLike = table.textColor ?? "#000000";
    const outlineColor: ColorLike = table.textBackgroundColor ?? "#FFFFFF"
    const outlineWidth: number = table.textBackgroundWidth ?? 3;

    const style = new Text({
      font: `${table.textFontSize ?? 11}px sans-serif`,
      text: StyleBuilder.getText(feature, resolution, maxResolution, table.textExpression ?? ""),
      fill: new Fill({ color: fillColor }),
      stroke: new Stroke({ color: outlineColor, width: outlineWidth })
    });

    if (table.geometryType === GeometryType.Point) {
      style.setOffsetY(-((table.imageRadius ?? 5) * 2));
    }
    else if (table.geometryType === GeometryType.LineString || table.geometryType === GeometryType.MultiLineString) {
      style.setPlacement("line");
    }

    return style;
  }

  public static textStyleFromLayerStyling(table: LayerStyling): StyleLike {

    const styleFunc = (feature: FeatureLike | undefined, resolution: number): Style => {
      const style: Style = new Style({
        // image: new Circle({
        //   fill: StyleBuilder.defaultFill,
        //   stroke: StyleBuilder.defaultStroke,
        //   radius: table.imageRadius ?? 3,
        // }),
        text: StyleBuilder.createTextStyle(feature, resolution, table)
      });

      return style;
    }

    return styleFunc;
  }

  private static fromSLD(sld: ITableSLDStyle) {
    const sldObject = SLDReader.Reader(sld.styleFile);
    const sldLayer = SLDReader.getLayer(sldObject);
    const style = sldLayer.styles[0];
    const featureTypeStyle = style.featuretypestyles[0];
    const result = SLDReader.createOlStyleFunction(featureTypeStyle, {});
    return result;
  }

  public static fromLayerStyling(table: LayerStyling): StyleLike {

    if (table.styleType === "SLD") {
      return StyleBuilder.fromSLD(table);
    }
    else if (table.styleType === "FUNCTION") {
      const styleFunc = StyleBuilder.createStyleFunction(table);
      return styleFunc;
    }

    const fill = table.fillColor ? new Fill({
      color: table.fillColor
    }) : StyleBuilder.defaultFill;

    const stroke = table.strokeColor ? new Stroke({
      color: table.strokeColor,
      width: table.strokeWidth ?? 2
    }) : StyleBuilder.defaultStroke;

    const image = new Circle({
      fill: fill,
      stroke: stroke,
      radius: table.imageRadius ?? 3,
    });

    const styleFunc = (feature: FeatureLike | undefined, resolution: number): Style | Style[] => {
      if (table.textEnabled && !table.textTableID) {
        const style: Style = new Style({
          image: image,
          fill: fill,
          stroke: stroke,
          text: StyleBuilder.createTextStyle(feature, resolution, table)
        });

        return style;
      } else {
        const style: Style = new Style({
          image: image,
          fill: fill,
          stroke: stroke
        });

        return style;
      }
    }

    return styleFunc;
  }

  private static createStyleFunction(styleDefinition: LayerStyling) {
    const funcCode: string = styleDefinition.styleFunction as string;
    const builder = new StyleBuilder(styleDefinition);
    const func = new Function("return " + funcCode)();

    return func(builder) as (feature: FeatureLike, resolution: number) => Style;
  }
}