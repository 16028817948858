import { Box, Typography } from "@mui/material";
import { Form, Submission } from "@tsed/react-formio";
import React, { useEffect, useState } from "react";
import { TableMetadata } from "@opt/core";
import { useNotifyStore } from "../Notifyer";
import { useDynamicFormsStore } from "./DynamicStore";

interface DynamicFormProps {
  onSave?: (model: object) => void;
  metadata: TableMetadata | undefined,
  forUpdate?: boolean,
  forReadOnly: boolean,
  model: object,
  hideMap?: boolean,
  relatedTableID?: string,
  relatedModelID?: string
}

const DynamicForm: React.FC<DynamicFormProps> = (
  {
    onSave,
    metadata,
    forUpdate,
    forReadOnly,
    model,
    hideMap,
    relatedTableID,
    relatedModelID
  }) => {

  const { createModel, updateModel } = useDynamicFormsStore();
  const { addMsg } = useNotifyStore();
  const [currentForm, setCurrentForm] = useState<any>(null);
  const [components, setComponents] = useState<any>(null);

  useEffect(() => {
    const comp = metadata?.getDefaultForm()?.getComponents(hideMap);
    setComponents(comp);
  }, [metadata])

  useEffect(() => {
    if (!currentForm || !model || !components) return;

    currentForm.formReady
      .then(() => {
        for (var key in model) {
          currentForm.getComponent(key)?.setValue(Reflect.get(model, key));
        }

        if (forUpdate) {
          const idField = metadata?.getKeyAttribute();
          const id = currentForm.getComponent(idField).id;
          const input = document.getElementById(`${id}-${idField}`);
          input?.setAttribute("disabled", "true");
        }

        const focus = getFocusFirstComponent(currentForm.components, forUpdate ? metadata?.getKeyAttribute() : undefined);

        setTimeout(() => {
          currentForm.focusOnComponent(focus);
        }, 200);
      });
  }, [components, currentForm, model, forUpdate])

  const getFocusFirstComponent = (components: any[], idField: string | undefined): string | undefined => {
    for (const comp of components) {
      if (comp._visible && !comp._disabled) {
        if (comp.type === 'components') {
          return getFocusFirstComponent(comp.components, idField)
        }
        else if (comp.key === idField) {
          continue;
        }
        else {
          return comp.key;
        }
      }
    }
  }

  const handleSubmit = (submit: Submission<any>) => {
    const { data } = submit;
    delete data["submit"];

    if (forUpdate) {
      updateModel(data, relatedTableID, relatedModelID)
        .then(m => {
          addMsg("success", "Registro alterado com sucesso.")
          if (onSave) onSave(m);
        })
        .catch(e => {
          if (e?.remote?.hasErrors) {
            const msg = e.remote.errors.join('\n');
            addMsg("error", `Não foi possível atualizar registro.\n${msg}`);
          }
        });
    }
    else {
      createModel(data, relatedTableID, relatedModelID)
        .then(m => {
          addMsg("success", "Registro criado com sucesso.")
          if (onSave) onSave(m);
        })
        .catch(e => {
          if (e?.remote?.hasErrors) {
            const msg = e.remote.errors.join('\n');
            addMsg("error", `Não foi possível criar registro.\n${msg}`);
          }
        });
    }
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          {metadata?.label}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          {forReadOnly ? 'Detalhes do registro' : forUpdate ? 'Editar registro' : 'Novo registro'}
        </Typography>
      </Box>
      {components &&
        <Form form={{ display: "wizard", components: components }}
          onSubmit={handleSubmit}
          onFormReady={(form) => setCurrentForm(form)}
          options={{ readOnly: forReadOnly }}></Form>
      }
      {!components &&
        <Typography marginTop="30px" textAlign="center" color="red">Nenhum formulário configurado.</Typography>
      }
    </>
  )
}

export default DynamicForm;