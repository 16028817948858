import { DataAPI, DataFetchAPI, DataQueryAPI, QueryFilter, TableMetadataSearch } from "@opt/core";
import { create } from "zustand";
import { Config } from "../../config";
import { FieldMetadata } from "@opt/core";
import { FormsMetadata } from "@opt/core";
import { TableMetadata } from "@opt/core";
import { TableMetadataSymbol } from "@opt/core";

interface MetadataState {
  tableMetadata: TableMetadata[];
  lastQueryFilterTableMetadata: QueryFilter;
  currentTableMetadata: TableMetadata | undefined;
  fieldsMetadata: FieldMetadata[];
  lastQueryFilterFieldMetadata: QueryFilter;
  formsMetadata: FormsMetadata[];
  lastQueryFilterFormsMetadata: QueryFilter;

  fetchAllTableMetadata: () => Promise<TableMetadata[] | undefined>,
  fetchListTableMetadata: (ids: string[]) => Promise<TableMetadata[] | undefined>,
  fetchTableMetadata: (modelID: string) => Promise<TableMetadata | undefined>,
  queryTableMetadata: (filter: QueryFilter) => void,
  createTableMetadata: (model: TableMetadata) => Promise<TableMetadata>,
  updateTableMetadata: (model: TableMetadata) => Promise<TableMetadata>,
  deleteTableMetadata: (modelID: string) => Promise<any>,

  queryFieldMetadata: (filter: QueryFilter) => void,
  createFieldMetadata: (model: FieldMetadata) => Promise<FieldMetadata>,
  updateFieldMetadata: (model: FieldMetadata) => Promise<FieldMetadata>,
  deleteFieldMetadata: (modelID: string) => Promise<any>,
  updateTableMetadataSymbol: (model: TableMetadataSymbol) => Promise<TableMetadataSymbol>,
  updateTableMetadataSearch: (model: TableMetadataSearch) => Promise<TableMetadataSearch>,

  queryFormsMetadata: (filter: QueryFilter) => void,
  createFormsMetadata: (model: FormsMetadata) => Promise<FormsMetadata>,
  updateFormsMetadata: (model: FormsMetadata) => Promise<FormsMetadata>,
  deleteFormsMetadata: (modelID: string) => Promise<any>
}

export const useMetadataStore = create<MetadataState>((set, get) => ({
  tableMetadata: [],
  lastQueryFilterTableMetadata: new QueryFilter(),
  currentTableMetadata: undefined,
  fieldsMetadata: [],
  lastQueryFilterFieldMetadata: new QueryFilter(),
  formsMetadata: [],
  lastQueryFilterFormsMetadata: new QueryFilter(),

  fetchAllTableMetadata: async () => {
    const { doFetchMany } = DataFetchAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata/all`);
    const result = await doFetchMany();

    set({ tableMetadata: result ?? [] });

    return result;
  },

  fetchListTableMetadata: async (ids: string[]) => {
    const listIDs = ids.join(',');
    const { doFetchMany } = DataFetchAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata/list?ids=${listIDs}`);
    const result = await doFetchMany();

    set({ tableMetadata: result ?? [] });

    return result;
  },

  fetchTableMetadata: async (modelID: string) => {
    const { doFetch } = DataFetchAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata`);
    const result = await doFetch(modelID);

    set({ currentTableMetadata: result });

    return result;
  },

  queryTableMetadata: async (filter: QueryFilter) => {
    const { doQuery } = DataQueryAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata`);
    const query = await doQuery(filter);

    set({ tableMetadata: query?.items, lastQueryFilterTableMetadata: query?.queryFilter });
  },

  createTableMetadata: async (model: TableMetadata): Promise<TableMetadata> => {
    const { doCreate } = DataAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata`);
    const result = await doCreate(model);

    set({ currentTableMetadata: result });

    return result;
  },

  updateTableMetadata: async (model: TableMetadata): Promise<TableMetadata> => {
    const { doUpdate } = DataAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata`);
    const result = await doUpdate(model);

    set({ currentTableMetadata: result });

    return result;
  },

  deleteTableMetadata: async (modelID: string): Promise<any> => {
    const { doDelete } = DataAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata`);
    const result = await doDelete(modelID);

    set({ currentTableMetadata: undefined });

    return result;
  },

  updateTableMetadataSymbol: async (model: TableMetadataSymbol): Promise<TableMetadataSymbol> => {
    const { doUpdate } = DataAPI<TableMetadataSymbol>(TableMetadataSymbol, `${Config.Instance.apiEndpoint}/metadata/symbol`);
    const result = await doUpdate(model);

    const current = get().currentTableMetadata?.clone() as any;
    Object.assign(current, result);

    set({ currentTableMetadata: current });

    return result;
  },

  updateTableMetadataSearch: async (model: TableMetadataSearch): Promise<TableMetadataSearch> => {
    const { doUpdate } = DataAPI<TableMetadataSearch>(TableMetadataSearch, `${Config.Instance.apiEndpoint}/metadata/search`);
    const result = await doUpdate(model);

    const current = get().currentTableMetadata?.clone() as TableMetadata;
    current.displayField = result.displayField;
    current.searchColumns = result.searchColumns;

    set({ currentTableMetadata: current });

    return result;
  },

  queryFieldMetadata: async (filter: QueryFilter) => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doQuery } = DataQueryAPI<FieldMetadata>(FieldMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/attributes`);
    const query = await doQuery(filter);

    set({ fieldsMetadata: query?.items, lastQueryFilterFieldMetadata: query?.queryFilter });
  },

  createFieldMetadata: async (model: FieldMetadata): Promise<FieldMetadata> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doCreate } = DataAPI<FieldMetadata>(FieldMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/attributes`);
    const result = await doCreate(model);

    return result;
  },

  updateFieldMetadata: async (model: FieldMetadata): Promise<FieldMetadata> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doUpdate } = DataAPI<FieldMetadata>(FieldMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/attributes`);
    const result = await doUpdate(model);

    return result;
  },

  deleteFieldMetadata: async (modelID: string): Promise<any> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doDelete } = DataAPI<TableMetadata>(TableMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/attributes`);
    const result = await doDelete(modelID);

    return result;
  },

  queryFormsMetadata: async (filter: QueryFilter) => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doQuery } = DataQueryAPI<FormsMetadata>(FormsMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/forms`);
    const query = await doQuery(filter);

    set({ formsMetadata: query?.items, lastQueryFilterFormsMetadata: query?.queryFilter });
  },

  createFormsMetadata: async (model: FormsMetadata): Promise<FormsMetadata> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doCreate } = DataAPI<FormsMetadata>(FormsMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/forms`);
    const result = await doCreate(model);

    return result;
  },

  updateFormsMetadata: async (model: FormsMetadata): Promise<FormsMetadata> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doUpdate } = DataAPI<FormsMetadata>(FormsMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/forms`);
    const result = await doUpdate(model);

    return result;
  },

  deleteFormsMetadata: async (modelID: string): Promise<any> => {
    const tableMetadataID = get().currentTableMetadata?.id;
    const { doDelete } = DataAPI<FormsMetadata>(FormsMetadata, `${Config.Instance.apiEndpoint}/metadata/${tableMetadataID}/forms`);
    const result = await doDelete(modelID);

    return result;
  },

}));

export default useMetadataStore;