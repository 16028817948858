
export class Config {

  private static _instance: Config;

  public apiEndpoint: string | undefined;

  private constructor() {

  }

  public static get Instance(): Config {
    if (!Config._instance) {
      Config._instance = new Config();
    }

    return Config._instance;
  }
}