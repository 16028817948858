import "ol/ol.css";
import "../ol.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import OlMap from "ol/Map";
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import OlSourceOSM from "ol/source/OSM";
import { ScaleLine, ZoomSlider, FullScreen, Attribution, defaults as defaultControls } from 'ol/control';

type MapContext = {
  map: OlMap
};

export const MapContextProvider = React.createContext<MapContext | undefined>(undefined);

export const useMapContext = () => useContext(MapContextProvider);

type MapLayersProps = {
  children: React.ReactNode
};

export const MapLayers = ({ children }: MapLayersProps) => { return <>{children}</>; };

type MapProps = {
  children: React.ReactNode;
  mapHeight: string | undefined;
  onMapCreated?: (map: OlMap) => void;
};

const AppMap: React.FC<MapProps> = ({ mapHeight, children, onMapCreated }): JSX.Element => {

  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<OlMap>();
  const [mapContext, setMapContext] = useState<MapContext>();

  useEffect(() => {

    const olmap = new OlMap({
      controls: defaultControls({ attribution: false }).extend([new ScaleLine({
        units: 'metric',
      }),
      new ZoomSlider(),
      new FullScreen(),
      new Attribution({ collapsible: true, collapsed: true })]),
      layers: [
        new OlLayerTile({
          source: new OlSourceOSM({ cacheSize: 1000 })
        })
      ],
      view: new OlView({ zoom: 5, maxZoom: 20, center: [-5793553, -1835852] }),
      target: mapRef.current as HTMLElement
    });

    if (onMapCreated !== undefined) onMapCreated(olmap);

    setMap(olmap);
    setMapContext({ map: olmap });

    return () => olmap.setTarget(undefined);
  }, []);

  useEffect(() => {
    map?.updateSize();
  }, [mapHeight])

  return (
    <MapContextProvider.Provider value={mapContext}>
      <div ref={mapRef} style={{ width: "100%", height: mapHeight ?? "100%" }}>
        {children}
      </div>
    </MapContextProvider.Provider>
  )
}

export default AppMap;