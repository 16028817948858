
interface SubscriptionInternal<T> {
  onNext?: (item: T) => void;
}

interface Subscription {
  unsubscribe(): void;
}

export class PropObservable<T> {
  private readonly subscribers: Array<SubscriptionInternal<T>> = [];

  lastItem: any;

  triggerNext(item: T) {
    this.lastItem = item;
    this.subscribers.forEach((sub) => sub.onNext && sub.onNext(item));
  }

  subscribe(
    onNext?: (item: T) => void
  ): Subscription {
    const subInternal: SubscriptionInternal<T> = {
      onNext
    };

    this.subscribers.push(subInternal);

    return {
      unsubscribe: () => {
        const index = this.subscribers.indexOf(subInternal);

        if (index !== -1) {
          this.subscribers.splice(index, 1);
        }
      },
    };
  }
}