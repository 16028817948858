import { Box, Button, Typography } from "@mui/material";
import { TransferList } from "@opt/ui-core";
import { TransferListItem } from "@opt/ui-core/src/components/TransferList";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdmStore } from "../../components/admin/AdmStore";
import { useNotifyStore } from "@opt/ui-core";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  }
}

const UserTenantAdminPage: React.FC = () => {

  const { userID } = useParams<string>();
  const { fetchTenantUserAccess, saveTenantAccess } = useAdmStore();
  const [allTenants, setAllTenants] = useState<TransferListItem[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<TransferListItem[]>([]);
  const [selected, setSelecteds] = useState<TransferListItem[]>([]);
  const { addMsg } = useNotifyStore();

  useEffect(() => {
    if (!userID) return;

    fetchTenantUserAccess(userID).then(x => {
      const all = x?.map(item => { return { id: item.tenantID, description: item.description } });
      const selected = x?.filter(x => x.hasAccess).map(item => { return { id: item.tenantID, description: item.description } });

      setAllTenants(all ?? []);
      setSelectedTenant(selected ?? []);
    });
  }, []);

  const handleSave = () => {
    const tenants = selected.map(x => x.id);
    saveTenantAccess(userID as string, tenants).then(x => {
      addMsg("success", "Acessos atualizados com sucesso.");
    })
      .catch(e => {
        addMsg("error", "Não foi possível atualizar os acessos.");
      })
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Tenants - Usuário: {userID}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Tenants de dados que o usuário {userID} possui acesso
        </Typography>
      </Box>
      <Box sx={{ padding: "20px" }}>
        <TransferList allItems={allTenants} selectedItems={selectedTenant} width={400} height={500} onChange={(value) => setSelecteds(value)} />
      </Box>
      <Button size="small" variant="contained" onClick={handleSave}>Salvar</Button>
    </>
  )
}

export default UserTenantAdminPage;