import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled, Typography } from "@mui/material";
import { Geometry, MultiPolygon, Polygon } from "ol/geom";
import React, { useEffect, useRef, useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { Control } from "ol/control";
import classNames from "classnames";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Feature } from "ol";
import { calculateExtent } from "@opt/mapping";
import OlMap from 'ol/Map';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from "react-i18next";

const ControlDiv = styled('div')({
  position: "absolute",
  top: '7px',
  zIndex: 100,
  right: '40px'
});

interface AlertsNavigatorProps {
  map: OlMap | undefined;
  alertLayer: VectorLayer<VectorSource> | undefined
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const AlertsNavigator: React.FC<AlertsNavigatorProps> = ({ map, alertLayer }) => {

  const { t } = useTranslation();
  const [geometries, setGeometries] = useState<{ name: string; geometry: Polygon; visited: boolean }[]>([]);
  const [open, setOpen] = React.useState(false);
  const listAlertRef = useRef<HTMLDivElement>(null);
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>(undefined);

  useEffect(() => {

    if (!alertLayer || !map) return;

    const addSource = async () => {
      while (alertLayer.getSource()?.getFeatures().length === 0) {
        await sleep(1000);
      }

      const geoms: any = [];
      const multipolygon = alertLayer.getSource()?.getFeatures()[0].getGeometry() as MultiPolygon;

      const polyPrefix = t('components.alerts.alertsNavigator.area');
      let polyIndex = 1;

      multipolygon.getPolygons().forEach(polygon => {
        geoms.push({ name: `${polyPrefix} ${polyIndex++}`, geometry: polygon, visited: false });
      });

      setGeometries(geoms);
    }

    addSource();

    const source = new VectorSource({ wrapX: false });
    setVectorSource(source);

    const style = new Style({
      stroke: new Stroke({
        color: "rgba(255,255,0,1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,255,0,0)",
      }),
    });

    new VectorLayer({
      source: source,
      style: style,
      map: map
    });

    const control = new Control({ element: listAlertRef.current as HTMLElement });
    map.addControl(control);

    return () => {
      map.removeControl(control);
    }
  }, [map, alertLayer]);

  useEffect(() => {
    if (!open) {
      vectorSource?.clear();
    }
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
    setSelectedItemIndex(undefined);
  };

  const handleClickGeometry = (geometry: Polygon) => {
    const feature = new Feature({ geometry: geometry.clone() });
    const viewExtent = calculateExtent(feature, 300);

    vectorSource?.clear();
    vectorSource?.addFeature(new Feature(geometry));

    if (viewExtent) {
      map?.getView().fit(viewExtent);
    }
  }

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    return (
      <>
        <ListItemButton style={style} key={index} sx={{ pl: 4 }} onClick={() => {
          geometries[index].visited = true;
          handleClickGeometry(geometries[index].geometry);
          setSelectedItemIndex(index);
        }}>
          <ListItemIcon sx={{ minWidth: "35px", maxWidth: "35px" }}>
            <CenterFocusWeakIcon />
          </ListItemIcon>
          <ListItemText secondary={geometries[index].name}
            sx={{
              '.MuiListItemText-secondary': {
                fontWeight: selectedItemIndex === index ? "bold" : "normal"
              }
            }} />
          {geometries[index].visited &&
            <ListItemIcon sx={{ minWidth: "35px", maxWidth: "35px" }}>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
          }
        </ListItemButton>
      </>
    );
  }

  return (
    <ControlDiv ref={listAlertRef} className={classNames("ol-control")} >
      <List
        sx={{ width: 220, maxWidth: 270, maxHeight: 370, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        dense
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <FormatListNumberedIcon />
          </ListItemIcon>
          <ListItemText secondary={t('components.alerts.alertsNavigator.title')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <FixedSizeList
            height={270}
            width={220}
            itemSize={25}
            itemCount={geometries.length}
            overscanCount={100}
          >
            {renderRow}
          </FixedSizeList>
        </Collapse>
      </List>
      {!open &&
        <Typography gutterBottom variant="body2" component="div" color="primary" bgcolor={"white"} margin={0} fontSize={12}>
          {geometries.length === 1 ? "1 alerta" : geometries.length + " alertas"}
        </Typography>
      }
    </ControlDiv>
  )
}

export default AlertsNavigator;