import {
  Box, Button, IconButton, Slide, Stack, Step, StepLabel, Stepper,
  styled, Tooltip, Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useExecutionStore } from "../components/execution/ExecutionStore";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@opt/ui-core";
import { FormatDecimalNumber, QueryFilter } from "@opt/core";
import { DatasetFilter } from "../models/DatasetFilter";
import { useTranslation } from "react-i18next";
import SlideshowIcon from '@mui/icons-material/Slideshow';

const Root = styled('div')({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  height: "100%"
});

const AlertBox = styled('div')({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center"
});

const styles = {
  stepper: {
    maxHeight: "80px",
    minHeight: "80px",
    padding: "20px"
  },
  alertTitle: {
    paddingLeft: "20px"
  }
}

const AlertsPage: React.FC = (): JSX.Element => {

  const { t } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'datasetName',
      headerName: `${t('pages.executions.gridCols.dataset')}`,
      width: 180,
      editable: false,
      filterable: false
    },
    {
      field: 'algorithmShortDescription',
      headerName: `${t('pages.executions.gridCols.algorithm')}`,
      width: 180,
      editable: false,
      filterable: false
    },
    {
      field: 'projectID',
      headerName: `${t('pages.executions.gridCols.projectID')}`,
      width: 400,
      editable: false,
      filterable: false
    },
    {
      field: 'parcelID',
      headerName: `${t('pages.executions.gridCols.parcelID')}`,
      width: 100,
      editable: false,
      filterable: false,
      align: "center"
    },
    {
      field: 'alertArea',
      headerName: `${t('pages.executions.gridCols.alertArea')}`,
      width: 150,
      editable: false,
      filterable: false,
      align: "right",
      valueFormatter: params =>
        FormatDecimalNumber(params.value)
    },
    {
      field: 'aoiArea',
      headerName: `${t('pages.executions.gridCols.aoiArea')}`,
      width: 120,
      editable: false,
      filterable: false,
      align: "right",
      valueFormatter: params =>
        FormatDecimalNumber(params.value)
    },
    {
      field: "action",
      headerName: "",
      width: 60,
      sortable: false,
      editable: false,
      filterable: false,
      resizable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return <Stack direction="row" spacing={2}>
          <Tooltip title={`${t('pages.executions.gotoDetails')}`}>
            <IconButton size="medium" onClick={() => navigate(`/alerts/${params.row?.eventID}`)}>
              <SlideshowIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    }
  ];

  const activeStep = 1;
  const navigate = useNavigate();
  const { executionID } = useParams<string>();

  const { filters, datasetFilter, algorithmFilter, startDate, endDate, getDatasetFilters, setFilters,
    alerts, alertsQueryFilter, queryEvents } = useExecutionStore();

  useEffect(() => {
    if (!executionID) return;

    if (!filters.length) {
      getDatasetFilters().then(filters => {
        queryEvents(executionID, alertsQueryFilter).then(events => {
          if (!events) return;

          const first = events[0];
          const ds = filters?.find(x => x.id === first.datasetID) as DatasetFilter;
          const algo = ds.algorithms.find(x => x.name === first.algorithmName);

          setFilters(ds, algo, startDate, endDate, true);
        });
      });
    } else {
      queryEvents(executionID, alertsQueryFilter);
    }
  }, [executionID])

  const onChangeFilters = (filter: QueryFilter) => {
    queryEvents(executionID as string, filter);
  }

  return (
    <>
      <Root>
        <Box sx={styles.stepper}>
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step key={"step_1"}>
              <StepLabel>{t('pages.executions.steps.step1')}</StepLabel>
            </Step>
            <Step key={"step_2"}>
              <StepLabel>{t('pages.executions.steps.step2')}</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Slide direction="left" in={activeStep === 1} mountOnEnter unmountOnExit>
          <Box>
            <AlertBox>
              <Tooltip title={t('pages.executions.backExecutionList')} placement="top">
                <Button size="small" onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                  {t('pages.executions.goBack')}
                </Button>
              </Tooltip>
              <Typography sx={styles.alertTitle} gutterBottom variant="h5" color="text.secondary">{t('pages.executions.alertsTitle')}</Typography>
            </AlertBox>
            <Typography gutterBottom variant="h6" color="text.secondary">{`${t('pages.executions.dataset')}: ${datasetFilter?.name} - ${t('pages.executions.algorithm')}: ${algorithmFilter?.shortDescription}`}</Typography>
            <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>
              <DataGrid
                height={"calc(100vh - 260px)"}
                dataRows={alerts}
                dataColumns={columns}
                queryFilter={alertsQueryFilter}
                rowID="eventID"
                onChangeFilters={onChangeFilters}
              />
            </Box>
          </Box>
        </Slide>
      </Root>
    </>
  )
}

export default AlertsPage;