import { Box, Typography } from "@mui/material";
import React from "react";
import { useCockpitStore } from "../CockpitStore";
import { useTranslation } from "react-i18next";

const InspectionsTotal: React.FC = () => {

  const { t } = useTranslation();
  const { numOfInspections } = useCockpitStore();

  return (
    <>
      <Box maxHeight={338} minHeight={338}>
        <Typography gutterBottom variant="h6" color="primary">
          {t('components.cockpit.cards.inspectionsTotal.title')}
        </Typography>
        <Typography gutterBottom variant="body2" color="primary">
          {t('components.cockpit.cards.inspectionsTotal.subtitle')}
        </Typography>
        <Typography variant="h1" color="secondary" marginTop="50px">
          {numOfInspections}
        </Typography>
      </Box>
    </>
  )
}

export default InspectionsTotal;