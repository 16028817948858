import React, { useEffect } from "react";
import {
  Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select,
  SelectChangeEvent, styled, Typography
} from "@mui/material";
import { AlgorithmFilter } from "../../models/AlgorithmFilter";
import { DatasetFilter } from "../../models/DatasetFilter";
import { useCockpitStore } from "./CockpitStore";
import { useUserAuthStore, useWindowDimensions } from "@opt/core";
import AlertsPerDataset from "./cards/AlertsPerDataset";
import AlertsPerDate from "./cards/AlertsPerDate";
import InspectionsPerStatus from "./cards/InspectionsPerStatus";
import AlertsTotal from "./cards/AlertsTotal";
import InspectionsTotal from "./cards/InspectionsTotal";
import InspectionsPerDate from "./cards/InspectionsPerDate";
import InspectionsPerType from "./cards/InspectionsPerType";
import { useTranslation } from "react-i18next";

const Root = styled('div')({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  height: "100%"
});

const CockpitView: React.FC = () => {

  const { t } = useTranslation();
  const { roles } = useUserAuthStore();
  const { filters, datasetFilter, algorithmFilter, filterLastDays, inspectionTypeFilter, inspectionTypes,
    getFilters, getInspectionTypes, setFilterInspectionType,
    setAlgorithmFilter, setDatasetFilter, setFilterLastDays,
    getNumOfExecution, getNumOfEvents, getSummaryPerDataset, getSummaryPerDate,
    getNumOfInspections, getSummaryInspectionsPerStatus,
    getSummaryInspectionsPerDate, getSummaryInspectionsPerType } = useCockpitStore();

  const { height } = useWindowDimensions();

  useEffect(() => {
    getFilters();
    getInspectionTypes();
  }, [])

  useEffect(() => {
    if (isAnalyst()) {
      getNumOfExecution(datasetFilter, algorithmFilter, filterLastDays);
      getNumOfEvents(datasetFilter, algorithmFilter, filterLastDays);
      getSummaryPerDataset(datasetFilter, algorithmFilter, filterLastDays);
      getSummaryPerDate(datasetFilter, algorithmFilter, filterLastDays);
    }

    if (isInspector()) {
      getNumOfInspections(datasetFilter, algorithmFilter, inspectionTypeFilter, filterLastDays);
      getSummaryInspectionsPerStatus(datasetFilter, algorithmFilter, inspectionTypeFilter, filterLastDays);
      getSummaryInspectionsPerDate(datasetFilter, algorithmFilter, inspectionTypeFilter, filterLastDays);
      getSummaryInspectionsPerType(datasetFilter, algorithmFilter, inspectionTypeFilter, filterLastDays);
    }
  }, [datasetFilter, algorithmFilter, filterLastDays, inspectionTypeFilter])

  const handleChangeDataset = (event: SelectChangeEvent) => {
    setDatasetFilter(event.target.value);
  }

  const handleChangeAlgorithm = (event: SelectChangeEvent) => {
    setAlgorithmFilter(event.target.value);
  }

  const handleChangeLastDays = (event: SelectChangeEvent) => {
    setFilterLastDays(Number(event.target.value));
  }

  const handleChangeInspectionType = (event: SelectChangeEvent) => {
    setFilterInspectionType(event.target.value);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const isAnalyst = () => {
    return roles?.some(x => x === "analyst");
  }

  const isInspector = () => {
    return roles?.some(x => x === "inspector");
  }

  return (
    <>
      <Root>
        <Box m={2}>
          <Typography gutterBottom variant="h5" color="primary">
            {t('components.cockpit.title')}
          </Typography>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
            <InputLabel id="filter-dataset-label">{t('components.cockpit.filters.dataset')}</InputLabel>
            <Select
              labelId="filter-dataset-label"
              id="filter-dataset"
              label={t('components.cockpit.filters.dataset')}
              value={datasetFilter}
              onChange={handleChangeDataset}
            >
              {filters.map((item: DatasetFilter) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {isAnalyst() &&
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <InputLabel id="filter-algorithm-label">{t('components.cockpit.filters.algorithm')}</InputLabel>
              <Select
                labelId="filter-algorithm-label"
                id="filter-algorithm"
                label={t('components.cockpit.filters.algorithm')}
                value={algorithmFilter}
                onChange={handleChangeAlgorithm}
              >
                {filters.flatMap((ds: DatasetFilter) => ds.algorithms)
                  .filter((value, index, self) => self.findIndex(i => i.name === value.name) === index)
                  .map((item: AlgorithmFilter) => (
                    <MenuItem key={item.name} value={item.name}>{item.shortDescription}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          }
          {isInspector() &&
            <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
              <InputLabel id="filter-inspection-label">{t('components.cockpit.filters.inspectionType')}</InputLabel>
              <Select
                labelId="filter-inspection-label"
                id="filter-inspection"
                label={t('components.cockpit.filters.inspectionType')}
                value={inspectionTypeFilter}
                onChange={handleChangeInspectionType}
              >
                <MenuItem value="ALL">{t('components.cockpit.filters.all')}</MenuItem>
                {inspectionTypes.map(item => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="filter-last-days-label">{t('components.cockpit.filters.lastDays')}</InputLabel>
            <Select
              labelId="filter-last-days-label"
              id="filter-last-days"
              label={t('components.cockpit.filters.lastDays')}
              value={filterLastDays.toString() ?? ""}
              onChange={handleChangeLastDays}
            >
              <MenuItem value={5}>{`5 ${t('components.cockpit.filters.days')}`}</MenuItem>
              <MenuItem value={15}>{`15 ${t('components.cockpit.filters.days')}`}</MenuItem>
              <MenuItem value={30}>{`30 ${t('components.cockpit.filters.days')}`}</MenuItem>
              <MenuItem value={60}>{`60 ${t('components.cockpit.filters.days')}`}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <Grid container
            spacing={1}
            m={1}
            sx={{ height: `${height - 230}px`, maxHeight: `${height - 230}px`, overflow: "auto" }}
            width="100%"
            paddingRight="25px"
            paddingBottom="10px">
            {isAnalyst() && <>

              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <AlertsTotal />
                </Item>
              </Grid>
              <Grid item xs={11} md={6} lg={4} >
                <Item>
                  <AlertsPerDataset />
                </Item>
              </Grid>
              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <AlertsPerDate />
                </Item>
              </Grid>
            </>}
            {isInspector() && <>
              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <InspectionsTotal />
                </Item>
              </Grid>
              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <InspectionsPerStatus />
                </Item>
              </Grid>
              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <InspectionsPerType />
                </Item>
              </Grid>
              <Grid item xs={11} md={6} lg={4}>
                <Item>
                  <InspectionsPerDate />
                </Item>
              </Grid>
            </>}
          </Grid>
        </Box>
      </Root>
    </>
  )
}

export default CockpitView;