
export class EventDetails {

  public id: string;
  public stacCatalogue: string;
  public stacItemID: string;
  public relatedAOI: string;
  public sensorID: string;
  public datasetID: string;
  public gridName: string;
  public algorithm: string;
  public algorithmShortDescription: string;
  public shapeArea: number;
  public executionDate: Date | undefined;
  public stacItemDate: Date | undefined;
  public baselineSTACItemID: string;
  public baselineSTACItemDate: Date | undefined;
  public archived: boolean | undefined;

  public constructor() {
    this.id = "";
    this.stacCatalogue = "";
    this.stacItemID = "";
    this.relatedAOI = "";
    this.sensorID = "";
    this.datasetID = "";
    this.gridName = "";
    this.algorithm = "";
    this.algorithmShortDescription = "";
    this.baselineSTACItemID = "";
    this.shapeArea = 0;
    this.executionDate = undefined;
    this.stacItemDate = undefined;
    this.baselineSTACItemDate = undefined;
  }
}