import { plainToClassFromExist } from "class-transformer";
import { useUserAuthStore } from "../auth/UserAuthStore";

export const PostAPI = <TModel>(typeFactory: new () => TModel, endpoint: string): {
  doPost: (model: TModel) => Promise<TModel>,
  doPostRaw: (data: any) => Promise<TModel>,
  doPostBlob: (model: TModel) => Promise<Blob | undefined>
} => {

  const doPost = async (model: TModel): Promise<TModel> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const jsonModel = JSON.stringify(model);

    const result = await fetch(endpoint, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      }),
      body: jsonModel
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to post data.");

        const text = await response.text();

        if (!text) return new typeFactory();

        const data = JSON.parse(text);

        return plainToClassFromExist(new typeFactory(), data);
      });

    return result;
  }

  const doPostRaw = async (data: any): Promise<TModel> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;

    const result = await fetch(endpoint, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      }),
      body: data
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to post data.");

        const text = await response.text();

        if (!text) return new typeFactory();

        const data = JSON.parse(text);

        return plainToClassFromExist(new typeFactory(), data);
      });

    return result;
  }

  const doPostBlob = async (model: TModel): Promise<Blob> => {

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const jsonModel = JSON.stringify(model);

    const result = await fetch(endpoint, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Content-Type': 'application/json'
      }),
      body: jsonModel
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to post data.");

        return response.blob();
      });

    return result;
  }

  return { doPost: doPost, doPostBlob: doPostBlob, doPostRaw: doPostRaw };
}
