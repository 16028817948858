import React, { useEffect, useRef, useState } from "react";
import OlMap from 'ol/Map';
import { Control } from "ol/control";
import { IconButton, Paper, Stack, styled, ToggleButton } from "@mui/material";
import ReactDOM from "react-dom";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { LayerDefinition } from "../LayerDefinition";
import VectorSource from "ol/source/Vector";
import { Draw, Modify, Snap } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DeleteIcon from '@mui/icons-material/Delete';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import UndoRedoEditor from "./UndoRedoEditor";
import DrawGeometryEditor from "./DrawGeometryEditor";
import { GeometryType } from "@opt/core";
import { Feature, MapBrowserEvent } from "ol";

//IoMagnetSharp
//TbAxisX

type EditorToolbarControlProps = {
  map: OlMap | undefined,
  layerDefinition: LayerDefinition | undefined,
  onDoneDrawing: (feature: Feature, updateMode: boolean) => void;
  onDelete: (feature: Feature) => void;
  signalClear: number
}

const StyledToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#697B84!important",
    backgroundColor: "#9DD2AF!important"
  }
});

const EditorToolbarControl: React.FC<EditorToolbarControlProps> = ({ map, layerDefinition, onDoneDrawing, onDelete, signalClear }) => {

  const contrlRef = useRef<HTMLDivElement>(document.createElement('div'));
  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [currentEditing, setCurrentEditing] = useState<Feature>();
  const [drawSource, setDrawSource] = useState<VectorSource>();
  const [drawer, setDrawer] = useState<Draw>();
  const [snap, setSnap] = useState<Snap>();
  const [modify, setModify] = useState<Modify>();
  const [selected, setSelected] = useState<Feature | undefined>(undefined);

  const { handleUndo, handleRedo, hasUndo, hasRedo, clearUndoRedo, pushUndo } = UndoRedoEditor(currentEditing, setCurrentEditing);
  const adder = DrawGeometryEditor(addMode, editMode, map, drawer, modify, snap, currentEditing, setCurrentEditing, pushUndo, clearUndoRedo);

  useEffect(() => {
    setSelectMode(false);
    setupAddMode(false);
    setupEditMode(false);
  }, [signalClear]);

  useEffect(() => {
    if (!map) return;

    const controlCard = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(controlCard);

    return () => {
      map.removeControl(controlCard);
    }
  }, [map]);

  useEffect(() => {
    if (!currentEditing) return;

    drawSource?.clear();
    drawSource?.addFeature(currentEditing);
    drawSource?.changed();
  }, [currentEditing])

  useEffect(() => {
    if (!map || !layerDefinition) return;

    const source = new VectorSource();
    setDrawSource(source);

    new VectorLayer({
      visible: true,
      source: source,
      map: map,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 179, 179, 0.5)'
        }),
        stroke: new Stroke({
          color: 'red',
          width: 2
        }),
      }),
    });
  }, [map, layerDefinition]);

  useEffect(() => {
    if (selectMode) {
      map?.on('click', onMapClicked);
    }
    else {
      map?.un('click', onMapClicked);
      drawSource?.clear();
      setSelected(undefined);
      setCurrentEditing(undefined);
      setupEditMode(false);
    }

    return () => {
      map?.un('click', onMapClicked);
    }
  }, [selectMode])

  const setupAddMode = (enable: boolean) => {
    if (enable) {
      const geomType = getGeometryType(layerDefinition?.geometryType as GeometryType);

      const draw = new Draw({ source: drawSource, type: geomType });
      setDrawer(draw);

      const modify = new Modify({ source: drawSource });
      setModify(modify);

      const snap = new Snap({ source: drawSource });
      setSnap(snap);
    }
    else {
      drawSource?.clear();
      drawSource?.changed();
    }

    setAddMode(enable);
  }

  const setupEditMode = (enable: boolean) => {
    if (enable) {
      const modify = new Modify({ source: drawSource });
      setModify(modify);

      const snap = new Snap({ source: drawSource });
      setSnap(snap);
    }
    else {
      setSelected(undefined);
      drawSource?.clear();
      drawSource?.changed();
    }

    setEditMode(enable);
  }

  const onMapClicked = (e: MapBrowserEvent<MouseEvent>) => {
    map?.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
      const definition = layer?.get("LAYER_DEFINITION") as LayerDefinition;

      if (definition && definition.key === layerDefinition?.key) {
        const clone = (feature as Feature).clone();
        setCurrentEditing(clone);
        setSelected(clone);
        drawSource?.clear();
        drawSource?.addFeature(clone);
        return;
      }
    });
  }

  const getGeometryType = (geomType: GeometryType): "Point" | "Polygon" | "LineString" => {
    if (geomType === GeometryType.Polygon || geomType === GeometryType.MultiPolygon) return "Polygon";
    if (geomType === GeometryType.LineString || geomType === GeometryType.MultiLineString) return "LineString";

    return "Point";
  }

  const handleDoneGeometry = () => {
    onDoneDrawing(currentEditing as Feature, editMode);
  }

  const Toolbar = () => {
    return ReactDOM.createPortal(
      <div style={{ top: "7px", left: "200px" }} className="ol-control">
        <Paper elevation={5} sx={{ height: "35px", padding: "5px" }}>
          <Stack direction="row">
            <StyledToggleButton className="styled-ol-control" disabled={editMode}
              value="check"
              selected={addMode}
              onChange={() => setupAddMode(!addMode)}
            >
              <AddIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton className="styled-ol-control" disabled={addMode}
              value="check"
              selected={selectMode}
              onChange={() => setSelectMode(!selectMode)}
            >
              <TouchAppIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton className="styled-ol-control" disabled={selected === undefined}
              value="check"
              selected={editMode}
              onChange={() => setupEditMode(!editMode)}
            >
              <EditIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton aria-label="undo" className="styled-ol-control"
              value="check"
              selected={hasUndo()}
              disabled={!hasUndo()}
              onClick={handleUndo}>
              <UndoIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton aria-label="redo" className="styled-ol-control"
              value="check"
              selected={hasRedo()}
              disabled={!hasRedo()}
              onClick={handleRedo}>
              <RedoIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton aria-label="delete" className="styled-ol-control"
              value="check"
              selected={selected !== undefined}
              disabled={selected === undefined}
              onClick={() => onDelete(currentEditing as Feature)}>
              <DeleteIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
            <StyledToggleButton aria-label="save" className="styled-ol-control"
              value="check"
              selected={currentEditing !== undefined}
              disabled={!currentEditing}
              onClick={handleDoneGeometry}>
              <DoneIcon sx={{ paddingBottom: "2px", marginLeft: "-1px" }} />
            </StyledToggleButton>
          </Stack>
        </Paper>
      </div>
      , contrlRef.current as Element
    )
  }

  return (
    <>
      <Toolbar></Toolbar>
    </>
  )
}

export default EditorToolbarControl;