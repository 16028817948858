import {
  Card, CardMedia, CardContent, Typography, Box, IconButton,
  OutlinedInput, InputAdornment, InputLabel, FormControl, LinearProgress
} from "@mui/material";
import styled from "@mui/styled-engine";
import React, { useEffect, useState } from "react";
import EastIcon from '@mui/icons-material/East';
import { Config } from '@opt/ui-core';
import Keycloak from 'keycloak-js';
import { useUserAuthStore } from "../UserAuthStore";
import {
  KeycloakConfig, LoginController, WelcomeConfig, restoreIDToken,
  restoreRefreshToken, restoreToken
} from "../LoginContext";

type ShowWelcomeProps = {
  config: KeycloakConfig
  keycloak: Keycloak,
  pageConfig?: WelcomeConfig,
  setController?: (controller: LoginController) => void;
}

const classes = {
  card: {
    maxWidth: "600px",
    padding: "40px"
  },
  cardBody: {
    padding: "40px!important"
  },
  actions: {
    justifyContent: "center"
  }
};

const StyledRoot = styled('div')({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  background: "radial-gradient(circle, rgba(183,218,86,1) 0%, rgba(105,123,132,1) 100%)"
});

const ShowWelcome: React.FC<ShowWelcomeProps> = ({ keycloak, config, pageConfig }) => {

  const [email, setEmail] = useState("");
  const [busy, setBusy] = useState(false);
  const { authenticated, setUserInfo, updateAccessToken } = useUserAuthStore();

  if (!authenticated) {
    keycloak.init({
      checkLoginIframe: false,
      refreshToken: restoreRefreshToken(),
      token: restoreToken(),
      idToken: restoreIDToken()
    })
      .then((status) => {
        if (status) {

          let roles: string[] = [];
          if (keycloak?.tokenParsed?.resource_access) {
            roles = roles = keycloak?.tokenParsed?.resource_access[config.clientID]?.roles;
          }

          const info = {
            authenticated: true,
            authorized: false,
            accessToken: keycloak.token,
            username: keycloak?.idTokenParsed?.preferred_username,
            email: keycloak?.idTokenParsed?.email,
            roles: roles
          };

          setUserInfo(info);

          const expTime = Math.round((keycloak?.tokenParsed?.exp ?? 0) + (keycloak?.timeSkew ?? 0) - new Date().getTime() / 1000) * 1000;

          setInterval(() => {
            keycloak.updateToken(30).then(() => {
              updateAccessToken(keycloak.token);
            });
          }, expTime);
        }
      }).catch(function () {
        alert('Não foi possível conectar-se ao servidor de autenticação.');
      });
  }

  useEffect(() => {
    const last = localStorage.getItem("opt.auth.login.lastEmail");

    if (last) {
      setEmail(last);
    }
  }, []);

  const getUserProvider = async (email: string) => {
    const result = await fetch(`${Config.Instance.apiEndpoint}/user/provider?email=${email}`, {
      method: 'GET',
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to query model.");

        return await response.text();
      });

    return result;
  }

  const handleLogin = async () => {
    setBusy(true);
    localStorage.setItem("opt.auth.login.lastEmail", email);
    const provider = await getUserProvider(email);

    keycloak
      .login({ idpHint: provider, loginHint: email })
      .catch(function () {
        alert('Não foi possível conectar-se ao servidor de autenticação.');
      });

    setBusy(false);
  }

  return (
    <StyledRoot>
      <Card sx={classes.card}>
        {pageConfig?.logo &&
          <CardMedia
            component="img"
            height="160"
            image={pageConfig.logo}
          />
        }
        <CardContent sx={classes.cardBody}>
          {pageConfig?.title &&
            <Typography gutterBottom variant="h5" component="div" color={pageConfig.titleColor ?? "primary"}>
              {pageConfig.title}
            </Typography>
          }
          {pageConfig?.subtitle &&
            <Typography variant="body2" color={pageConfig.subtitleColor ?? "text.secondary"}>
              {pageConfig.subtitle}
            </Typography>
          }
          <Box paddingTop="35px">
            <FormControl sx={{ minWidth: "380px" }}>
              <InputLabel htmlFor="email-input">E-mail</InputLabel>
              <OutlinedInput
                id="email-input"
                size="small"
                label="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleLogin}
                      edge="end"
                    >
                      <EastIcon />
                    </IconButton>
                  </InputAdornment>
                } />
            </FormControl >
            <Box sx={{ width: '100%', paddingTop: "5px" }}>
              {busy &&
                <LinearProgress />
              }
            </Box>
          </Box>
        </CardContent>
      </Card>
    </StyledRoot>
  )
}

export default ShowWelcome;