import React, { useEffect, useRef, useState } from "react";
import OlMap from "ol/Map";
import {
  Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel,
  IconButton, ListItemButton, Modal, Paper, Radio, RadioGroup, Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Control } from "ol/control";
import Draw, { createBox, DrawEvent } from 'ol/interaction/Draw';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Geometry } from "ol/geom";
import { Extent } from "ol/extent";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import TileLayer from "ol/layer/Tile";
import { PlanetProvider } from "./PlanetProvider";
import { calculateExtent, ControlAddedToMapSharedArea, LayerDefinition } from "@opt/mapping";
import { DownloadAPI, FormatDate, useUserAuthStore } from "@opt/core";
import { RemoteInspectionItem } from "./RemoteInspectionItem";
import { Feature } from "ol";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNotifyStore } from "@opt/ui-core";

type RemoteInspectionProps = {
  map: OlMap | undefined,
  zIndex: number
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignontent: 'center',
  alignItems: 'center'
};

const RemoteInspection: React.FC<RemoteInspectionProps> = ({ map, zIndex }) => {

  const { t } = useTranslation();
  const { currentTenant } = useUserAuthStore();
  const [showControl, setShowControl] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const contrlRef = useRef<HTMLDivElement>(null);
  const [drawer, setDrawer] = useState<Draw>();
  const [vectorSource, setVectorSource] = useState<VectorSource<Geometry>>();
  const [inspectLayer, setInspectLayer] = useState<TileLayer<any>>();
  const [selectedValue, setSelectedValue] = React.useState('last');
  const [sceneList, setSceneList] = useState<RemoteInspectionItem[]>([]);
  const [feature, setFeature] = useState<Feature>();
  const { addMsg } = useNotifyStore();

  function DownloadImage(url: string) {
    const { doDownload } = DownloadAPI(url);
    const [img, setImg] = useState("");

    useEffect(() => {
      doDownload("").then(data => {
        const i = URL.createObjectURL(data as Blob);
        setImg(i)
      });

    }, [url])

    return (<>
      <img style={{ width: "100px", height: "100px" }}
        src={img}
        alt=""
        loading="lazy"
      />
    </>)
  }

  const provider = new PlanetProvider();

  const handleControlAdded = (control: any) => {
    if (control.controlName !== "RemoteInspection") {
      setShowControl(false);
    }
  }

  useEffect(() => {

    if (!map || !currentTenant?.features?.remoteInspection) return;

    const control = new Control({ element: contrlRef.current as HTMLElement });
    map.addControl(control);

    const source = new VectorSource({ wrapX: false });
    setVectorSource(source);

    const style = new Style({
      stroke: new Stroke({
        color: "rgba(255,255,0,1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,255,0,0)",
      }),
    });

    new VectorLayer({
      source: source,
      map: map,
      style: style
    });

    const inspLayer = provider.createInspecLayer();
    inspLayer.setZIndex(zIndex);
    setInspectLayer(inspLayer);
    map.addLayer(inspLayer);

    const draw = new Draw({
      source: source,
      type: 'Circle',
      geometryFunction: createBox(),
    });

    setDrawer(draw);

    map.addEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);

    return () => {
      map.removeControl(control);
      map.removeLayer(inspLayer);
      map.removeEventListener(ControlAddedToMapSharedArea.EventName, handleControlAdded);
    }
  }, [map, currentTenant]);

  useEffect(() => {
    if (!drawer) return;

    drawer.on("drawend", handleDrawEnd);

    return () => {
      drawer.un("drawend", handleDrawEnd);
    }
  }, [drawer, selectedValue])

  const handleChooseAnother = () => {
    if (!sceneList.length) return;

    setShowModal(true);
  }

  const handleChooseLast = () => {
    if (!sceneList.length) return;

    const first = sceneList[0];
    inspectLayer?.getSource()?.setUrl(first.layerUrl);
    inspectLayer?.setVisible(true);
    inspectLayer?.getSource().refresh();
    setShowModal(false);
  }

  const handleDrawEnd = async (event: DrawEvent) => {
    map?.removeInteraction(drawer as Draw);

    const geom = event.feature.clone().getGeometry();
    try {
      const scenes = await provider.getSceneList(geom as Geometry);
      setSceneList(scenes);
      setFeature(event.feature);

      if (selectedValue === "last") {
        handleItemChoosen(scenes[0], event.feature);
      } else {
        setShowModal(true);
      }
    } catch {
      addMsg("error", t('components.remoteInspection.providerNotAvailable'))
    }
  }

  const handleItemChoosen = (info: RemoteInspectionItem, featureToZoom: Feature) => {
    inspectLayer?.getSource()?.setUrl(info.layerUrl);
    const view = calculateExtent(featureToZoom.clone(), 300);
    inspectLayer?.setExtent(featureToZoom.clone().getGeometry()?.getExtent());
    inspectLayer?.setVisible(true);
    inspectLayer?.getSource().refresh();

    const definition = inspectLayer?.get("LAYER_DEFINITION") as LayerDefinition;
    definition.metadata.id = info.id;
    definition.metadata.date = FormatDate(info.date);

    map?.getView().fit(view as Extent);
  }

  useEffect(() => {

    if (showControl) {
      map?.addInteraction(drawer as Draw);
      map?.dispatchEvent(new ControlAddedToMapSharedArea("RemoteInspection"));
    } else {
      map?.removeInteraction(drawer as Draw);
      vectorSource?.clear();
      inspectLayer?.setVisible(false);
      setSceneList([]);
    }
  }, [showControl]);

  const handleCloseControl = () => {
    setShowControl(false);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleOpenControl = () => {
    setShowControl(true);
  }

  const handleSelectedItem = (item: RemoteInspectionItem) => {
    setShowModal(false);
    handleItemChoosen(item, feature as Feature);
  }

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    return (
      <>
        <ListItemButton style={style} key={index} onClick={() => handleSelectedItem(sceneList[index])}>
          <Paper elevation={2} sx={{ width: "450px" }}>
            <Stack direction="row" sx={{ paddingLeft: "5px" }}>
              <>{DownloadImage(sceneList[index].thumbUrl)}</>
              <Stack sx={{ paddingTop: "10px", paddingLeft: "20px" }}>
                <Typography gutterBottom variant="body2" component="div" color="primary">
                  {t('components.remoteInspection.sceneID')}: {sceneList[index].id}
                </Typography>
                <Typography gutterBottom variant="body2" component="div" color="primary">
                  {t('components.remoteInspection.sceneDate')}: {sceneList[index].date}
                </Typography>
                <Typography gutterBottom variant="body2" component="div" color="primary">
                  {t('components.remoteInspection.sceneCloudCover')}: {sceneList[index].cloudPercent}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </ListItemButton>
      </>
    );
  }

  return currentTenant?.features?.remoteInspection ? (
    <div>
      <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
        <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {t('components.remoteInspection.title')}
          </Typography>
          <Button variant="outlined" size="small" onClick={() => handleOpenControl()}>
            {t('components.remoteInspection.selectArea')}
          </Button>
        </CardContent>
      </Card>
      <div ref={contrlRef} className="ol-control" style={{ top: '7px', right: 'calc(50% - 150px)', zIndex: 100 }}>
        {showControl &&
          <Card sx={{ maxWidth: 320, minWidth: 320 }}>
            <CardHeader
              sx={{ paddingBottom: "5px!important" }}
              action={
                <IconButton onClick={() => handleCloseControl()}>
                  <CloseIcon />
                </IconButton>
              }
              title={t('components.remoteInspection.cardTitle')}
              subheader={t('components.remoteInspection.cardSelectArea')}
              titleTypographyProps={{ variant: 'h6', color: "primary" }}
            />
            <CardContent sx={{ padding: 0, paddingBottom: "5px!important" }}>
              <FormControl>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={(value) => setSelectedValue(value.target.value)}
                  value={selectedValue}
                >
                  <FormControlLabel
                    value="last"
                    color="primary"
                    label={<Typography sx={{ paddingTop: "5px" }} gutterBottom variant="body1" color="primary">
                      {t('components.remoteInspection.getLastScene')}
                    </Typography>}
                    control={<Radio size="small" color="primary" onClick={() => handleChooseLast()} />}
                  />
                  <FormControlLabel
                    value="choose"
                    color="primary"
                    label={<Typography sx={{ paddingTop: "5px" }} gutterBottom variant="body1" color="primary">
                      {t('components.remoteInspection.chooseScene')}
                    </Typography>}
                    control={<Radio size="small" color="primary" onClick={() => handleChooseAnother()} />}
                  />
                </RadioGroup>
              </FormControl>
              {/* <Box
                display="flex"
                alignItems="center"
                justifyContent="center">
                <DetectionReport map={map} vectorSource={vectorSource} inspection={inspection} />
              </Box> */}
            </CardContent>
          </Card>
        }
      </div>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="primary">
            {t('components.remoteInspection.availableScenes')}
          </Typography>
          <FixedSizeList
            height={270}
            width={480}
            itemSize={110}
            itemCount={sceneList.length}
            overscanCount={100}
          >
            {renderRow}
          </FixedSizeList>
        </Box>
      </Modal>
    </div>
  ) : (<div></div>)
}

export default RemoteInspection;