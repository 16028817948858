import React, { useEffect, useState } from "react";
import { useNotifyStore } from "../Notifyer";
import useMetadataStore from "./MetadataStore";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { TableMetadataSearch } from "@opt/core";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  }
}

interface TableMetadataSearchProps {
  modelID: string | undefined
}

const TableMetadataSearchView: React.FC<TableMetadataSearchProps> = ({ modelID }) => {

  const collator = new Intl.Collator();

  const { addMsg } = useNotifyStore();
  const { currentTableMetadata, fetchTableMetadata, updateTableMetadataSearch } = useMetadataStore();
  const [display, setDisplay] = useState("");
  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    if (!modelID) return;
    fetchTableMetadata(modelID);
  }, [modelID]);

  useEffect(() => {
    const cols = currentTableMetadata?.searchColumns;
    setColumns(cols ?? []);

    setDisplay(currentTableMetadata?.displayField ?? "");
  }, [currentTableMetadata])

  const handleUpdate = () => {
    const model = new TableMetadataSearch();
    model.id = currentTableMetadata?.id;
    model.displayField = display;
    model.searchColumns = columns;

    updateTableMetadataSearch(model)
      .then(() => {
        addMsg("success", "Configuração de buscas atualizada com sucesso.");
      })
      .catch(() => {
        addMsg("error", "Não foi possível atualizar as configurações de buscas.")
      });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const name = event.target.name;

    if (checked) {
      if (!columns.includes(name)) {
        columns.push(name)
      }
    }
    else {
      const idx = columns.indexOf(name);
      if (idx > -1) {
        columns.splice(idx, 1);
      }
    }
    setColumns([...columns]);
  };

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Busca (Full-Text Search) - {currentTableMetadata?.name}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Configuração do mecanismo de busca da classe de feição
        </Typography>
      </Box>
      <Box sx={{ padding: "20px", maxWidth: "400px" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
          <InputLabel id="select-display-field">Atributo de visualização</InputLabel>
          <Select
            labelId="select-display-field"
            id="display-field"
            value={display}
            label="Atributo de visualização"
            onChange={(e) => setDisplay(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {currentTableMetadata?.fieldsMetadata.map(x => {
              return <MenuItem key={x.id} value={x.name}>{x.label} - ({x.name})</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormGroup>
          {currentTableMetadata?.fieldsMetadata.sort((a, b) => collator.compare(a.label, b.label)).map(x => {
            return <FormControlLabel key={x.id}
              control={<Checkbox name={x.name}
                onChange={handleChange}
                checked={columns.includes(x.name as string)} />}
              label={`${x.label} - (${x.name})`} />
          })}
        </FormGroup>
      </Box>
      <Box sx={styles.searchBox}>
        <Button size="small"
          variant="contained"
          onClick={() => handleUpdate()}
          disabled={!display || !columns.length}
        >Salvar</Button>
      </Box>
    </>
  )
}

export default TableMetadataSearchView;