import { Box, Button, Drawer, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GridColDef } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import TableMetadataForm from "./TableMetadataForm";
import { useNavigate } from "react-router-dom";
import useMetadataStore from "./MetadataStore";
import { TableMetadata } from "@opt/core";
import DeleteDialog from "../DeleteDialog";
import DataGrid from "../DataGrid";
import { GeometryType, QueryFilter } from "@opt/core";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  },
  gridBox: {
    margin: "20px",
    flexGrow: 1
  }
}

interface TableMetadataViewProps {
  hideForms?: boolean,
  hideSymbols?: boolean,
  hideSearch?: boolean
}

const TableMetadataView: React.FC<TableMetadataViewProps> = ({ hideForms, hideSymbols, hideSearch }) => {

  const navigate = useNavigate();
  const { tableMetadata, lastQueryFilterTableMetadata: lastQueryFilter,
    queryTableMetadata, deleteTableMetadata, createTableMetadata, updateTableMetadata } = useMetadataStore();
  const [showForm, setShowForm] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedModel, setSelectedModel] = useState<TableMetadata>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [anchorActions, setAnchorActions] = React.useState<null | HTMLElement>(null);
  const openMoreActionsMenu = Boolean(anchorActions);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 150,
      editable: false,
    },
    {
      field: 'label',
      headerName: 'Label',
      width: 250,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 350,
      editable: false,
    },
    {
      field: 'spatialReferenceID',
      headerName: 'SRID',
      width: 100,
      editable: false,
    },
    {
      field: 'isInternal',
      headerName: 'Interna?',
      width: 100,
      editable: false,
    }
  ];

  useEffect(() => {
    queryTableMetadata(lastQueryFilter);
  }, []);

  useEffect(() => {

    if (!showForm) {
      setSelectedModel(undefined);
      setUpdateMode(false);
      setReadOnlyMode(false);
    }

  }, [showForm])

  const handleCloseMoreActions = () => {
    setAnchorActions(null);
  };

  const handleSubmitForm = (model: TableMetadata) => {

    if (updateMode) {
      updateTableMetadata(model).then(_ => {
        setShowForm(false);
        queryTableMetadata(lastQueryFilter);
      });
    } else {
      createTableMetadata(model).then(_ => {
        setShowForm(false);
        queryTableMetadata(lastQueryFilter);
      });
    }
  }

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      deleteTableMetadata(selectedModel?.id as string).then(_ => {
        setShowDeleteDialog(false);
        queryTableMetadata(lastQueryFilter);
      })
    }

    setShowDeleteDialog(false);
  }

  const handleOnEdit = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(false);
    setUpdateMode(true);
    setShowForm(true);
  }

  const handleOnDelete = (data: any) => {
    setSelectedModel(data);
    setShowDeleteDialog(true);
  }

  const handleOnDetails = (data: any) => {
    setSelectedModel(data);
    setReadOnlyMode(true);
    setShowForm(true);
  }

  const handleMoreAction = (data: any, element: HTMLElement) => {
    setSelectedModel(data);
    setAnchorActions(element);
  }

  const onChangeFilters = (filter: QueryFilter) => {
    queryTableMetadata(filter);
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          Table Metadata
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          Cadastro das entidades tabulares e classes de feições
        </Typography>
      </Box>
      <Box sx={styles.searchBox}>
        <Button size="small" variant="contained" onClick={() => setShowForm(true)}>Novo</Button>
      </Box>
      <Box sx={styles.gridBox}>
        <DataGrid
          height="calc(100vh - 250px)"
          dataRows={tableMetadata}
          dataColumns={columns}
          queryFilter={lastQueryFilter}
          onChangeFilters={onChangeFilters}
          onEditClicked={handleOnEdit}
          onDeleteClicked={handleOnDelete}
          onDetailsClicked={handleOnDetails}
          onMoreActionsCliked={handleMoreAction}
        />
      </Box>
      <Drawer
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "500px", maxWidth: "500px" }}>
          <TableMetadataForm
            forUpdate={updateMode}
            forReadOnly={readOnlyMode}
            model={selectedModel as TableMetadata}
            onSubmit={handleSubmitForm}
          ></TableMetadataForm>
        </Box>

      </Drawer>
      <DeleteDialog confirmation={handleDeleteConfirmation} showDialog={showDeleteDialog} />
      <Menu
        id="more-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'more-actions-button',
        }}
        anchorEl={anchorActions}
        open={openMoreActionsMenu}
        onClose={handleCloseMoreActions}
      >
        <MenuItem onClick={() => navigate(`/metadata/${selectedModel?.id}/attributes`)}>
          Ver atributos
        </MenuItem>
        {!hideForms &&
          <MenuItem onClick={() => navigate(`/metadata/${selectedModel?.id}/forms`)}>
            Ver formulários
          </MenuItem>
        }
        {!hideSymbols &&
          <MenuItem disabled={selectedModel?.geometryType === GeometryType.None} onClick={() => navigate(`/metadata/${selectedModel?.id}/symbol`)}>
            Ver Simbologia
          </MenuItem>
        }
        {!hideSearch &&
          <MenuItem onClick={() => navigate(`/metadata/${selectedModel?.id}/search`)}>
            Configurar busca
          </MenuItem>
        }
      </Menu>
    </>
  );
}

export default TableMetadataView;