import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TransferListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

export interface TransferListItem {
  id: string,
  description: string
}

interface TransferListProps {
  allItems: TransferListItem[]
  selectedItems: TransferListItem[],
  width?: number,
  height?: number,
  onChange: (selecteds: TransferListItem[]) => void
}

function not(a: readonly TransferListItem[], b: readonly TransferListItem[]) {
  return a.filter((value) => !b.find(x => x.id === value.id));
}

function intersection(a: readonly TransferListItem[], b: readonly TransferListItem[]) {
  return a.filter((value) => b.find(x => x.id === value.id));
}

const TransferList: React.FC<TransferListProps> = ({ allItems, selectedItems, width, height, onChange }) => {

  const [checked, setChecked] = React.useState<readonly TransferListItem[]>([]);
  const [left, setLeft] = React.useState<readonly TransferListItem[]>(not(allItems, selectedItems));
  const [right, setRight] = React.useState<readonly TransferListItem[]>(selectedItems);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(not(allItems, selectedItems));
    setRight(selectedItems);
  }, [allItems, selectedItems]);

  useEffect(() => {
    onChange([...right])
  }, [right]);

  const handleToggle = (value: TransferListItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: readonly TransferListItem[], title: string) => (
    <Paper sx={{ width: width ?? "100%", height: height ?? "100%", overflow: 'auto' }}>
      <Typography color="primary" >{title}</Typography>
      <List dense component="div" role="list">
        {items.map((value: TransferListItem) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <TransferListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.description} />
            </TransferListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left, "Disponíveis")}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, "Selecionados")}</Grid>
    </Grid>
  );
}

export default TransferList;