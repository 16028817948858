import {
  Box, Card, CardContent, FormControl, FormHelperText, InputLabel, MenuItem, Select,
  SelectChangeEvent, Slider, Typography
} from "@mui/material";
import { LayerDefinition, LayerMetadata, LayerType, TileLoadFunction, TiledLayer } from "@opt/mapping";
import React, { useEffect, useMemo, useState } from "react";
import config from "../../config";
import { RasterView } from "../../models/RasterView";
import { useAlertsStore } from "./AlertsStore";
import OlMap from 'ol/Map';
import { FormatDate, useUserAuthStore } from "@opt/core";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Size } from "ol/size";
import { getRenderPixel } from "ol/render";
import { unByKey } from "ol/Observable";
import RenderEvent from "ol/render/Event";
import { useTranslation } from "react-i18next";

type AlertRasterViewProps = {
  map: OlMap | undefined
}

const AlertRasterView: React.FC<AlertRasterViewProps> = ({ map }) => {

  const { t } = useTranslation();
  const { currentEvent, eventDetails, eventExtent, getEventDetails, getRasterViews } = useAlertsStore();
  const [rasters, setRasters] = useState<RasterView[]>([]);
  const [selectedRaster, setSelectedRaster] = useState("NONE");
  const { currentTenant } = useUserAuthStore();

  const [swipeFrom, setSwipeFrom] = useState<RasterView | undefined>();
  const [swipeTo, setSwipeTo] = useState<RasterView | undefined>();
  const [swipe, setSwipe] = useState<number>(0);
  const [swipeToLayer, setSwipeToLayer] = useState<TileLayer<XYZ>>();
  const [mapSize, setMapSize] = useState<Size | undefined>();

  useEffect(() => {
    if (!currentEvent) return;

    if (currentEvent.eventID !== eventDetails?.id) {
      getEventDetails(currentEvent.eventID);
    } else {
      getRasterViews(currentEvent?.eventID)
        .then(rasters => setRasters(rasters ?? []));
    }

    const layer = new TileLayer({
      visible: false,
      extent: eventExtent,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        url: "",
        cacheSize: 1000,
        tileLoadFunction: TileLoadFunction()
      })
    });

    map?.addLayer(layer);
    layer.setZIndex(2);

    setSwipeToLayer(layer);

    const keyOnChangeSize = map?.on("change:size", () => {
      setMapSize(map.getSize())
    });

    setSwipe(0);

    return () => {
      map?.removeLayer(layer);
      if (keyOnChangeSize) unByKey(keyOnChangeSize);
    }
  }, [currentEvent, map]);

  useEffect(() => {
    if (!eventDetails) {
      setRasters([])
      return;
    }

    getRasterViews(eventDetails?.id)
      .then(rasters => setRasters(rasters ?? []));
  }, [eventDetails, map])

  useEffect(() => {
    map?.getAllLayers().forEach(layer => {
      const definition = layer.get("LAYER_DEFINITION") as LayerDefinition;

      if (!definition) return;

      if (definition.key === `rasterviews-${selectedRaster}`) {
        layer.setVisible(true);
      } else if (definition.key.startsWith("rasterviews-")) {
        layer.setVisible(false);
      }
    });

    setSwipe(0);

    if (selectedRaster && selectedRaster !== "NONE") {
      setupSwipeLayer(selectedRaster);
    }

  }, [selectedRaster])

  useEffect(() => {
    if (!swipeTo || !swipeFrom || swipeFrom.swipeTo !== swipeTo.name) {
      swipeToLayer?.setVisible(false);
      return;
    }

    const catalogName = swipeTo.perTenant ? `${currentTenant.id}_${eventDetails?.stacCatalogue}` : eventDetails?.stacCatalogue;
    const itemID = swipeTo.useBaseline ? eventDetails?.baselineSTACItemID : eventDetails?.stacItemID;
    const url = `${config.gisServices.url}/rasters/${catalogName}/${itemID}/{z}/{x}/{y}?${swipeTo.queryString}`;

    swipeToLayer?.getSource()?.setUrl(url);

    setSwipe(0);

    return () => {
      swipeToLayer?.setVisible(false);
    }
  }, [swipeTo, swipeFrom])

  useEffect(() => {
    if (selectedRaster && selectedRaster !== "NONE") {
      setupSwipeLayer(selectedRaster);
    }
  }, [rasters])

  const handleLayerToPrerender = (e: RenderEvent) => {
    const ctx = e.context as CanvasRenderingContext2D;
    const mapSize = map?.getSize() as Size;
    const width = mapSize[0] * (swipe / 100);
    const tl = getRenderPixel(e, [0, 0]);
    const tr = getRenderPixel(e, [width, 0]);
    const bl = getRenderPixel(e, [0, mapSize[1]]);
    const br = getRenderPixel(e, [width, mapSize[1]]);

    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = 'yellow';
    ctx.lineWidth = 8;
    ctx.moveTo(tl[0], tl[1]);
    ctx.lineTo(bl[0], bl[1]);
    ctx.lineTo(br[0], br[1]);
    ctx.lineTo(tr[0], tr[1]);
    ctx.closePath();
    ctx.stroke();
    ctx.clip();
  }

  const handleLayerPosrender = (e: RenderEvent) => {
    const ctx = e.context as CanvasRenderingContext2D;
    ctx.restore();
  }

  useEffect(() => {
    swipeToLayer?.setVisible(swipe > 0);

    const handlePreTo = swipeToLayer?.on("prerender", (e) => handleLayerToPrerender(e));
    const handlePosTo = swipeToLayer?.on("postrender", handleLayerPosrender);

    map?.render();

    return () => {
      if (handlePreTo) unByKey(handlePreTo)
      if (handlePosTo) unByKey(handlePosTo)
    }
  }, [swipe, mapSize])

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setSelectedRaster(value);
    setupSwipeLayer(value);
  }

  const setupSwipeLayer = (value: string) => {
    const from = rasters.find(x => x.name === value);

    if (from?.swipeTo) {
      const to = rasters.find(x => x.name === from.swipeTo);
      setSwipeFrom(from);
      setSwipeTo(to);
    } else {
      setSwipeFrom(undefined);
      setSwipeTo(undefined);
    }
  }

  const layers = useMemo(() => {
    const l = rasters.map(layer => {
      const visible = selectedRaster === layer.name;
      const catalogName = layer.perTenant ? `${currentTenant.id}_${eventDetails?.stacCatalogue}` : eventDetails?.stacCatalogue;
      const itemID = layer.useBaseline ? eventDetails?.baselineSTACItemID : eventDetails?.stacItemID;
      const itemDate = layer.useBaseline ? eventDetails?.baselineSTACItemDate : eventDetails?.stacItemDate;
      const url = `${config.gisServices.url}/rasters/${catalogName}/${itemID}/{z}/{x}/{y}?${layer.queryString}`;
      const definition = new LayerDefinition(`rasterviews-${layer.name}`, LayerType.Tiled, url, layer.description, 1, visible);
      definition.extent = eventExtent;

      const metadata = new LayerMetadata();
      metadata.provider = eventDetails?.stacCatalogue;
      metadata.id = itemID;
      metadata.date = FormatDate(itemDate);

      definition.metadata = metadata;

      return <TiledLayer key={layer.name} definition={definition} map={map} />
    });
    return l;
  }, [rasters])

  return (
    <>
      <Card sx={{ maxWidth: 455, margin: "10px 20px" }}>
        <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {t('components.alerts.rasterView.title')}
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="raster-list-label">{t('components.alerts.rasterView.layers')}</InputLabel>
            <Select
              labelId="raster-list-label"
              id="raster-list"
              value={selectedRaster}
              label="Camadas"
              onChange={handleChange}
              disabled={eventDetails?.archived}
            >
              <MenuItem value="NONE">{t('components.alerts.rasterView.none')}</MenuItem>
              {rasters.map(item => {
                return <MenuItem key={item.name} value={item.name}>{item.description}</MenuItem>
              })}
            </Select>
            {eventDetails?.archived &&
              <FormHelperText id="raster-list-text" sx={{ textAlign: "center", color: "red" }}>{t('components.alerts.rasterView.archived')}</FormHelperText>
            }
          </FormControl>
          {swipeFrom &&
            <Box>
              <Typography marginTop="10px">{`${t('components.alerts.rasterView.overlay')} ${swipeTo?.description}`}</Typography>
              <Slider max={100} min={0} value={swipe} onChange={(e, value) => setSwipe(value as number)} valueLabelDisplay="off" />
            </Box>
          }
        </CardContent>
      </Card>
      {rasters && rasters.length > 0 &&
        layers
      }
    </>
  )
}

export default AlertRasterView;