import React, { useEffect } from "react";
import { LayerDefinition } from "./LayerDefinition";
import { useMapContext } from "./AppMap";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import OlMap from 'ol/Map';
import { TileLoadFunction } from "./utils/TileLoadFunction";

type TiledLayerProps = {
  definition: LayerDefinition,
  map?: OlMap
}

const TiledLayer: React.FC<TiledLayerProps> = ({ definition, map }) => {

  const mapContext = useMapContext();

  useEffect(() => {

    const webmap = mapContext?.map ?? map;

    if (!definition || !webmap) return;

    const layer = new TileLayer({
      visible: definition.visible,
      extent: definition.extent,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        cacheSize: 1000,
        url: definition.url,
        tileLoadFunction: TileLoadFunction()
      })
    });

    layer.set("LAYER_DEFINITION", definition);

    webmap.addLayer(layer);

    layer.setZIndex(definition.zIndex);

    return () => {
      webmap.removeLayer(layer);
    }

  }, [definition, mapContext, map])

  return (<></>);
}

export default TiledLayer;