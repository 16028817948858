import React from "react";
import { useParams } from "react-router-dom";
import ExplorerMap from "../components/explorer/ExplorerMap";

const ExplorerMapPage: React.FC = () => {

  const { datasetID } = useParams<string>();

  return (
    <>
      {datasetID &&
        <ExplorerMap datasetID={datasetID} />
      }
    </>
  )
}

export default ExplorerMapPage;