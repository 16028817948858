import { ClickAwayListener, Portal, TextField } from "@mui/material";
import { FormatDate } from "@opt/core";
import React, { useEffect, useState } from "react";
import { CustomProvider, DateRangePicker } from 'rsuite';
import { DateRangePickerComponent } from "rsuite/esm/DateRangePicker";
import { ptBR } from "rsuite/esm/locales";

type DateRangeProps = {
  label?: string,
  shortcuts?: React.ComponentProps<typeof DateRangePicker>["ranges"],
  size?: React.ComponentProps<typeof DateRangePicker>["size"],
  value?: [Date, Date],
  initialValue?: [Date, Date],
  onChange?(value: [Date, Date]): void
}

const DateRange: React.FC<DateRangeProps> = ({ label, shortcuts, size, value: componentValue, initialValue, onChange }) => {

  const [open, setOpen] = useState(false);
  const container = React.useRef(null);
  const [value, setValue] = useState<[Date, Date]>([
    componentValue?.[0] ?? initialValue?.[0] ?? new Date(),
    componentValue?.[1] ?? initialValue?.[1] ?? new Date()
  ]);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value]);

  return (
    <>
      <ClickAwayListener onClickAway={(e) => {
        if (e.target === document.body) {
          // FIX: google chrome fires it all the time  
          return;
        }
        setOpen(false);
      }} >
        <div ref={container}>
          <TextField
            value={`${FormatDate(value[0])} - ${FormatDate(value[1])}`}
            size="small"
            label={label}
            variant="outlined"
            onFocus={() => setOpen(true)} />

          {open ? (
            <Portal container={() => container.current!}>
              <CustomProvider locale={ptBR}>
                <DateRangePicker
                  value={value}
                  onChange={(e) => setValue([e?.[0] as Date, e?.[1] as Date])}
                  defaultCalendarValue={[initialValue?.[0] ?? new Date(), initialValue?.[1] ?? new Date()]}
                  ranges={shortcuts}
                  size={size ?? "lg"}
                  format="dd/MM/yyyy"
                  character=" – "
                  hidden={true}
                  open={open}
                  onOk={() => setOpen(false)}
                  placement="bottomEnd"
                />
              </CustomProvider>
            </Portal>
          ) : null}

        </div>

      </ClickAwayListener>


    </>
  )
}

export default DateRange;