import { GeometryType } from "@opt/core";
import { Extent } from "ol/extent";
import { StyleLike } from "ol/style/Style";
import { LayerStyling } from "./styling/LayerStyling";
import { LegendItem, LegendLayerItem } from "./styling/LegendBuilder";

export enum LayerType {
  MVT = "MVT",
  Tiled = "Tiled",
  Feature = "Feature",
  OPT = "OPT"
}

export class LayerMetadata {
  public id: string | undefined;
  public provider: string | undefined;
  public date: string | undefined;
}

export class LayerDefinition extends EventTarget {

  private _key: string;
  public get key(): string {
    return this._key;
  }
  public set key(value: string) {
    this._key = value;
    this.raisePropertyChange("key", value, this._key);
  }

  private _type: LayerType;
  public get type(): LayerType {
    return this._type;
  }
  public set type(value: LayerType) {
    this._type = value;
    this.raisePropertyChange("type", value, this._type);
  }

  private _url: string;
  public get url(): string {
    return this._url;
  }
  public set url(value: string) {
    this._url = value;
    this.raisePropertyChange("url", value, this._url);
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
    this.raisePropertyChange("name", value, this._name);
  }

  private _zIndex: number;
  public get zIndex(): number {
    return this._zIndex;
  }
  public set zIndex(value: number) {
    this._zIndex = value;
    this.raisePropertyChange("zIndex", value, this._zIndex);
  }

  private _visible: boolean;
  public get visible(): boolean {
    return this._visible;
  }
  public set visible(value: boolean) {
    this._visible = value;
    this.raisePropertyChange("visible", value, this._visible);
  }

  private _showInLegend: boolean;
  public get showInLegend(): boolean {
    return this._showInLegend;
  }
  public set showInLegend(value: boolean) {
    this._showInLegend = value;
    this.raisePropertyChange("showInLegend", value, this._showInLegend);
  }

  private _identifiable: boolean;
  public get identifiable(): boolean {
    return this._identifiable;
  }
  public set identifiable(value: boolean) {
    this._identifiable = value;
    this.raisePropertyChange("identifiable", value, this._identifiable);
  }

  private _extent: Extent | undefined;
  public get extent(): Extent | undefined {
    return this._extent;
  }
  public set extent(value: Extent | undefined) {
    this._extent = value;
    this.raisePropertyChange("extent", value, this._extent);
  }

  private _style: StyleLike | undefined;
  public get style(): StyleLike | undefined {
    return this._style;
  }
  public set style(value: StyleLike | undefined) {
    this._style = value;
    this.raisePropertyChange("style", value, this._style);
  }

  private _geometryType: GeometryType | undefined;
  public get geometryType(): GeometryType | undefined {
    return this._geometryType;
  }
  public set geometryType(value: GeometryType | undefined) {
    this._geometryType = value;
    this.raisePropertyChange("geometryType", value, this._geometryType);
  }

  private _styleDefinition: LayerStyling | undefined;
  public get styleDefinition(): LayerStyling | undefined {
    return this._styleDefinition;
  }
  public set styleDefinition(value: LayerStyling | undefined) {
    this._styleDefinition = value;
    this.raisePropertyChange("styleDefinition", value, this._styleDefinition);
  }

  private _idProperty: string;
  public get idProperty(): string {
    return this._idProperty;
  }
  public set idProperty(value: string) {
    this._idProperty = value;
    this.raisePropertyChange("idProperty", value, this._idProperty);
  }

  private _legend: LegendItem[];
  public get legend(): LegendItem[] {
    return this._legend;
  }
  public set legend(value: LegendItem[]) {
    this._legend = value;
    this.raisePropertyChange("legend", value, this._legend);
  }

  private _metadata: LayerMetadata | undefined;
  public get metadata(): LayerMetadata {
    return this._metadata as LayerMetadata;
  }
  public set metadata(value: LayerMetadata) {
    this._metadata = value;
    this.raisePropertyChange("metadata", value, this._metadata);
  }

  constructor(key: string,
    type: LayerType,
    url: string,
    name: string,
    zIndex: number = 0,
    visible: boolean = true,
    showInLegend: boolean = true,
    identifiable: boolean = true,
    extent: Extent | undefined = undefined,
    style: StyleLike | undefined = undefined,
    geometryType: GeometryType | undefined = undefined,
    styleDefinition: LayerStyling | undefined = undefined,
    idProperty: string = "id") {
    super()
    this._key = key;
    this._type = type;
    this._url = url;
    this._name = name;
    this._zIndex = zIndex;
    this._visible = visible;
    this._showInLegend = showInLegend;
    this._identifiable = identifiable;
    this._extent = extent;
    this._style = style;
    this._geometryType = geometryType;
    this._styleDefinition = styleDefinition;
    this._idProperty = idProperty;
    this._legend = [];
  }

  private raisePropertyChange(key: string, newValue: any, oldValue: any) {
    const values = { key: key, newValue: newValue, oldValue: oldValue }
    this.dispatchEvent(new CustomEvent("propertychange", { detail: values }));
  }

  public clone(): LayerDefinition {
    const newOne = new LayerDefinition(this.key,
      this.type,
      this.url,
      this.name,
      this.zIndex,
      this.visible,
      this.showInLegend,
      this.identifiable,
      this.extent,
      this.style,
      this.geometryType,
      this.styleDefinition,
      this.idProperty);

    return newOne;
  }
}