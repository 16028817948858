import { useUserAuthStore } from "../auth/UserAuthStore";

export const DownloadAPI = (endpoint: string | undefined): {
  doDownload: (modelID: string) => Promise<Blob | undefined>,
  doDownloadAsStream: (modelID: string) => Promise<ReadableStreamDefaultReader<Uint8Array> | undefined>,
  doPostDownload: (data: any) => Promise<Blob | undefined>,
} => {

  const doDownload = async (modelID: string) => {
    if (endpoint === undefined) return;

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = modelID === "" || modelID === undefined ? `${endpoint}` : `${endpoint}/${modelID}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to download data.");

        return response.blob();
      });

    return result;
  }

  const doPostDownload = async (data: any) => {
    if (endpoint === undefined) return;

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const json = JSON.stringify(data);

    const result = await fetch(endpoint, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip',
        'Content-Type': 'application/json'
      }),
      body: json
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to download data.");

        return response.blob();
      });

    return result;
  }

  const doDownloadAsStream = async (modelID: string) => {
    if (endpoint === undefined) return;

    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;
    const url = modelID === "" || modelID === undefined ? `${endpoint}` : `${endpoint}/${modelID}`;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    });

    return result.body?.getReader();
  }

  return { doDownload: doDownload, doDownloadAsStream: doDownloadAsStream, doPostDownload: doPostDownload }
}