import { plainToClassFromExist } from "class-transformer";
import { useUserAuthStore } from "../auth/UserAuthStore";

export const DataFetchAPI = <TModel>(typeFactory: new () => TModel, endpoint: string | undefined): {
  doFetch: (modelID: string) => Promise<TModel | undefined>
  doFetchMany: () => Promise<TModel[] | undefined>
} => {

  const doFetch = async (modelID: string) => {
    if (endpoint === undefined) return;

    const url = modelID === "" || modelID === undefined ? `${endpoint}` : `${endpoint}/${modelID}`;
    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to query model.");

        return plainToClassFromExist(new typeFactory(), await response.json());
      });

    return result;
  }

  const doFetchMany = async () => {
    if (endpoint === undefined) return;

    const url = `${endpoint}`;
    const accessToken = useUserAuthStore.getState().accessToken;
    const tenantID = useUserAuthStore.getState().currentTenant.id;

    const result = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Authorization': `Bearer ${accessToken}`,
        'X-Tenant': tenantID,
        'Accept-Encoding': 'gzip'
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to query model.");

        return (await response.json()).map((x: any) => plainToClassFromExist(new typeFactory(), x));
      });

    return result;
  }

  return { doFetch: doFetch, doFetchMany: doFetchMany }
}