import { FormControl, FormControlLabel, FormHelperText, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, Typography } from "@mui/material";
import { CloneModels, GeometryType } from "@opt/core";
import React, { useState } from "react";
import { TableMetadata } from "@opt/core";
import SideEditForm from "../SideEditForm";

interface TableMetadataFormProps {
  forUpdate: boolean,
  forReadOnly: boolean,
  model: TableMetadata,
  onSubmit: (model: TableMetadata) => void
}

const TableMetadataForm: React.FC<TableMetadataFormProps> = ({ forUpdate, forReadOnly, model, onSubmit }) => {

  const [formValue, setFormValue] = useState<TableMetadata>(forUpdate || forReadOnly ? CloneModels.deepCopy<TableMetadata>(model) : new TableMetadata());

  const handleControlValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<GeometryType>) => {
    const { name: propName, value } = event.target;
    Reflect.set(formValue, propName, value);

    const newOne = CloneModels.deepCopy<TableMetadata>(formValue);
    setFormValue(newOne);
  }

  const handleControlNumbersOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: propName, value } = event.target;
    const isNumber = /^[0-9]*$/.test(value)

    if (isNumber) {
      Reflect.set(formValue, propName, value);
      const newOne = CloneModels.deepCopy<TableMetadata>(formValue);
      setFormValue(newOne);
    }
  }

  const handleControlCkeckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: propName, checked } = event.target;
    Reflect.set(formValue, propName, checked);
    setFormValue(formValue.clone());
  };

  return (
    <>
      <SideEditForm
        title="Table Metadata"
        forUpdate={forUpdate}
        forReadOnly={forReadOnly}
        onSubmit={() => onSubmit(formValue)}>
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="name">Nome</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="name"
              name="name"
              aria-describedby="name-helper-text"
              value={formValue?.name}
              onChange={handleControlValueChange} />
            <FormHelperText id="name-helper-text">Nome da tabela ou classe de feição</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="label">Label</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="label"
              name="label"
              aria-describedby="label-helper-text"
              value={formValue?.label}
              onChange={handleControlValueChange} />
            <FormHelperText id="label-helper-text">Label para exibição da tabela ou classe de feição</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="description">Descrição</InputLabel>
            <Input
              readOnly={forReadOnly}
              id="description"
              name="description"
              aria-describedby="description-helper-text"
              value={formValue?.description}
              onChange={handleControlValueChange} />
            <FormHelperText id="description-helper-text">Descrição da tabela ou classe de feição</FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="geometry-type-select-label">Tipo de Geometria</InputLabel>
            <Select
              readOnly={forReadOnly}
              labelId="geometry-type-select-label"
              id="geometry-type"
              name="geometryType"
              label="Tipo de Geometria"
              value={formValue?.geometryType}
              onChange={handleControlValueChange}
            >
              <MenuItem value={GeometryType.Point}>Ponto (Point)</MenuItem>
              <MenuItem value={GeometryType.MultiPoint}>Multiplos Ponto (MultiPoint)</MenuItem>
              <MenuItem value={GeometryType.LineString}>Linha (LineString)</MenuItem>
              <MenuItem value={GeometryType.MultiLineString}>Multiplas Linha (MultiLineString)</MenuItem>
              <MenuItem value={GeometryType.Polygon}>Polígono (Polygon)</MenuItem>
              <MenuItem value={GeometryType.MultiPolygon}>Multiplos Polígonos (MultiPolygon)</MenuItem>
              <MenuItem value={GeometryType.None}>Sem Geometria</MenuItem>
            </Select>
            <FormHelperText id="geometryType-helper-text">Tipo de geometria da classe de feição ou 'Sem Geometria' para tabular</FormHelperText>
          </FormControl>
          {formValue.geometryType !== GeometryType.None &&
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel htmlFor="spatialReferenceID">SRID</InputLabel>
              <Input
                readOnly={forReadOnly}
                id="spatialReferenceID"
                name="spatialReferenceID"
                aria-describedby="spatialReferenceID-helper-text"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={formValue?.spatialReferenceID ?? ''}
                onChange={handleControlNumbersOnlyChange} />
              <FormHelperText id="length-helper-text">Tamanho do atributo em número de caracteres</FormHelperText>
            </FormControl>
          }
          <FormControl sx={{ mt: 2 }}>
            <FormControlLabel sx={{ maxWidth: 195, justifyContent: "start" }}
              value="top"
              control={<Switch color="primary"
                name="isInternal"
                disabled={forReadOnly}
                checked={formValue.isInternal}
                onChange={handleControlCkeckedChange} />}
              label={<Typography color="rgba(0, 0, 0, 0.6)">Tabela Interna</Typography>}
              labelPlacement="start"
            />
            <FormHelperText id="order-helper-text">Informa se a tabela é interna do Sistema</FormHelperText>
          </FormControl>
        </>
      </SideEditForm>
    </>
  )
}

export default TableMetadataForm;