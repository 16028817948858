import Dexie, { Table } from 'dexie';

export interface Tiles {
  id: string,
  data: Blob;
}

export class OfflineDB extends Dexie {

  tiles!: Table<Tiles>;

  constructor() {
    super('OfflineMap');

    this.version(1).stores(
      { tiles: "id, data" }
    )
  }

  public saveTile = async (layerID: string, z: number, x: number, y: number, data: Blob): Promise<void> => {
    const id = `${layerID}_${z}_${x}_${y}`;
    await this.tiles.put({ id, data });
  }

  public getTile = async (layerID: string, z: number, x: number, y: number): Promise<Blob | undefined> => {
    const id = `${layerID}_${z}_${x}_${y}`;
    const data = await this.tiles.get({ id: id });
    return data?.data;
  }
}

export const mapDB = new OfflineDB();