export class InspectionType {

  public id: string | undefined;
  public name: string | undefined;
  public description: string | undefined;
  public active: boolean | undefined;

  constructor() {

  }
}